import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useLocation } from "react-router-dom";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { Link } from "react-router-dom";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Tooltip from '@mui/material/Tooltip';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { obtenerCuenta } from "../../../Redux/Cuenta";
import TableRowsIcon from '@mui/icons-material/TableRows';
import { ReceiptLongOutlined } from "@mui/icons-material";
import logo from "../../../img/Logonavbar.png"
import logolight from "../../../img/Logonavbar-light.png"
import LoadingScreen from '../../../Components/LoadingScreen'
import logoSOC from "../../../img/WebPortal.png"
import logoSOCDark from '../../../img/WebPortalDark.png'

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = theme.palette
    return (
        <MenuItem
            active={selected === to}
            style={{ color: colors.primary.main }}
            onClick={() => setSelected(to)}
            icon={icon}
            routerLink={<Link to={to} />}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    );
};

const MyProSidebar = ({ loggedUser, loadingAPP, mostrarLineas }) => {
    const dispatch = useDispatch()
    const theme = useTheme();
    const [selected, setSelected] = useState("");
    const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
    const { collapseSidebar, toggleSidebar, toggled, collapsed, broken, rtl } = useProSidebar();
    const { pathname } = useLocation();
    const usuario = useSelector(store => store.usuarios.user)

    React.useEffect(() => {
        setSelected(pathname);
        if (broken && !rtl && toggled) {
            setTimeout(() => {
                toggleSidebar()
            }, 1000)
        }
    }, [pathname]);

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    return loggedUser && !loadingAPP ? (
        <Box
            sx={{
                position: "sticky",
                display: "flex",
                height: "100vh",
                top: 0,
                bottom: 0,
                zIndex: 3,
                "& .sidebar": {
                    border: "none",
                },
                "& .menu-icon": {
                    backgroundColor: "transparent !important",
                    fontSize: "25px"
                },
                "& .menu-item": {
                    backgroundColor: "transparent !important",
                    "& .menu-icon": {
                        color: 'inherit',
                    },
                    "&.active": {
                        color: 'inherit',
                    },
                },
                "& .menu-item:not(.sub-menu)": {
                    backgroundColor: "transparent !important",
                    "&.active": {
                        color: "inherit !important",
                        backgroundImage: "linear-gradient(98deg, rgb(56, 190, 76, .8), rgb(56, 190, 76, .6)) !important",
                        borderRadius: '0px 100px 100px 0px',
                        boxShadow: 'rgba(58,53,65,0.42) 0px 4px 8px -4px',
                        "& .menu-icon": {
                            color: 'inherit', // Cambia esto al color que quieras para el menú activo
                        },

                    },
                    "&:hover": {
                        color: 'inherit',
                        backgroundColor: theme.palette.mode === 'dark' ? "rgb(47, 44, 69) !important" : "rgb(236, 237, 243) !important",
                        borderRadius: '0px 100px 100px 0px',
                    },
                },
                "& .menu-anchor": {
                    color: "inherit !important",
                    backgroundColor: "transparent !important",
                },

                "& .sub-menu-content": {
                    backgroundColor: theme.palette.primary.main,
                },
            }}
        >
            <Sidebar
                breakPoint="md"
                rtl={sidebarRTL}
                backgroundColor={broken && !rtl ? theme.palette.background.main : null}
                image={sidebarImage}
                defaultCollapsed={true}
            >
                <Menu iconshape="square">
                    <MenuItem
                        icon={
                            collapsed && (
                                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
                            )
                        }
                        style={{
                            margin: "10px 0 10px 0",
                        }}
                    >
                        {!collapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                // ml="2%"
                                style={{ maxWidth: "100%" }}
                            >
                                <IconButton
                                    onClick={
                                        broken ? () => toggleSidebar() : () => collapseSidebar()
                                    }
                                    style={{ flexShrink: 0 }}
                                >
                                    <CloseOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>
                    {broken && !rtl ? (
                        <Box sx={{ mt: 2 }}>
                            <Item
                                title="Resumen de Posición"
                                to="/"
                                icon={<HomeOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Mi Documentación Digital"
                                to="/midocumentaciondigital"
                                icon={<SnippetFolderIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            {
                                usuario != undefined && usuario.Estado == "100000000" ?
                                    <>

                                        <Item
                                            title="Mis Garantías"
                                            to="/misgarantias"
                                            icon={<AutoAwesomeMotionIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        {
                                            mostrarLineas ? <Item
                                                title="Mis Líneas"
                                                to="/mislineas"
                                                icon={<TableRowsIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            /> : null
                                        }
                                        <Item
                                            title="Mis Operaciones"
                                            to="/misoperaciones"
                                            icon={<ReceiptLongOutlined />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        /> </> : null
                            }
                            <Item
                                title="Mi Cuenta"
                                to="/micuenta"
                                icon={<AccountCircleIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </Box>
                    ) :
                        <Box sx={{ mt: 2 }}>
                            <Item
                                title="Resumen de Posición"
                                to="/"
                                icon={collapsed ?
                                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Resumen de Posición</Typography>}>
                                        <HomeOutlinedIcon />
                                    </Tooltip> : <HomeOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Mi Documentación Digital"
                                to="/midocumentaciondigital"
                                icon={collapsed ?
                                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mi Documentación Digital</Typography>}>
                                        <SnippetFolderIcon />
                                    </Tooltip> : <SnippetFolderIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            {
                                usuario != undefined && usuario.Estado == "100000000" ?
                                    <>

                                        <Item
                                            title="Mis Garantías"
                                            to="/misgarantias"
                                            icon={collapsed ?
                                                <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mis Garantías</Typography>}>
                                                    <AutoAwesomeMotionIcon />
                                                </Tooltip> : <AutoAwesomeMotionIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        {
                                            mostrarLineas ? <Item
                                                title="Mis Líneas"
                                                to="/mislineas"
                                                icon={collapsed ?
                                                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mis Líneas</Typography>}>
                                                        <TableRowsIcon />
                                                    </Tooltip> : <TableRowsIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            /> : null
                                        }
                                        <Item
                                            title="Mis Operaciones"
                                            to="/misoperaciones"
                                            icon={collapsed ?
                                                <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mis Operaciones</Typography>}>
                                                    <ReceiptLongOutlined />
                                                </Tooltip> : <ReceiptLongOutlined />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        /> </> : null
                            }

                            <Item
                                title="Mi Cuenta"
                                to="/micuenta"
                                icon={collapsed ?
                                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mi Cuenta</Typography>}>
                                        <AccountCircleIcon />
                                    </Tooltip> : <AccountCircleIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </Box>
                    }

                </Menu>
                {!collapsed ?
                    <Box sx={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px"
                    }}>
                        {
                            theme.palette.mode == 'dark' ?
                                <img src={logoSOC} alt="Logo Responsive" style={!collapsed ? { height: "2.8rem" } : { height: "1.8rem" }} /> :
                                <img src={logoSOCDark} alt="Logo Responsive" style={!collapsed ? { height: "2.8rem", animation: "width-increase 3s ease infinite" } : { height: "1.8rem", animation: "width-increase 3s ease infinite" }} />
                        }
                    </Box>
                    : null
                }
                {!collapsed ?
                    <Box sx={{
                        position: "absolute",
                        bottom: 50,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px",
                        display: { xs: 'block', sm: 'none' }
                    }}>
                        {
                            theme.palette.mode == 'dark' ?
                                <img src={logo} alt="Logo Responsive" style={{ height: "1.8rem" }} /> :
                                <img src={logolight} alt="Logo Responsive" style={{ height: "1.8rem" }} />
                        }
                    </Box>
                    : null}
            </Sidebar>
        </Box >
    ) : !loggedUser && loadingAPP ?
        <Box component="main" sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: '100%' }}>
            <LoadingScreen />
        </Box>
        : null
};

export default MyProSidebar;
