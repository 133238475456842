import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ModalRelaciones from './Modales/ModalRelaciones';
import TableCRUD from './TableCRUD';
import Tabla from './Tabla';
import { COLUMNASGLOBAL } from '../columnas/ColumnasRelaciones';
import { eliminarRelacionVinculacion, obtenerRelaciones, obtenerRelacionesFETCH } from '../Redux/RelacionDeVinculacion';
import { COLUMNASFIADORES } from '../columnas/ColumnasFiadores';
import TablaRelaciones from './TablaRelaciones';
import { loginToken } from '../Redux/Token';
import { Typography, Grid, Paper, Box } from '@mui/material';
import SpinnerF from './SpinnerF';
import { obtenerTipoDeDocumentos } from '../Redux/DatosSolicitudAlta';

const Relaciones = () => {
    const dispatch = useDispatch()
    //################ VINCULACIONES ####################
    const [relaciones, setRelaciones] = React.useState([])
    const [llamadaRelaciones, setLlamadaRelaciones] = React.useState(false)
    const relacionesSelector = useSelector(store => store.relaciones.relaciones)
    const [columnasGlobal, setColumnasGlobal] = React.useState(COLUMNASGLOBAL)

    const accountid = useSelector(store => store.usuarios.accountid)
    const [totalPorcentajeAccionario, setTotalPorcentajeAccionario] = React.useState(0)
    const [abrirModal, setAbrirModal] = React.useState(false)
    const [modo, setModo] = React.useState('')
    const [valoresAeditar, setValoresAeditar] = React.useState()
    const [relacionId, setRelacionId] = React.useState('')
    const token = useSelector(store => store.token.token)
    const [loadingData, setLoadingData] = React.useState(false)
    const loadingRelaciones = useSelector(store => store.relaciones.loadingRelaciones)
    const inactivacionSelector = useSelector(store => store.relaciones.inactivar)

    React.useEffect(() => {
        if (token == undefined || token == "") {
            dispatch(loginToken())
        }
    }, [token])

    React.useEffect(() => {
        setLoadingData(loadingRelaciones)
    }, [loadingRelaciones])

    React.useEffect(() => {
        if (relacionesSelector.length > 0 && llamadaRelaciones === true) {
            var relas = []
            let porcentaje = 0
            relacionesSelector.filter(relacion => relacion.statecode === 0).forEach(item => {
                var obj = {
                    personeria: item["aa.new_personeria@OData.Community.Display.V1.FormattedValue"],
                    contactid: item["ab.contactid"],
                    firstname: item["ab.firstname"],
                    lastname: item["ab.lastname"],
                    name: item["aa.name"],
                    emailaddress1: item["aa.emailaddress1"],
                    birthdate: item["ab.birthdate"],
                    new_nmerodedocumento: item["aa.new_nmerodedocumento"],
                    new_nrodedocumento: item["ab.new_nrodedocumento"],
                    new_cuitcuil: item["ab.new_cuitcuil"],
                    new_lugardenacimiento: item["ab.new_lugardenacimiento"],
                    telephone1: item["ab.telephone1"],
                    emailaddress1: item["ab.emailaddress1"],
                    emailadress1: item["aa.emailaddress1"],
                    new_correoelectrnicopararecibirestadodecuenta: item["ab.new_correoelectrnicopararecibirestadodecuenta"],
                    new_profesionoficioactividad: item["ab.new_profesionoficioactividad"],
                    new_name: item.new_name,
                    id: item.new_participacionaccionariaid,
                    new_porcentajedeparticipacion: item.new_porcentajedeparticipacion,
                    new_relacion: item["new_relacion@OData.Community.Display.V1.FormattedValue"],
                    new_tipoderelacion: item.new_tipoderelacion,
                    new_tipoderelacion_nombre: item["new_tipoderelacion@OData.Community.Display.V1.FormattedValue"],
                    _new_cuentacontactovinculado_value: item["_new_cuentacontactovinculado_value@OData.Community.Display.V1.FormattedValue"],
                    _new_cuentacontactovinculado_id: item._new_cuentacontactovinculado_value,
                    new_porcentajedeparticipacion: item.new_porcentajedeparticipacion,
                    new_porcentajebeneficiario: item.new_porcentajebeneficiario,
                    familystatuscode: item["ab.familystatuscode"],
                    new_cargo: item.new_cargo
                }
                relas.push(obj)
                if(obj.new_tipoderelacion === 100000001 && obj.new_porcentajedeparticipacion != undefined 
                    && obj.new_porcentajedeparticipacion > 0){
                    porcentaje += obj.new_porcentajedeparticipacion
                }
            })
            setTotalPorcentajeAccionario(porcentaje)
            setRelaciones(relas)
        } else if (token != undefined && token != '' && llamadaRelaciones === false) {
            obtenerRelacionesPorCuenta()
            setLlamadaRelaciones(true)
        } else if (relacionesSelector.length == 0 && llamadaRelaciones === true && inactivacionSelector === "EXITO") {
            setRelaciones([])
        }
    }, [relacionesSelector, token])

    // React.useEffect(() => {
    //     if (tipoDocumentoSelector.length > 0 && llamadaDocumentos === true) {
    //         const opcionesDocumentos = [];
    //         tipoDocumentoSelector.forEach(element => {
    //             if (element.new_onboarding === true) {
    //                 var tipo = { value: element.new_tipodedocumentoid, label: element.new_name }
    //                 opcionesDocumentos.push(tipo);
    //             }
    //         });
    //         setTiposDocumentos(opcionesDocumentos)
    //     } else if (token != '' && llamadaDocumentos === false) {
    //         obtenerTiposDocumentos()
    //         setLlamadaDocumentos(true)
    //     }
    // }, [tipoDocumentoSelector, token])


    const obtenerRelacionesPorCuenta = () => {
        dispatch(obtenerRelaciones(accountid, token.token))
    }

    const relacionesFETCH = () => {
        dispatch(obtenerRelacionesFETCH(accountid, token.token))
    }

    // const obtenerTiposDocumentos = async () => {
    //     dispatch(obtenerTipoDeDocumentos(token.token))
    // }

    const cerrarModal = () => {
        setAbrirModal(false)
        setModo('')
    }

    const handleModal = (id, obj, editar) => {
        if (editar === true) {
            const { new_tipoderelacion, firstname, lastname, new_cuitcuil, new_nrodedocumento,
                emailaddress1, new_correoelectrnicopararecibirestadodecuenta, telephone1,
                birthdate, new_lugardenacimiento, new_profesionoficioactividad, familystatuscode,
                name, new_nmerodedocumento, emailadress1, personeria, new_porcentajedeparticipacion, new_porcentajebeneficiario, contactid, accountid, new_cargo
            } = obj

            if (new_nmerodedocumento !== undefined) {
                const defaultValues = {
                    accountid: accountid,
                    tipo: 'Juridica',
                    tipoRelacion: new_tipoderelacion,
                    razonSocial: name,
                    Nombre: firstname,
                    Apellido: lastname,
                    CUITCUIL: new_nmerodedocumento,
                    DNI: new_nrodedocumento,
                    fecha: birthdate,
                    lugarNacimiento: new_lugardenacimiento,
                    correo: emailadress1,
                    correo2: new_correoelectrnicopararecibirestadodecuenta,
                    ProfesionOficioActividad: new_profesionoficioactividad,
                    telefonoLaboral: telephone1,
                    estadoCivil: familystatuscode,
                    porcentajeParticipacion: new_porcentajedeparticipacion,
                    porcentaje: new_porcentajebeneficiario,
                    cargo: new_cargo,
                };
                setModo('editar')
                setRelacionId(id)
                setValoresAeditar(defaultValues)
            } else {
                const defaultValues = {
                    contactid: contactid,
                    tipo: 'Humana',
                    tipoRelacion: new_tipoderelacion,
                    razonSocial: name,
                    Nombre: firstname,
                    Apellido: lastname,
                    CUITCUIL: new_cuitcuil,
                    DNI: new_nrodedocumento,
                    fecha: birthdate,
                    lugarNacimiento: new_lugardenacimiento,
                    correo: emailaddress1,
                    correo2: new_correoelectrnicopararecibirestadodecuenta,
                    ProfesionOficioActividad: new_profesionoficioactividad,
                    telefonoLaboral: telephone1,
                    estadoCivil: familystatuscode,
                    porcentajeParticipacion: new_porcentajedeparticipacion,
                    porcentaje: new_porcentajebeneficiario,
                    cargo: new_cargo,
                };
                setModo('editar')
                setRelacionId(id)
                setValoresAeditar(defaultValues)
            }
        } else {
            setModo('agregar')
        }

        setAbrirModal(true)
    }

    const funcionEliminarRelacion = (objeto) => {
        const { id } = objeto
        if (token !== {}) {
            dispatch((dispatch) => {
                dispatch(eliminarRelacionVinculacion(objeto, accountid, id, token.token))
                    .then(() => {
                        // Actualiza la tabla cuando se cumple la promesa
                        dispatchearAccion()
                    });
            });
        }
    }

    const dispatchearAccion = () => {
        dispatch(obtenerRelaciones(accountid, token.token))
        relacionesFETCH()
    }

    return (
        <Grid container spacing={4} sx={{ pt: 2 }}>
            <Grid item xs={12}>
                <Paper elevation={4} >
                    {
                        loadingData ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    py: 2
                                }}>
                                <SpinnerF />
                            </Box>
                        )
                            :
                            <TablaRelaciones
                                lineas={relaciones}
                                columnas={columnasGlobal}
                                handleOpen={handleModal}
                                titulo={"Relaciones"}
                                funcionEliminar={funcionEliminarRelacion}
                            />
                    }

                </Paper>
            </Grid>
            <ModalRelaciones
                open={abrirModal}
                close={cerrarModal}
                modo={modo}
                id={relacionId}
                objeto={valoresAeditar}
                porcentaje={totalPorcentajeAccionario}
            />
        </Grid>
    )
}

export default Relaciones