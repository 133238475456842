import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CustomSearchSelect from '../Components/CustomSearchSelect';
import CustomTextField from '../Components/CustomTextField';
import CustomSelect from '../Components/CustomSelect';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { obtenerTipoDeDocumentos } from '../Redux/DatosSolicitudAlta';
import { Typography } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const SolicitudAltaGeneral = ({token}) => {
    const dispatch = useDispatch()
    const [tiposDocumentos, setTiposDocumentos] = React.useState([])
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const tipoDocumentoSelector = useSelector(store => store.datos.tiposDocumentos)
    const retrieveTipoDocumentoSelector = useSelector(store => store.datos.retrieveTipoDocumento)
    

    React.useEffect(() => {
        if (tipoDocumentoSelector.length > 0 && llamadaDocumentos === true) {
            const opcionesDocumentos = [];
            tipoDocumentoSelector.forEach(element => {
                if (element.new_onboarding === true) {
                    var tipo = { value: element.new_tipodedocumentoid, label: element.new_name }
                    opcionesDocumentos.push(tipo);
                }
            });
            setTiposDocumentos(opcionesDocumentos)
        } else if (token != '' && llamadaDocumentos === false) {
            obtenerTiposDocumentos()
            setLlamadaDocumentos(true)
        }
    }, [tipoDocumentoSelector, token])

    const obtenerTiposDocumentos = async () => {
        dispatch(obtenerTipoDeDocumentos(token.token))
    }

    //################# NOTA IMPORTANTE ##################
    //El componente custom: CustomTextField, debe recibir como parametro name EL NOMBRE DEFINIDO EN DEFAULTVALUES.
    //ejemplo: <CustomTextField Component={TextField}  name="nombreRazonSocial" label="Nombre o Razón Social" helperText="Por favor escribe tu nombre o razón social" rules={{ required: "Required!" }} />
    //name="nombreRazonSocial" porque en solicitudAlta en defaultValues = nombreRazonSocial: ''

    const personeriaOpciones = [
        { value: '100000000', label: 'Jurídica' },
        { value: '100000001', label: 'Humana' }
    ]

    const productosServicios = [
        { value: '100000000', label: 'SGR' },
        // { value: '100000001', label: 'ALYC' }
    ]

    const theme = useTheme();

    return (
        <>
            <Paper elevation={4}>
                {/* <Grid xs={12} sx={{ bgcolor: theme.palette.primary.main, color: '#eee', p: 1, borderRadius: '5px', m: 0 }}>
                    <Typography variant="subtitle1" sx={{ m: 0 }} gutterBottom>
                        Documentación requerida
                    </Typography>
                </Grid> */}
                <CardContent>
                    <Grid container spacing={1}>
                        {/* QUEDA COMENTADO EN BASE A QUE AHORA EL SERVICIO ES SGR POR DEFECTO, YA LO INCLUI EN 
                        EL CONTROLADOR DEL DEFAULTVALUES */}
                        {/* <Grid xs={6} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomSelect
                                name="servicio"
                                label="Servicio"
                                helperText="Por favor, seleccione el servicio a operar"
                                items={productosServicios}
                                // onChange={e => servicioOnChange(e)}
                                rules={{ required: "Required!" }}
                            />

                        </Grid> */}
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomSelect
                                name="personeria"
                                label="Personeria"
                                helperText="Por favor, seleccione la personeria"
                                items={personeriaOpciones}
                                rules={{ required: "Required!" }}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                name="nombreRazonSocial"
                                label="Nombre o Razón Social"
                                helperText="Por favor escribe tu nombre o razón social"
                                rules={{ required: "Required!" }}
                                req="true"
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomSearchSelect
                                name="tipoDeDocumento"
                                lab="Tipo de documento"
                                helperText="Por favor, seleccione el tipo de documento"
                                options={tiposDocumentos}
                                noOptions={retrieveTipoDocumentoSelector && tiposDocumentos.length === 0 ? true : false}
                                rules={{ required: "Required!" }}
                                req={true}
                                sx={{ fontWeight: 400 }}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                label="CUIT/CUIL"
                                name="cuitCuilCdi"
                                helperText="Por favor escribe tu CUIT/CUIL sin espacios ni guiones"
                                rules={{ required: "Required!" }}
                                req="true"
                            />
                            <FormHelperText sx={{ mx: 1, color: '#f57c00' }} id="outlined-weight-helper-text">
                                ! Sin espacios ni guiones !
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>
        </>
    )
}

export default SolicitudAltaGeneral