import React, { useState, createContext, useContext } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import MyProSidebar from "./MyProSidebar";
import LoadingScreen from '../../../Components/LoadingScreen'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
const SidebarContext = createContext({});

export const MyProSidebarProvider = ({ children, loggedUser, loadingAPP, mostrarLineas }) => {
  const dispatch = useDispatch()
  const [sidebarRTL, setSidebarRTL] = useState(false);
  const [sidebarBackgroundColor, setSidebarBackgroundColor] =
    useState(undefined);
  const [sidebarImage, setSidebarImage] = useState(undefined);
  //El componente MyProSidebarProvider crea un contexto de React llamado SidebarContext, que se utiliza para compartir el estado de la barra lateral (MyProSidebar) con cualquier componente hijo. Dentro del proveedor, se definen tres estados usando el hook useState:
  //sidebarRTL: un estado booleano que indica si la barra lateral se muestra de derecha a izquierda (RTL, right-to-left) o de izquierda a derecha (LTR, left-to-right).
  // sidebarBackgroundColor: el color de fondo de la barra lateral, que comienza como undefined.
  // sidebarImage: la imagen de fondo de la barra lateral, que comienza como undefined.

  return (
    <ProSidebarProvider>
      <SidebarContext.Provider
        value={{
          sidebarBackgroundColor,
          setSidebarBackgroundColor,

          sidebarImage,
          setSidebarImage,

          sidebarRTL,
          setSidebarRTL,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: sidebarRTL ? "row-reverse" : "row",
          }}
        >
          <MyProSidebar loggedUser={loggedUser} loadingAPP={loadingAPP} mostrarLineas={mostrarLineas} />
          {children}
        </div>
      </SidebarContext.Provider>
    </ProSidebarProvider>
  )
  //  : (
  //   <Box component="main">
  //     <LoadingScreen />
  //   </Box>
  // )
};

export const useSidebarContext = () => useContext(SidebarContext);
