import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    InputLabel,
    Typography,
    useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { obtenerPaises } from '../../Redux/Cuenta';
import { crearRelacionVinculacion, editarRelacionVinculacion, obtenerRelaciones, obtenerRelacionesFETCH } from '../../Redux/RelacionDeVinculacion';
import { obtenerTipoDeDocumentos } from '../../Redux/TipoDeDocumento';
import * as Yup from 'yup'
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import CustomTextField from '../CustomTextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'
import CustomSelect from '../CustomSelect';
import { loginToken } from '../../Redux/Token';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomSearchSelect from '../CustomSearchSelect';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import CustomCurrencyField from '../../Components/CustomCurrencyField';
import { readOnlyDatos } from '../../Keys'
//MODAL RELACIONES REPRESENTANTES LEGALES/APODERADOS

const ModalRelaciones = ({ open, close, modo, id, objeto, porcentaje }) => {
    const dispatch = useDispatch()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [tipo, setTipo] = React.useState('Contacto')
    const [valorTab, setValorTab] = React.useState('1');

    const [opcionesPaises, setOpcionesPaises] = React.useState([])
    const [llamadaPais, setLlamadaPais] = React.useState(false)
    const paisesSelector = useSelector(store => store.cuenta.paises)
    const accountid = useSelector(store => store.usuarios.accountid)

    const [tiposDocumentos, setTiposDocumentos] = React.useState([])
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const tipoDocumentoSelector = useSelector(store => store.tiposDocumentos.tiposDocumentos)
    const loadingRelacionSelector = useSelector(store => store.relaciones.loadingRelacion)
    const resultadoSelector = useSelector(store => store.relaciones.CreacionDatos)

    const token = useSelector(store => store.token.token)

    React.useEffect(() => {
        if (token == undefined || token == "") {
            dispatch(loginToken())
        }
    }, [token])

    React.useEffect(() => {
        if (resultadoSelector === "EXITO") {
            setTimeout(() => {
                close()
            }, 2000)
        }
    }, [resultadoSelector])

    const defaultValues = {
        tipo: null,
        tipoRelacion: null,
        // tipoDeDocumento: null,
        razonSocial: '',
        Nombre: '',
        Apellido: '',
        CUITCUIL: '',
        DNI: '',
        fecha: '',
        lugarNacimiento: '',
        correo: '',
        correo2: '',
        ProfesionOficioActividad: '',
        telefonoLaboral: '',
        estadoCivil: null,
        NombreConyuge: '',
        tipoDocumento: '',
        nrodoConyuge: '',
        generoConyuge: '',
        conyugeTrabaja: '',
        cargo: '',
        porcentaje: 0,
        porcentajeParticipacion: 0,
        Observaciones: '',
        tipoSujetoObligado: '',
        domicilioFiscal: '',
        tieneDomiUsa: '',
        CalleNroPisoDepto: '',
        Codigopostal: '',
        ciudad: '',
        provinciaEstado: '',
        paisRegion: '',
        personaExpuesta: '',
        CargoFuncionJerarquia: '',
        entetrabajo: '',
        RelacionPersona: '',
        fechaAlta: '',
        fechaBaja: '',
    };

    const cuitCuilValidator = Yup.string().test('cuitCuil', 'El CUIT/CUIL no es válido', (value) => {
        if (!value) {
            // Si el campo está vacío, se considera válido (Yup se encargará de manejar este error)
            return true;
        }
        return validarCuitCuil(value);
    });

    const validationSchema = Yup.object().shape({
        tipo: Yup.string().required("Required"),
        tipoRelacion: Yup.string().required("Required"),
        razonSocial: Yup.string().when('tipo', {
            is: 'Juridica',
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        }),
        Nombre: Yup.string().when('tipo', {
            is: 'Humana',
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        }),
        Apellido: Yup.string().when('tipo', {
            is: 'Humana',
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        }),
        CUITCUIL: cuitCuilValidator.required('Este campo es obligatorio'),
        DNI: Yup.string().when('tipo', {
            is: 'Humana',
            then: Yup.string().required(),
            otherwise: Yup.string()
        }),
        fecha: Yup.string().when('tipo', {
            is: 'Humana',
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        }),
        lugarNacimiento: Yup.string().when('tipo', {
            is: 'Humana',
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        }),
        correo: Yup.string().required("Required"),
        correo2: Yup.string().when('tipo', {
            is: 'Humana',
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        }),
        ProfesionOficioActividad: Yup.string().when('tipo', {
            is: 'Humana',
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        }),
        telefonoLaboral: Yup.string().when('tipo', {
            is: 'Humana',
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        }),
        tipoRelacion: Yup.string().required("Required"),
        // tipoDeDocumento: Yup.object().when('tipo', (tipo) => {
        //     return tipo == 'Juridica' ? Yup.object({
        //         value: Yup.string().required()
        //     }) : Yup.object().nullable(true)
        // }),
        // porcentajeParticipacion: Yup.string().when('tipoRelacion', (tipoRelacion) => {
        //     return tipoRelacion == '100000001' ? Yup.number().required() : Yup.number().notRequired()
        // }),
        // estadoCivil: Yup.string().when('tipo', (tipo) => {
        //     return tipo.value == 'Humana' ? Yup.string().required('Requerido') : Yup.string().notRequired()
        // }),
        // estadoCivil: Yup.string().when('tipo', {
        //     is: 'Humana',
        //     then: Yup.string().required(),
        //     otherwise: Yup.string().notRequired()
        // }),
    });

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    const {
        handleSubmit,
        // reset, 
        // trigger 
    } = methods;

    React.useEffect(() => {
        if (modo === 'editar') {
            methods.reset(objeto)
        } else {
            methods.reset(defaultValues)
        }
    }, [modo])

    const dispatchearAccion = () => {
        dispatch(obtenerRelaciones(accountid, token.token))
    }

    const relacionesFETCH = () => {
        dispatch(obtenerRelacionesFETCH(accountid, token.token))
    }

    const ProcesarRelacion = (datos) => {
        dispatch((dispatch) => {
            debugger
            if (tipoWatch == "Juridica") {
                debugger
                if (porcentajeAccionaria != undefined) {
                    let porcentajeInt = parseFloat(porcentajeAccionaria)
                    if (porcentaje < 100 && (porcentajeInt > 0 && porcentajeInt < 100)) {
                        porcentaje += porcentajeInt
                        if (porcentaje > 100) {
                            toast.error('El porcentaje de tenencia accionaria debe conformar el 100%!', {
                                theme: "dark",
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            return
                        }
                    } else if (porcentaje > 100 || porcentajeInt > 100) {
                        toast.error('El porcentaje de tenencia accionaria debe conformar el 100%!', {
                            theme: "dark",
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        return
                    } else if (porcentaje >= 100 && porcentajeInt >= 100) {
                        toast.error('El porcentaje de tenencia accionaria debe conformar el 100%!', {
                            theme: "dark",
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        return
                    }
                }
            }

            dispatch(crearRelacionVinculacion(datos, accountid, token)).then(() => {
                // Actualiza la tabla cuando se cumple la promesa
                dispatchearAccion()
                relacionesFETCH()
            });
        });
    }
    const ProcesarEdicionRelacion = (datos) => {
        dispatch((dispatch) => {
            dispatch(editarRelacionVinculacion(datos, accountid, id, token)).then(() => {
                // Actualiza la tabla cuando se cumple la promesa
                dispatchearAccion()
                relacionesFETCH()
            });
        });
    }

    React.useEffect(() => {
        if (paisesSelector.length > 0 && llamadaPais === true) {
            var opciones = []
            paisesSelector.forEach(pais => {
                var pais = { value: pais.new_paisid, label: pais.new_name }
                opciones.push(pais);
            });
            //ordeno alfabeticamente
            opciones.sort(function (a, b) {
                if (a.label < b.label) { return -1; }
                if (a.label > b.label) { return 1; }
                return 0;
            })
            setOpcionesPaises(opciones)
        } else if (llamadaPais === false) {
            setLlamadaPais(true)
            obtenerTodosPaises()
        }

        if (tipoDocumentoSelector.length > 0 && llamadaDocumentos === true) {
            CompletarOpcionesTipoDocumentos(tipoDocumentoSelector)
        } else if (llamadaDocumentos === false) {
            obtenerTiposDocumentos()
            setLlamadaDocumentos(true)
        }

    }, [paisesSelector, tipoDocumentoSelector])

    const CompletarOpcionesTipoDocumentos = (tipos) => {
        const opcionesDocumentos = [];
        tipos.forEach(element => {
            if (element.new_onboarding === true) {
                var tipo = { value: element.new_tipodedocumentoid, label: element.new_name }
                opcionesDocumentos.push(tipo);
            }
        });
        setTiposDocumentos(opcionesDocumentos)
    }

    const obtenerTiposDocumentos = () => {
        dispatch(obtenerTipoDeDocumentos())
    }

    const obtenerTodosPaises = () => {
        dispatch(obtenerPaises())
    }

    const handleChangeTab = (event, newValue) => {
        setValorTab(newValue);
    };


    const estadoCivilOpciones = [
        { value: '1', label: 'Soltero/a' },
        { value: '2', label: 'Casado/a' },
        { value: '100000000', label: 'Concubinato' },
        { value: '3', label: 'Divorciado/a' },
        { value: '4', label: 'Viudo/a' }
    ]

    const sujetoOpciones = [
        { value: '100000000', label: 'Sujeto Obligado' },
        { value: '100000001', label: 'Sujeto No Obligado' }
    ]

    const sexoOpciones = [
        { value: '100000000', label: 'Masculino' },
        { value: '100000001', label: 'Femenino' },
        { value: '100000002', label: 'Otro' },
        { value: '100000003', label: 'No Especificar' },
    ]

    const personeria = [
        { value: 'Humana', label: 'Humana' },
        { value: 'Juridica', label: 'Jurídica' },
    ]

    const tipoRelacionesVinculacionJuridica = [
        { value: '100000001', label: 'Accionista' },
        { value: '100000002', label: 'Beneficiario' },
        { value: '100000003', label: 'Miembro del Directorio' },
        { value: '100000004', label: 'Representante Legal/Apoderado' },
        { value: '100000007', label: 'Asesor/Estructurador del proyecto' },
        { value: '100000005', label: 'Otro' }
    ]

    const tipoRelacionesVinculacionHumana = [
        { value: '100000000', label: 'Titular' },
        { value: '100000007', label: 'Asesor/Estructurador del proyecto' },
        { value: '100000005', label: 'Otro' }
    ]

    // const estadoCivilwatch = methods.watch('estadoCivil')
    const tipoWatch = methods.watch('tipo')
    const relacionWatch = methods.watch('tipoRelacion')
    const porcentajeAccionaria = methods.watch('porcentajeParticipacion')

    const validarCuitCuil = (numero) => {
        // Verificar que el número tenga 11 dígitos
        if (numero.length !== 11) {
            return false;
        }
        console.log(numero)

        // Verificar que el primer dígito sea 2 (para CUIT) o 3 (para CUIL)
        var primerDigito = parseInt(numero.charAt(0));
        if (primerDigito !== 2 && primerDigito !== 3) {
            return false;
        }

        // Multiplicar cada dígito del número por un coeficiente preestablecido según su posición en el número
        var coeficientes = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        var suma = 0;
        for (var i = 0; i < 10; i++) {
            var digito = parseInt(numero.charAt(i));
            suma += digito * coeficientes[i];
        }

        // Dividir la suma obtenida por 11 y tomar el residuo
        var residuo = suma % 11;

        // Verificar si el CUIT/CUIL es válido
        if (residuo === 0) {
            return parseInt(numero.charAt(10)) === 0;
        } else {
            var ultimoDigito = parseInt(numero.charAt(10));
            var resultado = 11 - residuo;
            return resultado === ultimoDigito;
        }
    }

    return (
        <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title" fullScreen>
            <form onSubmit={handleSubmit}>
                <AppBar sx={{ position: 'sticky' }}>
                    <Toolbar>
                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} sx={{ mt: 3 }} >
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={close}
                                aria-label="close"
                                sx={{ mr: 2 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                        {/* <IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton> */}
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {modo === 'editar' && !readOnlyDatos ? 'Editar relación' : null}
                            {modo !== 'editar' && !readOnlyDatos ? 'Agregar relación' : null}
                            {readOnlyDatos ? 'Relación' : null}
                        </Typography>
                        {/* <Button autoFocus color="inherit" onClick={close}> */}
                        {modo === 'editar' && !readOnlyDatos ?
                            (
                                <Button color="primary"
                                    onClick={handleSubmit(ProcesarEdicionRelacion)}
                                    variant="contained"
                                    disabled={loadingRelacionSelector}
                                >
                                    Editar
                                </Button>
                            )
                            : modo !== 'editar' && !readOnlyDatos ?
                                (
                                    <Button color="primary"
                                        onClick={handleSubmit(ProcesarRelacion)}
                                        variant="contained"
                                        disabled={loadingRelacionSelector}
                                    >
                                        Agregar
                                    </Button>
                                ) : null
                        }
                        {/* </Button> */}
                    </Toolbar>
                </AppBar>
                {/* <DialogTitle id="form-dialog-title">{modo === 'editar' ? 'Editar relación' : 'Agregar relación'}</DialogTitle> */}
                <Grid container spacing={1}>
                    <DialogContent sx={{ mx: 2 }}>
                        <FormProvider {...methods}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <CustomSelect
                                        name="tipo"
                                        label="Personeria"
                                        helperText="Por favor, seleccione un valor"
                                        items={personeria}
                                        rules={{ required: "Required!" }}
                                        variant="standard"
                                        readOnly={readOnlyDatos}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomSelect
                                        variant="standard"
                                        name="tipoRelacion"
                                        label="Relación de vinculación"
                                        helperText="Por favor, seleccione un valor"
                                        items={tipoWatch == 'Juridica' ? tipoRelacionesVinculacionJuridica : tipoRelacionesVinculacionHumana}
                                        rules={{ required: "Required!" }}
                                        readOnly={readOnlyDatos}
                                    />
                                </Grid>
                                {
                                    tipoWatch === 'Juridica' ?
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="Razón Social"
                                                    name="razonSocial"
                                                    helperText="Escribe la razón social"
                                                    required
                                                    rules={{ required: "Required!" }}
                                                    variant="standard"
                                                    InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="Correo Electrónico"
                                                    name="correo"
                                                    helperText="Escribe el Correo Electrónico"
                                                    required
                                                    rules={{ required: "Required!" }}
                                                    variant="standard"
                                                    InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                />
                                            </Grid>
                                            {/* <Grid xs={12} sm={6} >
                                                <CustomSearchSelect
                                                    name="tipoDeDocumento"
                                                    lab="Tipo de documento"
                                                    helperText="Por favor, seleccione el tipo de documento"
                                                    options={tiposDocumentos}
                                                    rules={{ required: "Required!" }}
                                                    req={true}
                                                    sx={{ fontWeight: 400 }}
                                                />
                                            </Grid> */}
                                            <Grid item xs={12} sm={6}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="CUIT/CUIL"
                                                    // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    name="CUITCUIL"
                                                    helperText="Escribe un CUIT/CUIL válido"
                                                    required
                                                    variant="standard"
                                                    rules={{ required: "Required!" }}
                                                    InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                />
                                            </Grid>

                                            {/* <Grid item xs={12} sm={6}>
                                            <CustomTextField
                                                Component={TextField}
                                                label="Correo Electrónico para recibir Estado de Cuenta"
                                                name="correo2"
                                                helperText="Escribe el Correo Electrónico"
                                                required
                                            />
                                        </Grid> */}

                                            {/* <Grid item xs={12} sm={6}>
                                            <CustomTextField
                                                Component={TextField}
                                                label="Profesión, Oficio o Actividad"
                                                name="ProfesionOficioActividad"
                                                helperText="Escribe el oficio"
                                                required
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <CustomTextField
                                                Component={TextField}
                                                label="Teléfono Laboral"
                                                name="telefonoLaboral"
                                                helperText="Escribe el Teléfono"
                                                required
                                            />
                                        </Grid> */}
                                        </>
                                        :
                                        null
                                }
                                {
                                    tipoWatch === 'Humana' ?



                                        (
                                            <>
                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        Component={TextField}
                                                        label="Nombre"
                                                        name="Nombre"
                                                        helperText="Escribe el nombre"
                                                        required
                                                        variant="standard"
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        Component={TextField}
                                                        label="Apellido"
                                                        name="Apellido"
                                                        helperText="Escribe el apellido"
                                                        required
                                                        variant="standard"
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        Component={TextField}
                                                        label="CUIT/CUIL"
                                                        name="CUITCUIL"
                                                        helperText="Escribe un CUIT/CUIL válido"
                                                        required
                                                        variant="standard"
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>

                                                    <CustomTextField
                                                        Component={TextField}
                                                        label="DNI"
                                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                        name="DNI"
                                                        helperText="Escribe el DNI"
                                                        required
                                                        variant="standard"
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    />

                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        Component={TextField}
                                                        label="Lugar de nacimiento"
                                                        name="lugarNacimiento"
                                                        helperText="Escribe el lugar de nacimiento"
                                                        required
                                                        variant="standard"
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} sx={{ pt: 2 }}>
                                                    <Controller
                                                        name="fecha"
                                                        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (<LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                                                            <DesktopDatePicker
                                                                {...field}
                                                                views={['day', 'month', 'year']}
                                                                inputRef={ref}
                                                                label="Fecha de nacimiento:"
                                                                renderInput={(inputProps) => (
                                                                    <TextField
                                                                        variant="standard"
                                                                        {...inputProps}
                                                                        onBlur={onBlur}
                                                                        name={name}
                                                                        error={!!fieldState.error}
                                                                        helperText={fieldState.error ? 'Indica tu fecha de nacimiento' : null}
                                                                        fullWidth
                                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        variant="standard"
                                                        Component={TextField}
                                                        label="Correo Electrónico"
                                                        name="correo"
                                                        helperText="Escribe el Correo Electrónico"
                                                        required
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        Component={TextField}
                                                        label="Correo Electrónico para recibir Estado de Cuenta"
                                                        name="correo2"
                                                        helperText="Escribe el Correo Electrónico"
                                                        required
                                                        variant="standard"
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        Component={TextField}
                                                        label="Profesión, Oficio o Actividad"
                                                        name="ProfesionOficioActividad"
                                                        helperText="Escribe el oficio"
                                                        required
                                                        variant="standard"
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        Component={TextField}
                                                        label="Teléfono Laboral"
                                                        name="telefonoLaboral"
                                                        helperText="Escribe el Teléfono"
                                                        required
                                                        variant="standard"
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    />
                                                </Grid>

                                                {/* <Grid item xs={12} sm={6}>
                                                <CustomSelect
                                                    name="estadoCivil"
                                                    label="Estado Civil"
                                                    helperText="Por favor, seleccione un valor"
                                                    items={estadoCivilOpciones}
                                                // rules={{ required: "Required!" }}
                                                />
                                            </Grid> */}
                                                {/* {
                                                estadoCivilwatch === '2' ?
                                                    (
                                                        <>
                                                            <Divider />


                                                            <Grid item xs={12} sm={6}>
                                                                <CustomTextField
                                                                    Component={TextField}
                                                                    label="Nombre Conyuge"
                                                                    name="NombreConyuge"
                                                                    helperText="Escribe el nombre de tu conyuge"
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} sm={6}>
                                                                <CustomSelect
                                                                    name="tipoDocumento"
                                                                    label="Tipo de Documento"
                                                                    helperText="Por favor, seleccione un valor"
                                                                    items={tiposDocumentos}
                                                                    rules={{ required: "Required!" }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} sm={6}>
                                                                <CustomTextField
                                                                    Component={TextField}
                                                                    label="Nro. de Documento Conyuge"
                                                                    name="nrodoConyuge"
                                                                    helperText="Escribe el nro de documento de tu conyuge"
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} sm={6}>
                                                                <CustomSelect
                                                                    name="generoConyuge"
                                                                    label="Género del Cónyuge"
                                                                    helperText="Por favor, seleccione un valor"
                                                                    items={sexoOpciones}
                                                                    rules={{ required: "Required!" }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} sm={6}>
                                                                <InputLabel id="demo-multiple-name-label">Cónyuge Trabaja?</InputLabel>
                                                                {/* <Controller
                                                                 name="conyugeTrabaja"
                                                                 defaultValue={false}
                                                                 render={({ value: valueProp, onChange }) => {
                                                                     return (
                                                                         <Switch
                                                                             value={valueProp}
                                                                             onChange={(event, val) => {
                                                                                 if (val) {
                                                                                     setValue(`input2`, record.price);
                                                                                 }
                                                                                 return onChange(val);
                                                                             }}
                                                                         />
                                                                     );
                                                                 }}
                                                             /> */}
                                                {/* <Switch name='conyugeTrabaja'
                                                                // checked={switchConyugeTrabajo} 
                                                                // onChange={switchConyugeTrabajoOnchange}
                                                                />
                                                            </Grid>



                                                        </>
                                                    )
                                                    : null
                                            } */}
                                            </>
                                        ) : null
                                }
                            </Grid>
                            <Grid container spacing={2}>
                                {
                                    relacionWatch == '100000003' && (
                                        <>

                                            <Divider sx={{ mt: 2, mb: 2 }} />
                                            <Grid item xs={12} sm={6} sx={{ pt: 2 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>Miembro del Directorio</Typography>
                                                    <CustomTextField
                                                        Component={TextField}
                                                        label="Cargo"
                                                        name="cargo"
                                                        helperText="Escribe el cargo"
                                                        variant="standard"
                                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }

                                {
                                    relacionWatch == '100000002' && (
                                        <>
                                            <Divider sx={{ mt: 2, mb: 2 }} />
                                            <Grid item xs={12} sm={6}>
                                                <Typography>Beneficiario</Typography>
                                                <CustomTextField
                                                    Component={TextField}
                                                    type="number"
                                                    label="Porcentaje"
                                                    name="porcentaje"
                                                    helperText="Escribe el porcentaje"
                                                    variant="standard"
                                                    InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                />
                                            </Grid>
                                        </>
                                    )
                                }

                                {
                                    relacionWatch == '100000001' && (
                                        <>

                                            <Divider sx={{ mt: 2, mb: 2 }} />
                                            <Grid item xs={12} sm={6}>
                                                <Typography>Participación Accionaria</Typography>
                                                <CustomCurrencyField
                                                    // Component={TextField}
                                                    name="porcentajeParticipacion"
                                                    label="% de Participacion"
                                                    rules={{ required: "Required!" }}
                                                    helperText="Escribe el porcentaje"
                                                    variant="standard"
                                                    symbol="%"
                                                    InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                                // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                                {/* <CustomTextField
                                                    Component={TextField}
                                                    type="number"
                                                    label="% de Participacion"
                                                    name="porcentajeParticipacion"
                                                    helperText="Escribe el porcentaje"
                                                    variant="standard"
                                                /> */}
                                            </Grid>
                                        </>
                                    )
                                }

                                <Divider sx={{ mt: 2, mb: 2 }} />
                                <Grid item xs={12} sm={12} sx={{ width: '100%' }}>
                                    <Typography>observaciones</Typography>
                                    <CustomTextField
                                        Component={TextField}
                                        multiline
                                        label="Observaciones"
                                        name="observaciones"
                                        helperText="Escribe alguna observación"
                                        InputProps={{ readOnly: readOnlyDatos, disableUnderline: false }}
                                    />
                                </Grid>
                            </Grid>
                        </FormProvider>

                    </DialogContent>
                    {/* <Grid container justifyContent="flex-end" sx={{ p: 2 }}>
                    <Grid item>
                        <Button
                            color='primary'
                            onClick={close}
                        >Cerrar</Button>
                    </Grid>
                    <Grid item>
                        {modo === 'editar' ?
                            (
                                <Button color="secondary"
                                    onClick={handleSubmit(ProcesarEdicionRelacion)}
                                    variant="contained">
                                    Editar
                                </Button>
                            )
                            :
                            (
                                <Button color="secondary"
                                    onClick={handleSubmit(ProcesarRelacion)}
                                    variant="contained">
                                    Agregar
                                </Button>
                            )
                        }
                    </Grid>
                </Grid> */}
                </Grid>
            </form>
        </Dialog >
    )
}

export default ModalRelaciones