let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const COLUMNSCERTIFICADOS = [
    {
        header: 'Número de Registro',
        accessorKey: 'new_numeroderegistro',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Vigencia Desde',
        accessorKey: 'new_vigenciadesde',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Vigencia Hasta',
        accessorKey: 'new_vigenciahasta',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Razón para el estado',
        accessorKey: 'statuscode',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]