import React from 'react'
import { styled } from '@mui/material/styles';
import { Box, Typography, Divider, useTheme, Paper } from '@mui/material';
import { COLUMNAOPERACIONES } from '../columnas/ColumnasOperaciones';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Fade from 'react-reveal/Fade';
import Tabla from './Tabla';
import {
    obtenerOperaciones, refreshOperaciones, obteneAcreedores,
    obtenerDestinoDeFondos, obtenerDocumentosPorOperacion, obtenerDocumentosDeOperacion
} from '../Redux/Operaciones';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import SpinnerF from './SpinnerF';
import ModalOperaciones from './Modales/ModalOperaciones';
import ModalNuevaOperacion from './Modales/ModalNuevaOperacion';
import { obtenerGarantiasFetch } from '../Redux/Garantias';
import { obtenerLimitePorLinea } from '../Redux/LimitePorLinea';
import moment from 'moment'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Container from '@mui/material/Container';

const Operaciones = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [operaciones, setOperaciones] = React.useState([])
    const [cargaOperacion, setCargaOperacion] = React.useState(false)
    const [garantias, setGarantias] = React.useState([])
    const [columnasOperaciones, setColumnasOperaciones] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [openNew, setOpenNew] = React.useState(false)
    const [op, setOp] = React.useState([])
    const [garantiaOp, setGarantiaOp] = React.useState([])
    const [operacionid, setOperacionid] = React.useState('')
    const [llamadaOperaciones, setLlamadaOperaciones] = React.useState(false)
    const [acreedores, setAcreedores] = React.useState([])
    const [destinoDeFondos, setDestinoDeFondos] = React.useState([])
    const [llamadaAcreedores, setLlamadaAcreedores] = React.useState(false)
    const [llamadaDestinoDeFondos, setLlamadaDestinoDeFondos] = React.useState(false)
    const [loadingData, setLoadingData] = React.useState(false)
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const [documentosOperacion, setDocumentosOperacion] = React.useState([])
    const [documentosOP, setDocumentosOP] = React.useState([])
    const [documentosDeOP, setDocumentosDeOP] = React.useState([])
    const [llamadaDocumentosDeOP, setLlamadaDocumentosDeOP] = React.useState(false)
    const [value, setValue] = React.useState("0")
    const operacionesSelector = useSelector(store => store.operaciones.operaciones)
    const accountid = useSelector(store => store.usuarios.accountid)
    const cargaOPSelector = useSelector(store => store.operaciones.cargaOP)
    const cargaGarantiaSelector = useSelector(store => store.operaciones.cargaGarantia)
    const refreshOperacionesSelector = useSelector(store => store.operaciones.refreshOperaciones)
    const token = useSelector(store => store.token.token)
    const acredoresSelector = useSelector(store => store.operaciones.acreedores)
    const destinoDeFondosSelector = useSelector(store => store.operaciones.destinoDeFondos)
    const loadingOperaciones = useSelector(store => store.operaciones.loadingOperaciones)
    const garantiaSelector = useSelector(store => store.operaciones.garantia)
    const documentosXOperacionSelector = useSelector(store => store.operaciones.documentosXOperacion)
    const documentoXIDSelector = useSelector(store => store.operaciones.documentosXID)
    const documentoDeOPSelector = useSelector(store => store.operaciones.documentosDeOperacion)
    const [dataExport, setDataExport] = React.useState([])
    const headerDataExp = ["Nro de Operación", "Tipo de Operación", "Destino de Fondo", "Estado", "Monto de la Comisión", "Monto de la Operación",
        "Divisa", "Fecha de Creación"]

    React.useEffect(() => {
        try {
            if (accountid !== '') {
                if (llamadaOperaciones === true && operacionesSelector != undefined && operacionesSelector.length > 0) {
                    setColumnasOperaciones(COLUMNAOPERACIONES)
                    let OperacionesExport = []
                    let OperacionesFormat = []
                    let GarantiasFormat = []
                    let operacionesUnicas = [...new Map(operacionesSelector.map(item => [item["new_operacionid"], item])).values()]
                    let garantiasUnicas = [...new Map(operacionesSelector.map(item => [item["garantia.new_garantiaid"], item])).values()]
                    let documentacionesUnicas = [...new Map(operacionesSelector.map(item => [item["documentaciones.new_documentacionporoperacionid"], item])).values()]
                    operacionesUnicas.forEach(element => {
                        if (element["new_operacionid"] != undefined && element["new_operacionid"] != "") {
                            let operacionFormat = {
                                id: element["new_operacionid"],
                                new_nrooperacion: element["new_nrooperacion"],
                                new_fechadeenvio: element["new_fechadeenvio"] ? new Date(element["new_fechadeenvio"]) : '',
                                new_destinodefondo: element["_new_destinodefondo_value@OData.Community.Display.V1.FormattedValue"],
                                new_montototalcomision: element["new_montototalcomision@OData.Community.Display.V1.FormattedValue"],
                                new_acreedor: element["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"],
                                new_acreedor_value: element["_new_acreedor_value"],
                                new_referido: element["_new_referido_value@OData.Community.Display.V1.FormattedValue"],
                                new_socioprotector: element["_new_socioprotector_value@OData.Community.Display.V1.FormattedValue"],
                                new_destinodefondo: element["_new_destinodefondo_value@OData.Community.Display.V1.FormattedValue"],
                                new_tipooperacin: element["new_tipooperacin@OData.Community.Display.V1.FormattedValue"],
                                new_tipooperacin_value: element["new_tipooperacin"],
                                new_tipodecheque: element["new_tipodecheque@OData.Community.Display.V1.FormattedValue"],
                                new_productocomercial: element["_new_productocomercial_value@OData.Community.Display.V1.FormattedValue"],
                                new_tipogarantia: element["new_tipogarantia@OData.Community.Display.V1.FormattedValue"],
                                new_montototal: element["new_montototal"],
                                createdon: element["createdon@OData.Community.Display.V1.FormattedValue"],
                                fechaCreacion: new Date(element["createdon"]),
                                fechaCreacion_str: moment(new Date(element["createdon"])).format('DD/MM/yyyy HH:mm'),
                                new_fechadeinstrumentacion: element["new_fechadeinstrumentacion"] ? new Date(element["new_fechadeinstrumentacion"]) : '',
                                new_cantidadgarantias: element["new_cantidadgarantias"],
                                new_montodelaoperacion: element["new_montodelaoperacion@OData.Community.Display.V1.FormattedValue"],
                                statuscode: element["statuscode@OData.Community.Display.V1.FormattedValue"]
                            }
                            let operacionExport = {
                                id: element["new_operacionid"],
                                new_nrooperacion: element["new_nrooperacion"] ? element["new_nrooperacion"] : '',
                                new_tipodeoperacion: element["new_tipodeoperacion@OData.Community.Display.V1.FormattedValue"] ? element["new_tipodeoperacion@OData.Community.Display.V1.FormattedValue"] : '',
                                new_destinodefondo: element["_new_destinodefondo_value@OData.Community.Display.V1.FormattedValue"] ? element["_new_destinodefondo_value@OData.Community.Display.V1.FormattedValue"] : '',
                                statuscode_value: element["statuscode@OData.Community.Display.V1.FormattedValue"] ? element["statuscode@OData.Community.Display.V1.FormattedValue"] : '',
                                new_montototalcomision: element["new_montototalcomision@OData.Community.Display.V1.FormattedValue"] ? element["new_montototalcomision@OData.Community.Display.V1.FormattedValue"] : '',
                                new_montodelaoperacion: element["new_montodelaoperacion@OData.Community.Display.V1.FormattedValue"] ? element["new_montodelaoperacion@OData.Community.Display.V1.FormattedValue"] : '',
                                transactioncurrencyid: element["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"] ? element["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"] : '',
                                fechaCreacion: element["createdon"] ? moment(new Date(element["createdon"])).format('DD/MM/yyyy HH:mm') : '',
                            }

                            OperacionesFormat.push(operacionFormat)
                            OperacionesExport.push(operacionExport)
                        }
                    })
                    OperacionesFormat.sort(function (a, b) {
                        return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
                    });
                    OperacionesExport.sort(function (a, b) {
                        return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
                    });
                    setOperaciones(OperacionesFormat)
                    setDataExport(OperacionesExport)

                    garantiasUnicas.forEach(element => {
                        if (element["garantia.new_garantiaid"] != undefined && element["garantia.new_garantiaid"] != "") {
                            let garantiaFormat = {
                                id: element["garantia.new_garantiaid"],
                                new_operacionid: element["new_operacionid"],
                                new_ndeordendelagarantiaotorgada: element["garantia.new_ndeordendelagarantiaotorgada"],
                                new_tipodeoperacion: element["garantia.new_tipodeoperacion@OData.Community.Display.V1.FormattedValue"],
                                new_acreedor: element["garantia._new_acreedor_value@OData.Community.Display.V1.FormattedValue"],
                                statuscode_value: element["garantia.statuscode@OData.Community.Display.V1.FormattedValue"],
                                new_monto: element["garantia.new_monto"],
                                new_fechadevencimiento: moment(new Date(element["garantia.new_fechadevencimiento"])).format('DD/MM/yyyy HH:mm'),
                                new_tipodegarantias: element["garantia.new_tipodegarantias@OData.Community.Display.V1.FormattedValue"],
                                createdon: moment(new Date(element["createdon"])).format('DD/MM/yyyy HH:mm'),
                            }
                            GarantiasFormat.push(garantiaFormat)
                        }
                    })
                    setGarantias(GarantiasFormat)
                    dispatch(refreshOperaciones(false))
                    debugger
                    var documentosOPAUX = []
                    setLlamadaDocumentosDeOP(true)
                    documentacionesUnicas.forEach(element => {
                        let documentosFormat = {
                            id: element["documentaciones.new_documentacionporoperacionid"],
                            new_documentacionporoperacionid: element["documentaciones.new_documentacionporoperacionid"],
                            new_documento: element["documentaciones.new_documento@OData.Community.Display.V1.FormattedValue"],
                            _new_operacion_value: element["new_operacionid"],
                            new_name: element["documentaciones.new_name"],
                            statuscode: element["documentaciones.statuscode"],
                            new_fechadevencimiento: element["documentaciones.new_fechadevencimiento"] ? moment(new Date(element["documentaciones.new_fechadevencimiento"])).format('DD/MM/yyyy') : null,
                            utilidades: {
                                new_vinculocompartido: element["documentaciones.new_vinculocompartido"],
                                new_urlplantilla: element["documentos.new_urlplantilla"],
                                new_descripcion: element["documentos.new_descripcion"]
                            }
                        }
                        documentosOPAUX.push(documentosFormat)
                    })
                    setDocumentosDeOP(documentosOPAUX)
                    if (documentosOPAUX.length > 0 && operacionid != '') {
                        if (documentosOPAUX.filter(item => item._new_operacion_value == operacionid).length > 0) {
                            setDocumentosOP(documentosOPAUX.filter(item => item._new_operacion_value == operacionid))
                        }
                    }
                    if (cargaOperacion == true) {
                        setLlamadaOperaciones(false)
                        setCargaOperacion(false)
                    }
                    if (llamadaOperaciones == false) {
                        setLlamadaOperaciones(true)
                    }
                } else if (refreshOperacionesSelector == false && token != undefined
                    && llamadaOperaciones === false) {
                    setLlamadaOperaciones(true)
                    obtenerOperacion(accountid, token.token)
                }
            }
        }
        catch (error) {
            // console.log(error)
        }
    }, [operacionesSelector, accountid, token])

    React.useEffect(() => {
        setLoadingData(loadingOperaciones)
    }, [loadingOperaciones])

    React.useEffect(() => {
        if (cargaOPSelector == "EXITO") {
            setLlamadaOperaciones(true)
            setCargaOperacion(true)
            obtenerOperacion(accountid, token.token)
        }
    }, [cargaOPSelector])

    const obtenerOperacion = (cuenta_id, token_id) => {
        dispatch(obtenerOperaciones(cuenta_id, token_id))
    }

    const handleOpenModalTable = (id) => { //EL id lo pasa el js desde el jsx tabla
        setOpen(true)
        setOperacionid(id)
        setOp(operaciones.filter(item => item.id == id))
        if (garantias != undefined && garantias.length > 0) {
            if (garantias.filter(item => item.new_operacionid == id).length > 0) {
                setGarantiaOp(garantias.filter(item => item.new_operacionid == id))
            } else {
                setGarantiaOp([])
            }
        } else {
            setGarantiaOp([])
        }
        if (documentosDeOP != undefined && documentosDeOP.length > 0) {
            if (documentosDeOP.filter(item => item._new_operacion_value == id && item.id != undefined).length > 0) {
                setDocumentosOP(documentosDeOP.filter(item => item._new_operacion_value == id && item.id != undefined))
            } else {
                setDocumentosOP([])
            }
        } else {
            setDocumentosOP([])
        }
    }

    const handleOpenNewModalTable = () => {
        setOpenNew(true)
    }

    const handleClose = () => setOpen(false);

    const handleCloseNew = () => setOpenNew(false);

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, pt: 4, px: 1 }}>
            <Fade>
                <Grid sx={{ px: 2 }}>
                    <TabContext value={value}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            // bgcolor: 'background.paper'
                        }}>
                            <TabList
                                aria-label="scrollable force tabs example"
                                onChange={handleChangeTabs} variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <Tab label="Operaciones" value="0" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                            </TabList>
                        </Box>
                        <TabPanel value="0" sx={{ p: 0 }}>
                            <Grid container spacing={4} sx={{ pt: 2 }}>
                                <Grid item xs={12}>
                                    <Paper elevation={4}>
                                        {
                                            loadingData ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        py: 2
                                                    }}>
                                                    <SpinnerF />
                                                </Box>
                                            )
                                                :
                                                <Tabla
                                                    lineas={operaciones}
                                                    columnas={COLUMNAOPERACIONES(theme)}
                                                    handleOpen={handleOpenModalTable}
                                                    // handleOpenNew={handleOpenNewModalTable}
                                                    acciones={true}
                                                    exportData={true}
                                                    dataExp={dataExport}
                                                    headerDataExp={headerDataExp}
                                                    nombreArchivo="Operaciones"
                                                />
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Fade>
            <ModalOperaciones open={open} handleClose={handleClose} operacion={op}
                garantias={garantiaOp} acreedores={acreedores} documentosDeOP={documentosOP} />
            <ModalNuevaOperacion open={openNew} handleClose={handleCloseNew}
                acreedores={acreedores} destinoDeFondos={destinoDeFondos} />
        </Box >
    )
}

export default Operaciones