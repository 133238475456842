import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../img/Logonavbar.png';
import Container from '@mui/material/Container';
import { registrarUsuarioContacto, registrarUsuarioSGROC } from '../Redux/usuarios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { RecaptchaKey } from '../Keys'
import { toast } from 'react-toastify';
import { loginToken } from '../Redux/Token'
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

const schema = yup.object().shape({
    // nombre: yup.string().required('El nombre es requerido'),
    // apellido: yup.string().required('El apellido es requerido'),
    correo: yup.string().email('El correo electrónico no es válido').required('El correo electrónico es requerido'),
    password: yup.string().min(4, 'La contraseña debe tener al menos 4 caracteres').
        max(15, 'La contraseña no puede serperar los 15 caracteres').required('La contraseña es requerida'),
    repassword: yup.string().oneOf([yup.ref('password'), 'Las contraseñas deben coincidir']),
});

const Registrar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const resultado = useSelector(store => store.usuarios.resultadoRegistro)
    const loading = useSelector(store => store.usuarios.loadingRegistro)
    const [desabilitarRegistro, setDesabilitarRegistro] = React.useState(false)
    const [robot, setRobot] = React.useState(false)
    const token = useSelector(store => store.token.token)

    React.useEffect(() => {
        if (resultado !== undefined) {
            if (resultado !== '' && resultado == "EXITO") {
                setDesabilitarRegistro(false)
                reset();
                navigate('/')
            } else if (desabilitarRegistro == true) {
                setDesabilitarRegistro(false)
            }
        }
    }, [resultado])

    React.useEffect(() => {
        if (token == undefined || token == "") {
            dispatch(loginToken())
        }
    }, [token])

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = (data) => {
        if (!robot) {
            toast.error('Debes confirmar que no eres un robot!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        debugger
        dispatch(registrarUsuarioSGROC(data.correo, data.password, token.token))
        setDesabilitarRegistro(true)
    };

    const robotOnChange = (value) => {
        var valor = ""
        if (value) {
            valor = "True"
            setRobot(true)
        } else {
            valor = "False"
            setRobot(false)
        }
    }

    //loader en boton
    const buttonSx = {
        color: '#24292e',
        p: 1.2,
        // fontWeight: 'bold',
        ...(loading && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', }} gap="0.5rem">
                    <img src={logo} alt="Logo Responsive" style={{ height: "3rem" }} />
                </Box>
                <Typography component="h1" variant="h5" sx={{ mb: 2, mt: 4 }}>
                    Crear Cuenta
                </Typography>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="correo"
                                label="Correo"
                                {...register("correo")}
                                helperText={errors.correo?.message}
                                error={errors.correo}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Contraseña"
                                type="password"
                                id="password"
                                {...register("password")}
                                helperText={errors.password?.message}
                                error={errors.password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Repetir Contraseña"
                                type="password"
                                id="repassword"
                                {...register("repassword")}
                                helperText={errors.repassword?.message}
                                error={errors.repassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReCAPTCHA
                                size={12}
                                className=""
                                render="explicit"
                                sitekey={RecaptchaKey}
                                onChange={e => robotOnChange(e)} />
                        </Grid>
                    </Grid>
                    {/* <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={desabilitarRegistro}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Crear cuenta
                    </Button> */}
                    <Box sx={{ mt: 2, mb: 2, position: 'relative' }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={buttonSx}
                            disabled={loading}
                        // onClick={handleSubmit(ProcesarLogin)}
                        >
                            Crear cuenta
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                    <Box component="form" noValidate sx={{ mt: 3 }}>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/" variant="body2">
                                    Ya tienes una cuenta? Inicia sesion
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default Registrar;