import axios from 'axios'
import {Entidad, UrlApiDynamics} from '../Keys'
//Const
const dataInicial = {
    loading: false,
    tiposDocumentos: []
}

const TODOS_TIPDOCUMENTO_EXITO = 'TODOS_TIPDOCUMENTO_EXITO'
const LOADING = "LOADING"
const ERROR = 'ERROR'

//Reducers
export default function tipoDocumentosReducers(state = dataInicial, action){
    switch (action.type) {
        case ERROR: 
            return { ...dataInicial }
        case LOADING:
            return { ...state, loading: true }
        case TODOS_TIPDOCUMENTO_EXITO:
            return { ...state, tiposDocumentos: action.payload, loading: false }
        default:
            return { ...state }
    }
}

//Actions
export const obtenerTipoDeDocumentos = (token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })
    
    try {
        
        var entidad = 'new_tipodedocumentos'
        var fetch = "<fetch mapping='logical' distinct='false'>" +
        "<entity name='new_tipodedocumento'>" +
        "<attribute name='new_name' />" +
        "<attribute name='new_codigo' />" +
        "<attribute name='new_tipodedocumentoid' />" +
        "<attribute name='new_onboarding' />" +
        "<order attribute='new_name' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='new_onboarding' operator='eq' value='1' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";
        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        // const response = await axios.post(`${UrlApi}api/consultafetch`,
        //     {
        //         "entidad": entidad,
        //         "fetch": fetch
        //     },
        //     {
        //         headers: {
        //             "Authorization": `Bearer ${token}`
        //         }
        //     }
        // )
        dispatch({
            type: TODOS_TIPDOCUMENTO_EXITO,
            payload: response.data
        })      
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}