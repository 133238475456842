

export const COLUMNASSOCXBOLSA = [
    {
        accessorKey: 'id'
    },
    {
        header: 'Sociedad De Bolsa',
        accessorKey: '_new_sociedaddebolsa_value',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>,
    },
    {
        header: 'Cuenta Comitente',
        accessorKey: 'new_cuentacomitente',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]