import Chip from '@mui/material/Chip';
import DescargarNota from '../Components/DescargarNota';

export const COLUMNASDOCUMENTOSGARANTIA = [
    {
        accessorKey: 'id',
    },
    {
        header: 'Archivo',
        accessorKey: 'filename',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        Header: '',
        accessorKey: 'annotationid',
        Cell: ({ cell }) => { return cell != null ? <DescargarNota value={cell.getValue()} /> : '-' }
    }
]