import axios from 'axios'
import { Entidad, UrlApiDynamics, UrlApi, authMail, authPass } from '../Keys'
import { auth, firebase, storage } from '../Firebase'
import {
    // ToastContainer, 
    toast
} from 'react-toastify';
import { RepeatOneSharp } from '@mui/icons-material';

//constantes
const dataInicial = {
    loading: false,
    loadingSesion: false,
    loadingRegistro: false,
    loadingRecupero: false,
    activo: false,
    error: false,
    logoff: false,
    accountid: '',
    contactid: '',
    tipo: '',
    resultado: '',
    resultadoRegistro: '',
    resultadoRecupero: '',
    photoURL: '',
    user: [],
    status: false,
    estadoUsuario: false,
    loadingONsesion: false
}

//Types auth/wrong-password
const LOADING = 'LOADING'
const LOADING_ONSESION = 'LOADING_ONSESION'
const LOADING_SESION = 'LOADING_SESION'
const LOADING_REGISTRO = 'LOADING_REGISTRO'
const LOADING_RECUPERO = 'LOADING_RECUPERO'
const LIMPIAR_RECUPERO = 'LIMPIAR_RECUPERO'
const USUARIO_ERROR = 'USUARIO_ERROR'
const USUARIO_EXITO = 'USUARIO_EXITO'
const TIPO_EXITO = 'TIPO_EXITO'
const CERRAR_SESION = 'CERRAR_SESION'
const REGISTRO_USUARIO = 'REGISTRO_USUARIO'
const USUARIO_ACTUALIZACION = 'USUARIO_ACTUALIZACION'
const LIMPIAR_ERROR = 'LIMPIAR_ERROR'
const REGISTRO_USUARIO_EXISTENTE = 'REGISTRO_USUARIO_EXISTENTE'
const RECUPERO_EXITO = 'RECUPERO_EXITO'
const RECUPERO_ERROR = 'RECUPERO_ERROR'
const LIMPIAR_ESTADO = 'LIMPIAR_ESTADO'
const ERROR_SESION = 'ERROR_SESION'
const ESTADO_USUARIO = 'ESTADO_USUARIO'
const USUARIO_ACTIVADO = 'USUARIO_ACTIVADO'

export default function usuariosReducer(state = dataInicial, action) {
    switch (action.type) {
        case USUARIO_ACTUALIZACION:
            return { ...state, accountid: action.accountid, contactid: action.contactid, tipo: action.tipo, photoURL: action.payload }
        case REGISTRO_USUARIO:
            return { ...state, loading: false, user: action.payload, activo: true, accountid: action.accountid, contactid: action.contactid, error: false, resultadoRegistro: action.resultadoRegistro, loadingRegistro: action.loadingRegistro, Personeria: action.Personeria, Estado: action.Estado, Name: action.Name, Cuit: action.Cuit, photoURL: action.photoURL, loadingONsesion: action.loadingONsesion }
        case CERRAR_SESION:
            return { ...dataInicial, loading: false, activo: false, logoff: true, accountid: '', contactid: '', tipo: '', resultado: '', user: null }
        case USUARIO_EXITO:
            return { ...state, loadingSesion: action.loadingSesion, user: action.payload, activo: true, accountid: action.accountid, contactid: action.contactid, error: false, resultado: action.resultado, Personeria: action.Personeria, Estado: action.Estado, Name: action.Name, Cuit: action.Cuit, photoURL: action.photoURL, loadingONsesion: action.loadingONsesion }
        case USUARIO_ACTIVADO:
            return { ...state, user: action.payload }
        case REGISTRO_USUARIO_EXISTENTE:
            return { ...state, resultadoRegistro: action.resultadoRegistro, loadingRegistro: action.loadingRegistro }
        case USUARIO_ERROR:
            return { ...dataInicial, error: true, resultado: action.resultado, loadingSesion: action.loadingSesion }
        case LIMPIAR_ERROR:
            return { ...state, error: false }
        case LIMPIAR_ESTADO:
            return { ...state, logoff: true }
        case LOADING:
            return { ...state, resultado: action.resultado, loading: action.loading }
        case LOADING_SESION:
            return { ...state, resultado: action.resultado, loadingSesion: action.loadingSesion }
        case TIPO_EXITO:
            return { ...state, tipo: action.tipo }
        case LOADING_REGISTRO:
            return { ...state, resultadoRegistro: action.resultadoRegistro, loadingRegistro: action.loadingRegistro }
        case RECUPERO_EXITO:
            return { ...state, resultadoRecupero: action.resultadoRecupero, loadingRecupero: action.loadingRecupero }
        case RECUPERO_ERROR:
            return { ...state, resultadoRecupero: action.resultadoRecupero, loadingRecupero: action.loadingRecupero }
        case ERROR_SESION:
            return { ...state, loadingSesion: action.loadingSesion }
        case LOADING_RECUPERO:
            return { ...state, loadingRecupero: action.loadingRecupero }
        case LIMPIAR_RECUPERO:
            return { ...state, resultadoRecupero: action.resultadoRecupero }
        case ESTADO_USUARIO:
            return { ...state, estadoUsuario: action.estado }
        case LOADING_ONSESION:
            return { ...state, loadingONsesion: action.loadingONsesion }
        default:
            return { ...state }
    }
}

export const loginUsuario = (email, pass) => async (dispatch) => {
    dispatch({
        type: LOADING_SESION,
        loadingSesion: true
    })

    try {
        var existeDynamics = true
        var respAuth
        var Personeria = ''
        let accountid = undefined
        let name = ''
        let estado = ''
        let cuit = ''
        let photoURL = ''
        let respCuenta
        let token = ''
        var entidad = "accounts"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='account'>" +
            "<attribute name='accountid'/> " +
            "<attribute name='new_estadodelsocio'/> " +
            "<order attribute ='createdon' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='emailaddress1' operator='eq' value='" + email + "' />" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "</filter>" +
            "</entity>" +
            "</fetch>";

        const login = () => new Promise(async (resolve, reject) => {
            await axios.post(`${UrlApi}api/Usuarios/login`, {
                "email": authMail,
                "password": authPass
            }).then(resp => {
                token = resp.data
            }).catch(() => {
                reject('Error de Autenticacion')
            })

            await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token.token}`
                    }
                })
                .then((respMail) => {
                    if (respMail.data.length == 0) {
                        existeDynamics = false
                        reject('No existe una cuenta con ese correo en nuestro sistema')
                        return
                    } else {
                        respCuenta = respMail.data
                    }
                })
                .catch(() => {
                    existeDynamics = false
                    reject("Hubo un error al inciar sesión, por favor intente mas tarde")
                    return
                })

            if (existeDynamics) {
                await auth.signInWithEmailAndPassword(email, pass)
                    .then(response => {
                        respAuth = response
                    })
                    .catch(err => {
                        if (err.code == "auth/wrong-password") {
                            reject("La contraseña es incorrecta")
                        } else if (err.code = 'auth/user-not-found') {
                            reject("El correo es inexistente")
                        } else {
                            reject("El correo o la contraseña son incorrectos")
                        }
                        return
                    })
                if (respAuth != undefined) {
                    await firebase.collection('usuarios').doc(respAuth.user.uid).get()
                        .then((doc) => {
                            if (doc.exists) {
                                const documento = doc.data()
                                if (respCuenta != null && respCuenta[0].new_estadodelsocio !== documento.Estado) {
                                    firebase.collection('usuarios').doc(respAuth.user.uid).update("Estado", respCuenta[0].new_estadodelsocio)
                                    estado = respCuenta[0].new_estadodelsocio
                                } else {
                                    estado = documento.Estado
                                }
                                accountid = documento.accountid
                                Personeria = documento.Personeria
                                name = documento.Name
                                cuit = documento.Cuit
                                photoURL = documento.photoURL
                                estado = estado

                                localStorage.setItem('usuario', JSON.stringify({
                                    email: respAuth.user.email,
                                    uid: respAuth.user.uid,
                                    accountid: documento.accountid,
                                    Personeria: Personeria,
                                    Name: name,
                                    Estado: estado,
                                    Cuit: cuit,
                                    photoURL: documento.photoURL
                                }))

                                dispatch({
                                    type: USUARIO_EXITO,
                                    accountid: accountid,
                                    photoURL: photoURL,
                                    payload: {
                                        email: respAuth.user.email,
                                        uid: respAuth.user.uid,
                                        accountid: accountid,
                                        Personeria: Personeria,
                                        Name: name,
                                        Estado: estado,
                                        Cuit: cuit,
                                        photoURL: photoURL
                                    },
                                    loadingSesion: false
                                })
                                resolve()
                            }
                        })
                }
            }
        })

        const response = await toast.promise(
            login,
            {
                // pending: 'Autenticando...',
                // success: 'Bienvenido!! 👌',
                error: {
                    render({ data }) {
                        dispatch({
                            type: ERROR_SESION,
                            loadingSesion: false
                        })
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        dispatch({
            type: USUARIO_ERROR,
            loadingSesion: false
        })
    }
}

export const activarUsuarioFirebase = (uid, estado) => async (dispatch) => {
    try {
        await firebase.collection('usuarios').doc(uid).get()
            .then((doc) => {
                if (doc.exists) {
                    firebase.collection('usuarios').doc(uid).update("Estado", estado)
                        .then(() => {
                            let usuarioStorage = JSON.parse(localStorage.getItem("usuario"))
                            if (usuarioStorage != null) {
                                usuarioStorage.Estado = estado
                                localStorage.setItem("usuario", JSON.stringify(usuarioStorage))
                                dispatch({
                                    type: USUARIO_ACTIVADO,
                                    payload: usuarioStorage
                                })

                            }
                        })
                }
            })
    } catch {
        console.log("Error")
    }
}

export const registrarUsuarioSGROC = (email, pass, token) => async (dispatch) => {
    dispatch({
        type: LOADING_REGISTRO,
        resultadoRegistro: 'LOADING',
        loadingRegistro: true
    })

    var entidad = "accounts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='account'>" +
        "<attribute name='name'/> " +
        "<attribute name='accountid'/> " +
        "<attribute name='new_personeria'/>" +
        "<attribute name='new_estadodelsocio'/> " +
        "<attribute name='new_nmerodedocumento'/> " +
        "<order attribute ='createdon' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='emailaddress1' operator='eq' value='" + email + "' />" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    var Personeria = ''
    var accountid = ''
    var personeria = ''
    var name = ''
    var estado = ''
    var cuit = ''

    const registro = () => new Promise(async (resolve, reject) => {

        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((respMail) => {
                if (respMail.data.length == 0) {
                    reject('No existe una cuenta con ese correo en nuestro sistema')
                    return
                }
                accountid = respMail.data[0].accountid;
                personeria = respMail.data[0].new_personeria
                name = respMail.data[0].name
                estado = respMail.data[0].new_estadodelsocio
                cuit = respMail.data[0].new_nmerodedocumento

                if (personeria === 100000000) {
                    Personeria = 'J'
                } else {
                    Personeria = 'F'
                }
            })
            .catch(() => {
                reject("Hubo un error en el registro, por favor intente mas tarde")
                return
            })

        await auth.createUserWithEmailAndPassword(email, pass)
            .then(resp => {
                if (resp.user != null && resp.user.uid != null) {
                    firebase.collection('usuarios').doc(resp.user.uid).set({
                        email: resp.user.email,
                        uid: resp.user.uid,
                        accountid: accountid,
                        Personeria: Personeria,
                        Name: name,
                        Estado: estado,
                        Cuit: cuit
                    })

                    localStorage.setItem('usuario', JSON.stringify({
                        email: resp.user.email,
                        uid: resp.user.uid,
                        accountid: accountid,
                        Personeria: Personeria,
                        Name: name,
                        Estado: estado,
                        Cuit: cuit
                    }))

                    dispatch({
                        type: REGISTRO_USUARIO,
                        accountid: accountid,
                        resultadoRegistro: 'EXITO',
                        loadingRegistro: true,
                        payload: {
                            email: resp.user.email,
                            uid: resp.user.uid,
                            accountid: accountid,
                            Personeria: Personeria,
                            Name: name,
                            Estado: estado,
                            Cuit: cuit
                        }
                    })
                    resolve()
                } else {
                    reject("Hubo un error al crear la cuenta.")
                }
            })
            .catch(error => {
                if (error.code == "auth/account-exists-with-different-credential") {
                    reject("La cuenta ya existe con diferentes credenciales.")
                } else if (error.code == "auth/email-already-in-use") {
                    reject("El correo ya esta en uso.")
                } else if (error.code == "auth/credential-already-in-use") {
                    reject("Las credenciales estan en uso.")
                }
            })
    })

    const response = await toast.promise(
        registro,
        {
            // pending: 'Registrando...',
            success: 'Registro completado 👌',
            error: {
                render({ data }) {
                    dispatch({
                        type: REGISTRO_USUARIO_EXISTENTE,
                        resultadoRegistro: 'ERROR',
                        loadingRegistro: false
                    })
                    return `${data}`
                }
            }
        },
        {
            theme: "dark",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
    )
}

export const recuperarContraseña = (email) => async (dispatch) => {
    dispatch({
        type: LOADING_RECUPERO,
        resultadoRecupero: 'LOADING',
        loadingRecupero: true
    })

    try {
        const recupero = () => new Promise(async (resolve, reject) => {
            await auth.sendPasswordResetEmail(email)
                .then(resp => {
                    dispatch({
                        type: RECUPERO_EXITO,
                        resultadoRecupero: 'EXITO',
                        loadingRecupero: false
                    })
                    resolve()
                })
                .catch(err => {
                    if (err.code == 'auth/user-not-found') {
                        reject('No existe una cuenta con ese correo.')
                    }
                    else if (err.code == 'auth/too-many-requests') {
                        reject('Hemos bloqueado todas las peticiones de este correo, por favor, intentalo mas tarde.')
                    }
                    else {
                        reject('Correo invalido.')
                    }
                })
        })

        const response = await toast.promise(
            recupero,
            {
                pending: 'Enviando Correo de Recupero...',
                success: 'Recupero completado 👌',
                error: {
                    render({ data }) {
                        dispatch({
                            type: RECUPERO_ERROR,
                            resultadoRecupero: 'ERROR',
                            loadingRecupero: false
                        })
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )

    } catch (error) {
        dispatch({
            type: USUARIO_ERROR
        })
    }
}


export const limpiarRecupero = () => async (dispatch) => {
    dispatch({
        type: LIMPIAR_RECUPERO,
        resultadoRecupero: '',
    })
}


export const cerrarSesion = () => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        emptyCache()
        //Eliminamos el usuario del local storage
        localStorage.removeItem('usuario')
        //Cierra sesion en firestore
        dispatch({
            type: CERRAR_SESION
        })
        auth.signOut()
        // setTimeout(() => {

        // }, 500);
    } catch (error) {
        dispatch({
            type: USUARIO_ERROR
        })
    }
}

export const leerUsuarioActivo = () => (dispatch) => {
    dispatch({
        type: LOADING_ONSESION,
        loadingONsesion: true
    })
    if (localStorage.getItem('usuario')) {
        const user = JSON.parse(localStorage.getItem('usuario'))
        dispatch({
            type: USUARIO_EXITO,
            payload: user,
            accountid: user.accountid,
            contactid: user.contactid,
            Personeria: user.Personeria,
            Name: user.Name,
            Estado: user.Estado,
            Cuit: user.Cuit,
            photoURL: user.photoURL,
            loadingONsesion: false
        })
    }
}

function emptyCache() {
    if ('caches' in window) {
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // // Makes sure the page reloads. Changes are only visible after you refresh.
        // window.location.reload(true);
    }
}

export const obtenerTipo = () => async (dispatch) => {
    try {
        const user = JSON.parse(localStorage.getItem('usuario'))
        dispatch({
            type: TIPO_EXITO,
            tipo: user.tipo,
        })
    } catch (error) {
        dispatch({
            type: USUARIO_ERROR
        })
    }
}

export const actualizarAccountid = (uid) => async (dispatch) => {
    dispatch({
        type: LOADING_ONSESION,
        loadingONsesion: true
    })
    try {
        await firebase.collection('usuarios').doc(uid).get()
            .then((doc) => {
                //debugger;
                if (doc.exists) {
                    const documento = doc.data()
                    dispatch({
                        type: USUARIO_ACTUALIZACION,
                        accountid: documento.accountid,
                        contactid: documento.contactid,
                        Personeria: documento.Personeria,
                        Name: documento.Name,
                        Estado: documento.Estado,
                        Cuit: documento.Cuit,
                        photoURL: documento.photoURL,
                        loadingONsesion: false
                    })
                }
            })
    } catch (error) {
        dispatch({
            type: USUARIO_ERROR
        })
    }
}

export const limpiarError = () => (dispatch) => {
    dispatch({
        type: LIMPIAR_ERROR
    })
}

export const LimpiarEstados = () => (dispatch) => {
    dispatch({
        type: LIMPIAR_ESTADO
    })
}

export const actualizarFotoUid = (imgEditada) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    const user = auth.currentUser;
    let urlImg
    // console.log(user)
    try {
        const subirFoto = () => new Promise(async (resolve, reject) => {
            const imgRef = storage.ref().child(user.uid).child('foto perfil')
            await imgRef.put(imgEditada)
            const imgURL = await imgRef.getDownloadURL()
            urlImg = imgURL
            //uid
            await firebase.collection('usuarios').doc(user.uid).update({
                photoURL: imgURL,
            }).then(() => {
                if (localStorage.getItem('usuario')) {
                    const user = JSON.parse(localStorage.getItem('usuario'))
                    user.photoURL = urlImg
                    localStorage.setItem("usuario", JSON.stringify(user))
                }
                resolve()
            }).catch((error) => {
                reject(error)
            })
        })
        const response = await toast.promise(
            subirFoto,
            {
                pending: 'Procesando...',
                success: 'Foto subida con éxito!',
                error: {
                    render({ data }) {
                        // console.log(data)
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )

        await firebase.collection('usuarios').doc(user.uid).get()
            .then((doc) => {
                if (doc.exists) {
                    // debugger
                    const documento = doc.data()
                    let tipo = documento.tipo

                    if (tipo === "PYME") {
                        let accountid = documento.accountid
                        axios.post(`${UrlApiDynamics}account/fotodeperfil?accountId=${accountid}&url=${encodeURIComponent(urlImg)}&cuit=${Entidad}`)
                    } else {
                        // console.log("No es una pyme.")
                    }

                }
            })
        debugger
        dispatch({
            type: USUARIO_ACTUALIZACION,
            payload: urlImg
        })


    } catch (error) {
        // console.log(error)
    }
}


export const EstadoUsuario = (estado) => (dispatch) => {
    dispatch({
        type: ESTADO_USUARIO,
        estado: estado,
    })
}