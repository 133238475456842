import React from 'react'
import { Box, Typography, Divider, Button, Paper } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Container from '@mui/material/Container';
import Tabla from '../Tabla';
import { COLUMNSGOPERACIONES } from '../../columnas/ColumnasGarantias';
import { COLUMNASDOCUMENTOSOPERACION } from '../../columnas/ColumnasDocumentosOperacion'
import { useSelector, useDispatch } from 'react-redux';
import ModalNuevaGarantia from './ModalNuevaGarantia';
import ModalNuevoDocumentoOP from './ModalNuevoDocumentoOP';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { obtenerTodosLimitesPorLineas } from '../../Redux/LimitePorLinea'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

const ModalOperaciones = ({ open, handleClose, operacion, garantias, acreedores, documentosDeOP }) => {
    const dispatch = useDispatch()
    const theme = useTheme();
    const [value, setValue] = React.useState("0")
    const [nombreDoc, setNombreDoc] = React.useState('')
    const [montoTotalGarantias, setMontoTotalGarantias] = React.useState(0)
    const [operacionid, setOperacionid] = React.useState('')
    const [tipoOperacion, setTipoOperacion] = React.useState([])
    const [garantiasT, setGarantiasT] = React.useState([])
    const [acreedor, setAcreedor] = React.useState('')
    const [colDocumentosOP, setColDocumentosOP] = React.useState([])
    const [openNew, setOpenNew] = React.useState(false)
    const [openNewDoc, setOpenNewDoc] = React.useState(false)
    const [limites, setLimites] = React.useState([])
    const [llamadaLimites, setLlamadaLimites] = React.useState(false)
    const [documentosDOP, setDocumentoDOP] = React.useState([])
    const [documentoXOPID, setDocumentoXOPID] = React.useState('')

    const accountid = useSelector(store => store.usuarios.accountid)
    const limiteSelector = useSelector(store => store.limites.limites)
    const token = useSelector(store => store.token.token)
    const cargaDocumentoSelector = useSelector(store => store.operaciones.cargaDocumento)


    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    React.useEffect(() => {
        if (cargaDocumentoSelector == "EXITO") {
            if (openNewDoc == true) {
                setTimeout(() => {
                    setOpenNewDoc(false)
                }, 1500)
            }
        }
    }, [cargaDocumentoSelector])

    React.useEffect(() => {
        debugger
        if (operacion.length > 0) {
            setTipoOperacion(operacion.map(op => op.new_tipooperacin_value))
            var acreedorAux = operacion.map(op => (
                {
                    label: op.new_acreedor,
                    value: op.new_acreedor_value
                }
            ))
            setAcreedor(acreedorAux)
            setOperacionid(operacion.map(op => op.id))
        }
        setGarantiasT(COLUMNSGOPERACIONES)

    }, [operacion])

    React.useEffect(() => {
        debugger
        if (documentosDeOP.length > 0) {
            setColDocumentosOP(COLUMNASDOCUMENTOSOPERACION)
            setDocumentoDOP(documentosDeOP)
        } else {
            setColDocumentosOP([])
            setDocumentoDOP([])
        }

    }, [documentosDeOP])

    const closeModal = () => {
        setDocumentoDOP([])
        handleClose()
    }

    React.useEffect(() => {
        if (limiteSelector != undefined && limiteSelector.length > 0) {
            setLimites(limiteSelector)
        } else if (token != undefined && llamadaLimites === false) {
            dispatch(obtenerTodosLimitesPorLineas(accountid, token.token))
            setLlamadaLimites(true)
        }
    }, [limiteSelector])

    React.useEffect(() => {
        if (garantias.length > 0) {
            let montoTotal = 0
            garantias.forEach(item => {
                if (item.new_monto != undefined && item.new_monto != 0) {
                    montoTotal += item.new_monto
                }
            })
            setMontoTotalGarantias(montoTotal)
        }
    }, [garantias])

    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const handleOpenNewModalTable = () => {
        setOpenNew(true)
    }

    const handleOpenNewModalTableDoc = (id) => {
        setOpenNewDoc(true)
        setDocumentoXOPID(id)
        debugger
        // setNombreDoc(documentosDOP.filter(x => x.id = id).map(doc => doc.new_documento)[0])
        setNombreDoc(documentosDeOP.filter(x => x.id = id).map(doc => doc.new_documento)[0])
        // console.log(documentosDOP.filter(x => x.id = id).map(doc => doc.new_documento)[0])
    }

    const handleCloseNew = () => setOpenNew(false);

    const handleCloseNewDoc = () => setOpenNewDoc(false);
    //style del modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '100%',
        width: 'auto',
        bgcolor: 'background.default',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
        m: 1
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth="lg"
            // sx={{  }}
        >
            <DialogContent sx={{ m: 0, p: 0, backgroundColor: theme.palette.background.main }}>
                <Paper elevation={4} sx={{ m: 0, p: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                        <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem", pt: 1 }}>
                            Operaciones
                        </Typography>
                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={closeModal}
                                aria-label="close"
                                sx={{ mr: 2 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Divider />
                    <Container component="main">
                        <TabContext value={value}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                // bgcolor: 'background.paper'
                            }}>
                                <TabList
                                    aria-label="scrollable force tabs example"
                                    onChange={handleChangeTabs} variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                >
                                    <Tab label="Operación" value="0" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                    <Tab label="Garantías" value="1" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                    <Tab label="Documentación de la operación" value="2" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                    {/* <Tab label="Plan de Cuotas" value="2"></Tab>
                                    <Tab label="Monetización y Ponderación" value="3"></Tab> */}
                                </TabList>
                            </Box>
                            <TabPanel value="0" sx={{ p: 0, mt: 1, height: '450px', overflow: 'auto' }}>
                                {
                                    operacion.map(op =>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ p: 1 }}>
                                                <Typography sx={{ fontWeight: 'bold' }}>
                                                    Datos Generales
                                                </Typography>
                                                <Divider sx={{ mt: 1 }} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="N° de Operación" variant="standard" value={op.new_nrooperacion ? op.new_nrooperacion : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Acreedor" variant="standard" value={op.new_acreedor ? op.new_acreedor : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Referido" variant="standard" value={op.new_referido ? op.new_referido : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Socio Protector" variant="standard" value={op.new_socioprotector ? op.new_socioprotector : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Destino de fondos" variant="standard" value={op.new_destinodefondo ? op.new_destinodefondo : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Tipo de Operación" variant="standard" value={op.new_tipooperacin ? op.new_tipooperacin : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Tipo de Cheque" variant="standard" value={op.new_tipodecheque ? op.new_tipodecheque : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Producto Comercial" variant="standard" value={op.new_productocomercial ? op.new_productocomercial : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Tipo de Garantía" variant="standard" value={op.new_tipogarantia ? op.new_tipogarantia : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Monto de la operación" variant="standard" value={op.new_montodelaoperacion ? op.new_montodelaoperacion : '-'} />
                                            </Grid>
                                            <Grid xs={12} sx={{ p: 1 }}>
                                                <Typography item xs={12} sm={6} md={4} lg={3} sx={{ mt: 1, fontWeight: 'bold' }}>
                                                    KPI Fechas
                                                </Typography>
                                                <Divider sx={{ mt: 1 }} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Fecha de creación" variant="standard" value={op.fechaCreacion_str} />
                                                {/* <TextField fullWidth label="Fecha de creación" variant="standard" value={op.createdon ?  : '-'} /> */}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Fecha de Instrumentación" variant="standard" value={op.new_fechadeinstrumentacion ? op.new_fechadeinstrumentacion : '-'} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Fecha de Envío" variant="standard" value={op.new_fechadeenvio ? op.new_fechadeenvio : '-'} />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </ TabPanel>
                            <TabPanel value="1" sx={{ p: 0, mt: 1, height: '450px', overflow: 'auto' }}>
                                <Tabla
                                    lineas={garantias}
                                    columnas={garantiasT}
                                />
                                {
                                    operacion.map(op =>
                                        <Grid container>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Monto de la operación" variant="standard" value={dollarUS.format(montoTotalGarantias)} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Cantidad de Garantias" variant="standard" value={garantias.length} />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </ TabPanel>
                            <TabPanel value="2" sx={{ p: 0, mt: 1, height: '450px', overflow: 'auto' }}>
                                <Tabla
                                    lineas={documentosDOP}
                                    columnas={COLUMNASDOCUMENTOSOPERACION(theme)}
                                    handleOpen={handleOpenNewModalTableDoc}
                                    acciones={true}
                                    edicionRegistro={true}
                                />
                            </ TabPanel>
                            <ModalNuevoDocumentoOP
                                open={openNewDoc}
                                handleClose={handleCloseNewDoc}
                                documentoOPID={documentoXOPID}
                                documento={nombreDoc}
                            />
                        </TabContext>
                    </Container>
                </Paper>
            </DialogContent>
        </Dialog >
    )
}

export default ModalOperaciones