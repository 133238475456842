import React from 'react'
import { Box, Typography, Divider, Button, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Fade from 'react-reveal/Fade';
import Tabla from './Tabla';
import Container from '@mui/material/Container';
import { obtenerComprobantesDeVenta } from '../Redux/Cuenta'
import { COLUMNSCV } from '../columnas/ColumnasComprobanteDeVenta'
import SpinnerF from './SpinnerF';

const ComprobanteDeVenta = () => {
    //Hooks
    const dispatch = useDispatch()
    const [comprobantes, setComprobantes] = React.useState([])
    const [columnasComprobantes, setColumnasComprobantes] = React.useState([])
    const [llamadaComprobantes, setLlamadaComprobantes] = React.useState(false)
    //Selectores
    const comprobantesDeVentaSelector = useSelector(store => store.cuenta.comprobantesDeVenta)
    const comprobantesLoading = useSelector(store => store.cuenta.loadingComprobante)
    const token = useSelector(store => store.token.token)
    const accountid = useSelector(store => store.usuarios.accountid)
    //Funciones
    React.useEffect(() => {
        if (accountid != '') {
            if (comprobantesDeVentaSelector.length > 0) {
                setColumnasComprobantes(COLUMNSCV)
                let comprobantesVenta = []
                comprobantesDeVentaSelector.forEach(item => {
                    var comprovanteVenta = {
                        new_nrocomprobante: item["new_nrocomprobante"],
                        createdon: item['createdon@OData.Community.Display.V1.FormattedValue'],
                        new_TipodeComprobante: item['_new_tipodecomprobante_value@OData.Community.Display.V1.FormattedValue'],
                        new_total: item['new_total'],
                        transactioncurrency: item['_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue'],
                        nota: {
                            annotationid: item['nota.annotationid'],
                            filename: item['nota.filename'],
                            mimetype: item['nota.mimetype'],
                            // documentbody: item['nota.documentbody'],
                            urlafip: item['new_urlafip']
                        }
                    }
                    comprobantesVenta.push(comprovanteVenta)
                })
                setComprobantes(comprobantesVenta)
                setLlamadaComprobantes(true)
            } else if (token != undefined && llamadaComprobantes == false) {
                obtenerComprobantes()
                setLlamadaComprobantes(true)
            }
        }
    }, [comprobantesDeVentaSelector, token])

    const obtenerComprobantes = () => {
        dispatch(obtenerComprobantesDeVenta(accountid, token.token))
    }

    return (
        <Grid container spacing={4} sx={{ pt: 2 }}>
            <Grid item xs={12}>
                <Paper elevation={4} >
                    {
                        comprobantesLoading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    py: 2
                                }}>
                                <SpinnerF />
                            </Box>
                        )
                            :
                            !comprobantesLoading && comprobantes.length > 0 ?
                                (
                                    <Tabla
                                        lineas={comprobantes}
                                        columnas={columnasComprobantes}
                                        titulo={"Comprobantes de Venta"} />

                                ) :
                                !comprobantesLoading && comprobantesDeVentaSelector.length == 0 && llamadaComprobantes == true ?
                                    (
                                        <Tabla
                                            lineas={[]}
                                            columnas={columnasComprobantes}
                                            titulo={"Comprobantes de Venta"} />
                                    ) : null
                    }
                </Paper>
            </Grid>
        </Grid>
    )
}

export default ComprobanteDeVenta