import React from 'react'
import { Box, Typography, Divider, Button, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Fade from 'react-reveal/Fade';
import Tabla from './Tabla';
import { obtenerGarantiasFetch, obtenerAdjuntosGarantias } from '../Redux/Garantias';
import { COLUMNSGEG, COLUMNSGEC, COLUMNSGV, COLUMNSGVV, COLUMNSGA, COLUMNSGT } from '../columnas/ColumnasGarantias';
import SpinnerF from './SpinnerF';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ModalGarantias from './Modales/ModalGarantias';
import ExtensionOffOutlinedIcon from '@mui/icons-material/ExtensionOffOutlined';
import ModalNuevaGarantia from './Modales/ModalNuevaGarantia';
import moment from 'moment'
import { obteneAcreedores, obtenerDestinoDeFondos } from '../Redux/Operaciones';
import { useTheme } from '@mui/material';
import GraficoBarraApex from './Graficos/GraficoBarraApex';
import GraficoDonaApex from './Graficos/GraficoDonaApex';
import { obtenerLimitePorLineaPrestamo } from '../Redux/LimitePorLinea';

const estadosGarantia = ['Afrontada', 'Anulada', 'En Cartera', 'En Gestión', 'Honrada', 'Vencida', 'Vigente']
const estadosYcolores = [{ estado: "Afrontada", color: '#ffa726' }, { estado: "Anulada", color: '#f44336' },
{ estado: "En Cartera", color: '#f57c00' }, { estado: "En Gestión", color: '#66bb6a' }, { estado: "Honrada", color: '#42a5f5' },
{ estado: "Vencida", color: '#d32f2f' }, { estado: "Vigente", color: '#388e3c' }]

const Garantias = () => {
    const dispatch = useDispatch()
    //HOOKS DEL ACCORDEON
    const [expanded, setExpanded] = React.useState();
    const theme = useTheme();
    const [garantias, setGarantias] = React.useState([])
    const [llamadaGarantias, setLlamadaGarantias] = React.useState(false)
    const [estadoGarantias, setEstadoGarantias] = React.useState([])
    const [dataGarantia, setDataGarantia] = React.useState([])
    const [dataGarantiaCargada, setDataGarantiaCargada] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [openNew, setOpenNew] = React.useState(false)
    const [garantiaid, setGarantiaid] = React.useState('')
    const [loadingData, setLoadingData] = React.useState(false)
    const [llamadaAcreedores, setLlamadaAcreedores] = React.useState(false)
    const [llamadaDestinoDeFondos, setLlamadaDestinoDeFondos] = React.useState(false)
    const [llamadaAdjuntosgarantia, setLlamadaAdjuntosgarantia] = React.useState(false)
    const [acreedores, setAcreedores] = React.useState([])
    const [destinoDeFondos, setDestinoDeFondos] = React.useState([])
    const [adjuntosGarantia, setAdjuntosGarantia] = React.useState([])
    const [dataExport, setDataExport] = React.useState([])
    const [montoDisponible, setMontoDisponible] = React.useState(0)

    const accountid = useSelector(store => store.usuarios.accountid)
    const garantiasSelector = useSelector(store => store.garantias.garantias)
    const garantiasFetchSelector = useSelector(store => store.garantias.garantiasFetch)
    const loadingGarantias = useSelector(store => store.garantias.loadingGarantias)
    const adjuntosGarantiaSelector = useSelector(store => store.garantias.adjuntosGarantia)
    const token = useSelector(store => store.token.token)
    const acredoresSelector = useSelector(store => store.operaciones.acreedores)
    const destinoDeFondosSelector = useSelector(store => store.operaciones.destinoDeFondos)
    const [coloresGarantias, setColoresGarantias] = React.useState([])
    const limiteSelector = useSelector(store => store.limites.limitePrestamo)

    //Columnas
    const [garantiasT, setGarantiasT] = React.useState([])
    const [garantiasEC, setGarantiasEC] = React.useState([])

    //values de los tabs - siempre el numero como string
    const [value, setValue] = React.useState("0")
    const headerDataExp = ["Nro de Orden", "Tipo de Operación", "Acreedor", "Estado", "Monto", "Divisa",
        "Fecha de Vencimiento", "Fecha de Creación", "Formato del Cheque", "Nro de Cheque", "Tipo CHPD"]

    React.useEffect(() => {
        if (accountid !== '') {
            if (llamadaGarantias === true && garantiasFetchSelector != undefined && garantiasFetchSelector.length > 0) {
                setGarantiasT(COLUMNSGT)
                setGarantiasEC(COLUMNSGEC)
                let GarantiasFormat = []
                let GarantiasExport = []
                garantiasFetchSelector.forEach(element => {
                    let garantiaFormat = {
                        id: element["new_garantiaid"],
                        new_ndeordendelagarantiaotorgada: element["new_ndeordendelagarantiaotorgada"],
                        new_tipodeoperacion: element["new_tipodeoperacion@OData.Community.Display.V1.FormattedValue"],
                        new_acreedor: element["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"],
                        new_acreedor_value: element["_new_acreedor_value"],
                        statuscode_value: element["statuscode@OData.Community.Display.V1.FormattedValue"],
                        statuscode: element["statuscode"],
                        new_monto: element["new_monto"],
                        new_fechadevencimiento: moment(new Date(element["new_fechadevencimiento"])).format('DD/MM/yyyy'),
                        new_tipodegarantias: element["new_tipodegarantias@OData.Community.Display.V1.FormattedValue"],
                        fechaCreacion: new Date(element["createdon"]),
                        createdon: moment(new Date(element["createdon"])).format('DD/MM/yyyy HH:mm'),
                        transactioncurrencyid: element["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"],
                        new_sociosprotector: element["_new_sociosprotector_value@OData.Community.Display.V1.FormattedValue"],
                        new_fechadecancelada: moment(new Date(element["new_fechadecancelada"])).format('DD/MM/yyyy'),
                        new_fechadeanulada: moment(new Date(element["new_fechadeanulada"])).format('DD/MM/yyyy'),
                        new_librador: element["_new_libradorcheque_value@OData.Community.Display.V1.FormattedValue"],
                        new_formatodelcheque: element["new_formatodelcheque@OData.Community.Display.V1.FormattedValue"],
                        new_numerodecheque: element["new_numerodecheque"],
                        new_tipochpd: element["new_tipochpd@OData.Community.Display.V1.FormattedValue"],
                        new_tasa: element["new_tasa@OData.Community.Display.V1.FormattedValue"],
                        new_plazodias: element["new_plazodias"],
                        new_periodogracia: element["new_periodogracia"],
                        new_sistemadeamortizacion: element["new_sistemadeamortizacion@OData.Community.Display.V1.FormattedValue"],
                        new_periodicidadpagos: element["new_periodicidadpagos@OData.Community.Display.V1.FormattedValue"],
                        new_observaciones: element["new_observaciones"]
                    }
                    let garantiaExport = {
                        id: element["new_garantiaid"],
                        new_ndeordendelagarantiaotorgada: element["new_ndeordendelagarantiaotorgada"] ? element["new_ndeordendelagarantiaotorgada"] : '',
                        new_tipodeoperacion: element["new_tipodeoperacion@OData.Community.Display.V1.FormattedValue"] ? element["new_tipodeoperacion@OData.Community.Display.V1.FormattedValue"] : '',
                        new_acreedor: element["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"] ? element["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"] : '',
                        statuscode_value: element["statuscode@OData.Community.Display.V1.FormattedValue"] ? element["statuscode@OData.Community.Display.V1.FormattedValue"] : '',
                        new_monto: element["new_monto"] ? element["new_monto"] : '',
                        transactioncurrencyid: element["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"] ? element["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"] : '',
                        new_fechadevencimiento: element["new_fechadevencimiento"] ? moment(new Date(element["new_fechadevencimiento"])).format('DD/MM/yyyy') : '',
                        fechaCreacion: element["createdon"] ? moment(new Date(element["createdon"])).format('DD/MM/yyyy') : '',
                        new_formatodelcheque: element["new_formatodelcheque@OData.Community.Display.V1.FormattedValue"] ? element["new_formatodelcheque@OData.Community.Display.V1.FormattedValue"] : '',
                        new_numerodecheque: element["new_numerodecheque"] ? element["new_numerodecheque"] : '',
                        new_tipochpd: element["new_tipochpd@OData.Community.Display.V1.FormattedValue"] ? element["new_tipochpd@OData.Community.Display.V1.FormattedValue"] : ''
                    }

                    GarantiasFormat.push(garantiaFormat)
                    GarantiasExport.push(garantiaExport)
                })

                GarantiasFormat.sort(function (a, b) {
                    return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
                });

                GarantiasExport.sort(function (a, b) {
                    return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
                });

                setGarantias(GarantiasFormat)
                setDataExport(GarantiasExport)

                let estadiosGarantias = []
                let cantidadesGarantias = []
                let coloresG = []
                // let estadios = [...new Set(garantiasFetchSelector.map(item => item["statuscode@OData.Community.Display.V1.FormattedValue"]))]
                estadosGarantia.forEach(elemento => {
                    var nombre = elemento
                    var cantidad = garantiasFetchSelector.filter(pyme =>
                        pyme["statuscode@OData.Community.Display.V1.FormattedValue"] == elemento).length > 0 ?
                        garantiasFetchSelector.filter(pyme => pyme["statuscode@OData.Community.Display.V1.FormattedValue"] == elemento).length : 0

                    var estado = {
                        opcion: nombre,
                        cantidad: cantidad
                    }
                    estadiosGarantias.push(estado)

                    if (cantidad > 0) {
                        var garantiaCantidad = {
                            opcion: nombre,
                            cantidad: cantidad
                        }
                        cantidadesGarantias.push(garantiaCantidad)

                        var color = ''
                        estadosYcolores.filter(item => item.estado == elemento).map(estadosYC => {
                            color = estadosYC.color
                        })

                        if (color != '') {
                            coloresG.push(color)
                        }
                    }
                })
                setEstadoGarantias(estadiosGarantias.sort(function (a, b) {
                    if (a.estado < b.estado) {
                        return -1;
                    }
                    if (a.estado > b.estado) {
                        return 1;
                    }
                    return 0;
                }))
                setDataGarantia(cantidadesGarantias.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                }))
                setColoresGarantias(coloresG)
                setDataGarantiaCargada(true)
                if (llamadaGarantias == false) {
                    setLlamadaGarantias(true)
                }
            } else if (token != undefined && llamadaGarantias === false) {
                obtenerGarantias(accountid, token.token)
                setLlamadaGarantias(true)
            }
        }
    }, [garantiasFetchSelector, loadingGarantias, accountid, token])

    React.useEffect(() => {
        dispatch(obtenerLimitePorLineaPrestamo(accountid, token.token))
    }, [])

    React.useEffect(() => {
        setLoadingData(loadingGarantias)
    }, [loadingGarantias])

    React.useEffect(() => {
        if (limiteSelector?.length > 0) {
            setMontoDisponible(limiteSelector[0]?.new_montodisponibleporoperacion) //11 tipo prestamo
        }
    }, [limiteSelector])

    React.useEffect(() => {
        if (acredoresSelector.length > 0 && llamadaAcreedores === true) {
            const acreedoresArray = [];
            acredoresSelector.forEach(element => {
                var opcion = {
                    value: element.new_acreedorid,
                    label: element.new_name,
                    tipo: element.new_tipodeacreedor,
                    montoCalificado: element?.new_montocalificado > 0 ? element.new_montocalificado : 0
                }
                acreedoresArray.push(opcion);
            });
            setAcreedores(acreedoresArray)
        } else if (token != undefined && llamadaAcreedores === false) {
            dispatch(obteneAcreedores(token.token))
            setLlamadaAcreedores(true)
        }
    }, [acredoresSelector, token])

    React.useEffect(() => {
        if (destinoDeFondosSelector.length > 0 && llamadaDestinoDeFondos === true) {
            const destinoDeFondosArray = [];
            destinoDeFondosSelector.forEach(element => {
                var opcion = { value: element.new_destinodefondosid, label: element.new_name }
                destinoDeFondosArray.push(opcion);
            });
            setDestinoDeFondos(destinoDeFondosArray)
        } else if (token != undefined && llamadaDestinoDeFondos === false) {
            dispatch(obtenerDestinoDeFondos(token.token))
            setLlamadaDestinoDeFondos(true)
        }
    }, [destinoDeFondosSelector, token])

    React.useEffect(() => {
        if (adjuntosGarantiaSelector.length > 0 && llamadaAdjuntosgarantia === true) {
            let AdjuntosFormat = []
            adjuntosGarantiaSelector.forEach(element => {
                let adjuntoFormat = {
                    id: element["new_adjuntosid"],
                    new_name: element["new_name"],
                    new_tipo: element["new_tipo@OData.Community.Display.V1.FormattedValue"],
                    filename: element["nota.filename"],
                    statuscode: element["statuscode"],
                    new_garantia: element["_new_garantia_value"],
                    annotationid: element['nota.annotationid']
                    // nota: {
                    //     annotationid: element['nota.annotationid'],
                    //     filename: element['nota.filename'],
                    //     mimetype: element['nota.mimetype']
                    // }
                }
                AdjuntosFormat.push(adjuntoFormat)
            })
            setAdjuntosGarantia(AdjuntosFormat)
        } else if (token != undefined && llamadaDestinoDeFondos === false) {
            dispatch(obtenerAdjuntosGarantias(accountid, token.token))
            setLlamadaAdjuntosgarantia(true)
        }
    }, [adjuntosGarantiaSelector, token])

    const obtenerGarantias = (cuent, tok) => {
        dispatch(obtenerGarantiasFetch(cuent, tok))
    }

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    const handleOpenModalTable = (id) => {
        setOpen(true)
        setGarantiaid(id)
    }

    const handleClose = () => setOpen(false);

    //style del modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.default',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleOpenNewModalTable = () => {
        setOpenNew(true)
    }

    const handleCloseNew = () => setOpenNew(false);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
            {/* <DrawerHeader /> */}
            <Fade>
                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}> */}
                <Grid sx={{ px: 2 }}>
                    {/* <Container sx={{ px: 0, mx: 0 }}> */}
                    <TabContext value={value}>
                        {/* <Header title="Garantias" subtitle="Resumen de garantias" /> */}
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            // bgcolor: 'background.paper'
                            px: 0, mx: 0
                        }}>
                            <TabList
                                aria-label="scrollable force tabs example"
                                onChange={handleChangeTabs} variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <Tab label="Garantías" value="0" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="Vigentes" value="3" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="En Cartera" value="1" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="En Gestión" value="2" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="Vencidas" value="4" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="Afrontadas" value="5" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                            </TabList>
                        </Box>
                        <TabPanel value="0" sx={{ p: 0 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <Paper elevation={4} sx={{ mt: 2 }}>
                                        {
                                            loadingData ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        py: 2
                                                    }}>
                                                    <SpinnerF />
                                                    {/* <span className="loaderP"></span> */}
                                                </Box>
                                            )
                                                :
                                                <Tabla sx={{ maxHeight: 50 }}
                                                    lineas={garantias}
                                                    columnas={COLUMNSGT(theme)}
                                                    handleOpen={handleOpenModalTable}
                                                    acciones={true}
                                                    handleOpenNew={handleOpenNewModalTable}
                                                    accionAgregar={true}
                                                    exportData={true}
                                                    dataExp={dataExport}
                                                    headerDataExp={headerDataExp}
                                                    nombreArchivo="Garantias"
                                                />
                                        }

                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7} sx={{ mt: 1 }}>
                                    {/* <Paper elevation={4} sx={{ p: 0 }}> */}
                                    {/*  */}
                                    {
                                        loadingData ? (
                                            <Paper elevation={4} sx={{ p: 0 }}>
                                                <Box sx={{
                                                    minwidth: 450, height: 300,
                                                    boxShadow: 1,
                                                    borderRadius: 2,
                                                    // pt: 2,
                                                    m: 0,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                    <SpinnerF />
                                                </Box>
                                            </Paper>
                                        ) : !loadingData && dataGarantia.length > 0 ? (
                                            // <GraficoBarra data={estadoGarantias} x="estado" y="Cantidad" />
                                            <GraficoBarraApex datos={estadoGarantias} titulo="Estadios de las Garantías" />
                                        ) : !loadingData && garantiasFetchSelector.length == 0 && llamadaGarantias == true ?
                                            <Paper elevation={4} sx={{ p: 0 }}>
                                                <Box sx={{
                                                    minwidth: 450, height: 300,
                                                    boxShadow: 1,
                                                    borderRadius: 2,
                                                    // pt: 2,
                                                    m: 0,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                    <ExtensionOffOutlinedIcon sx={{ fontSize: "40px" }} />
                                                </Box>
                                            </Paper> : null
                                    }
                                    {/*  */}
                                    {/* </Paper> */}
                                </Grid>
                                <Grid xs={12} sm={12} md={5} sx={{ mt: 1 }}>
                                    {/* <Paper elevation={4} sx={{ m: 0, p: 0 }}>
                                        <Box sx={{
                                            minwidth: 450, height: 300,
                                            boxShadow: 1,
                                            borderRadius: 2,
                                            // pt: 2,
                                            m: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}> */}
                                    {
                                        loadingData ? (
                                            <Paper elevation={4} sx={{ p: 0 }}>
                                                <Box sx={{
                                                    minwidth: 450, height: 300,
                                                    boxShadow: 1,
                                                    borderRadius: 2,
                                                    // pt: 2,
                                                    m: 0,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                    <SpinnerF />
                                                </Box>
                                            </Paper>
                                        ) : !loadingData && dataGarantia.length > 0 ? (
                                            // <GraficoPieCantidad data={dataGarantia} COLORS={coloresGarantias} />
                                            <GraficoDonaApex
                                                datos={estadoGarantias}
                                                // opciones={opciones}
                                                titulo="Estadios de las Garantías"
                                            // opcionPrincipal="FONDEP"
                                            />
                                        ) : !loadingData && garantiasFetchSelector.length == 0 && llamadaGarantias == true ?
                                            <Paper elevation={4} sx={{ p: 0 }}>
                                                <Box sx={{
                                                    minwidth: 450, height: 300,
                                                    boxShadow: 1,
                                                    borderRadius: 2,
                                                    // pt: 2,
                                                    m: 0,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                    <ExtensionOffOutlinedIcon sx={{ fontSize: "40px" }} />
                                                </Box>
                                            </Paper> : null
                                    }
                                    {/* </Box>
                                    </Paper> */}
                                </Grid>
                            </Grid>
                        </ TabPanel>
                        <TabPanel value="1">
                            <Grid item xs={12}>
                                <Paper>
                                    {
                                        garantias.filter(item => item.statuscode == 100000004).length > 0 ? (
                                            <Tabla
                                                lineas={garantias}
                                                columnas={garantiasEC}

                                                handleOpen={handleOpenModalTable}
                                                acciones={true}
                                                exportData={true}
                                                dataExp={dataExport.filter(item => item.statuscode_value === "En Cartera")}
                                                headerDataExp={headerDataExp}
                                                nombreArchivo="Garantias en Cartera"
                                            />

                                        ) : <Tabla
                                            lineas={[]}
                                            columnas={garantiasEC}
                                        />
                                    }
                                </Paper>
                            </Grid>
                        </ TabPanel>
                        <TabPanel value="2">
                            <Paper>
                                {
                                    garantias.filter(item => item.statuscode == 1).length > 0 ? (
                                        <Tabla
                                            lineas={garantias.filter(item => item.statuscode == 1)}
                                            columnas={garantiasEC}

                                            handleOpen={handleOpenModalTable}
                                            acciones={true}
                                            exportData={true}
                                            dataExp={dataExport.filter(item => item.statuscode_value === "En Gestión")}
                                            headerDataExp={headerDataExp}
                                            nombreArchivo="Garantias en Gestión"
                                        />

                                    ) : <Tabla
                                        lineas={[]}
                                        columnas={garantiasEC}

                                    />
                                }
                            </Paper>
                        </ TabPanel>
                        <TabPanel value="3">
                            <Paper>
                                {
                                    garantias.filter(item => item.statuscode == 100000001).length > 0 ? (
                                        <Tabla
                                            lineas={garantias.filter(item => item.statuscode == 100000001)}
                                            columnas={garantiasEC}

                                            handleOpen={handleOpenModalTable}
                                            acciones={true}
                                            exportData={true}
                                            dataExp={dataExport.filter(item => item.statuscode_value === "Vigente")}
                                            headerDataExp={headerDataExp}
                                            nombreArchivo="Garantias Vigentes"

                                        />

                                    ) : <Tabla
                                        lineas={[]}
                                        columnas={garantiasEC}

                                    />
                                }
                            </Paper>
                        </ TabPanel>
                        <TabPanel value="4">
                            <Paper>
                                {
                                    garantias.filter(item => item.statuscode == 100000000).length > 0 ? (
                                        <Tabla
                                            lineas={garantias.filter(item => item.statuscode == 100000000)}
                                            columnas={garantiasEC}

                                            handleOpen={handleOpenModalTable}
                                            acciones={true}
                                            exportData={true}
                                            dataExp={dataExport.filter(item => item.statuscode_value === "Vencida")}
                                            headerDataExp={headerDataExp}
                                            nombreArchivo="Garantias vencidas"
                                        />

                                    ) : <Tabla
                                        lineas={[]}
                                        columnas={garantiasEC}

                                    />
                                }
                            </Paper>
                        </ TabPanel>
                        <TabPanel value="5">
                            <Paper>
                                {
                                    garantias.filter(item => item.statuscode == 100000002).length > 0 ? (
                                        <Tabla
                                            lineas={garantias.filter(item => item.statuscode == 100000002)}
                                            columnas={garantiasEC}
                                            titulo={"Garantías afrontadas"}
                                            handleOpen={handleOpenModalTable}
                                            acciones={true}
                                            exportData={true}
                                            dataExp={dataExport.filter(item => item.statuscode_value === "Afrontada")}
                                            headerDataExp={headerDataExp}
                                            nombreArchivo="Garantias afrontadas"
                                        />

                                    ) : <Tabla
                                        lineas={[]}
                                        columnas={garantiasEC}
                                        titulo={"Garantías afrontadas"}
                                    />
                                }
                            </Paper>
                        </ TabPanel>
                    </TabContext>
                    {/* </Container> */}
                </Grid>
            </Fade>
            <ModalGarantias open={open} handleClose={handleClose} adjuntos={adjuntosGarantia.filter(item => item.new_garantia == garantiaid)} garantia={garantias.filter(item => item.id == garantiaid)} garantiaid={garantiaid} />
            <ModalNuevaGarantia open={openNew} handleClose={handleCloseNew} acreedores={acreedores} destinoDeFondos={destinoDeFondos} montoDisponible={montoDisponible} />
        </Box>
    )
}

export default Garantias