import React from 'react'
import Chart from 'react-apexcharts';
import { Select, MenuItem, Box } from '@mui/material';
import DashboardCard from '../DashboardCard';
import { useTheme } from '@mui/material/styles';

let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const GraficoBarraApex = ({ datos, opciones, titulo }) => {
    const [datosGrafico, setDatosGraficos] = React.useState([])
    const [series, setSeries] = React.useState([])
    const [opcion, setOpcion] = React.useState([])
    const theme = useTheme();

    React.useEffect(() => {
        if (opciones?.length > 0) {
            let opcion = opciones[0]
            setOpcion(opcion)
            setDatosGraficos(datos.filter(item => item.filtro === opcion))
            let resultado = [
                {
                    name: 'Cantidad',
                    data: datos.filter(item => item.filtro === opcion).map(item => item.cantidad),
                }
            ]
            setSeries(resultado)
        } else {
            setDatosGraficos(datos)
            let resultado = [
                {
                    name: 'Cantidad',
                    data: datos.map(item => item.cantidad),
                }
            ]
            setSeries(resultado)
        }
    }, [datos])

    const handleChange = (event) => {
        setOpcion(event.target.value);
        setDatosGraficos(datos.filter(item => item.filtro === event.target.value))
        let resultado = [
            {
                name: 'Cantidad',
                data: datos.filter(item => item.filtro === event.target.value).map(item => item.cantidad),
            }
        ]
        setSeries(resultado)
    };


    const optionscolumnchart = {
        chart: {
            locales: [{
                "name": "en",
                "options": {
                    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    "toolbar": {
                        "exportToSVG": "Descargar SVG",
                        "exportToPNG": "Descargar PNG",
                        "exportToCSV": "Descargar CSV",
                        "menu": "Menu",
                        "selection": "Selection",
                        "selectionZoom": "Selection Zoom",
                        "zoomIn": "Zoom In",
                        "zoomOut": "Zoom Out",
                        "pan": "Panning",
                        "reset": "Reset Zoom"
                    }
                }
            }],
            defaultLocale: "en",
            type: 'bar',
            fontFamily: "'Plus Jakarta Sans', sans-serif;",
            foreColor: '#adb0bb',
            toolbar: {
                show: true,
                export: {
                    csv: {
                        filename: titulo,
                        columnDelimiter: ',',
                        headerCategory: 'Serie',
                        headerValue: 'Cantidad',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    png: {
                        filename: titulo,
                        columnDelimiter: ',',
                        headerCategory: 'Serie',
                        headerValue: 'Cantidad',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    svg: {
                        filename: titulo,
                        columnDelimiter: ',',
                        headerCategory: 'Serie',
                        headerValue: 'Cantidad',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                }
            },
        },
        colors: ["rgb(84, 230, 0)", "rgb(255, 45, 0)", "rgb(255, 163, 25)", "rgb(87, 202, 34)", "rgb(0, 155, 255)",
            "rgb(255, 45, 0)", "rgb(243, 156, 18)", "rgb(87, 124, 96)", "rgb(77, 103, 117)", "rgb(239, 226, 152)"],
        plotOptions: {
            bar: {
                distributed: true, // this line is mandatory
                horizontal: false,
                barHeight: '60%',
                columnWidth: '42%',
                borderRadius: [6],
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all',
            },
        },
        stroke: {
            show: true,
            width: 5,
            lineCap: "butt",
            colors: ["transparent"],
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 2,
            position: 'back',
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
            row: {
                colors: undefined,
                opacity: 0.5
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },

        yaxis: {
            labels: {
                formatter: function (value) {
                    return value;
                }
            },
            style: {
                colors: ['#82ca9d'],
                fontWeight: 'bold',
            },
            tickAmount: 4,
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#8884d8',
                    fontWeight: 'bold',
                },
            },
            categories: datosGrafico.map(item => item.opcion),
            colors: ["rgb(84, 230, 0)", "rgb(255, 45, 0)", "rgb(255, 163, 25)", "rgb(93, 173, 226)", "rgb(187, 143, 206)",
                "rgb(46, 204, 113)", "rgb(243, 156, 18)", "rgb(87, 124, 96)", "rgb(77, 103, 117)", "rgb(239, 226, 152)"],
            axisBorder: {
                show: false,
            },
        },
        tooltip: {
            theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
            fillSeriesColor: false,
        },
    };

    return (
        <DashboardCard title={titulo}>
            <Box sx={{
                "& .apexcharts-menu": {
                    background: '#223354!important',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'normal',
                    color: "rgba(255, 255, 255, 0.8)"
                },
                "& .apexcharts-menu:hover": {
                    color: "#223354!important"
                },
            }}>
                <Chart
                    options={optionscolumnchart}
                    series={series}
                    type="bar"
                    height="280px"
                />
            </Box>
        </DashboardCard>
    )
}

export default GraficoBarraApex