import React from 'react';
import { Card, CardContent, Typography, Stack, Box } from '@mui/material';

const DashboardCard = ({
    title,
    subtitle,
    children,
    action,
    footer,
    cardheading,
    headtitle,
    headsubtitle,
    middlecontent,
}) => {
    return (
        <Card
            sx={{
                background: 'paper',
                // m: 1,
                // minwidth: 600,
                // minHeight: 400,
                boxShadow: 1,
                borderRadius: 2,
            }}
            elevation={4}
            variant={undefined}
        >
            {cardheading ? (
                <CardContent>
                    <Typography variant="h6">{headtitle}</Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        {headsubtitle}
                    </Typography>
                </CardContent>
            ) : (
                <CardContent sx={{ p: "10px" }}>
                    {title ? (
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            alignItems={'center'}
                            mb={3}
                        >
                            <Box>
                                {title ? <Typography variant="h6">{title}</Typography> : ''}

                                {subtitle ? (
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {subtitle}
                                    </Typography>
                                ) : (
                                    ''
                                )}
                            </Box>
                            {action}
                        </Stack>
                    ) : null}
                    {children}
                </CardContent>
            )}
            {middlecontent}
            {footer}
        </Card>
    );
};

export default DashboardCard;
