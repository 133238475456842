import React from 'react'
import Moment from 'moment'
import Chip from '@mui/material/Chip';
import DescargarArchivo from '../Components/DescargarArchivo'

let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

//Todas
export const COLUMNSCV = [
    {
        header: 'Nro. de Comprobante',
        accessorKey: 'new_nrocomprobante',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Fecha',
        accessorKey: 'createdon',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Tipo',
        accessorKey: 'new_TipodeComprobante',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Importe Total',
        accessorKey: 'new_total',
        Cell: ({ cell }) => { dollarUS.format(cell.getValue()) }
    },
    {
        header: 'Divisa',
        accessorKey: 'transactioncurrency',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        Header: '',
        accessorKey: 'nota',
        Cell: ({ cell }) => { return cell != null ? <DescargarArchivo nota={cell.getValue()} /> : '-' }
    }
]