import React from 'react'
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LightModeOutlined, DarkModeOutlined, Menu as MenuIcon, Search, ArrowDropDownOutlined } from '@mui/icons-material'
import FlexBetween from './FlexBetween'
import profileImage from '../img/perfilRandom.jpg'
import { useTheme, AppBar, Toolbar, IconButton, InputBase, Button, Box, Typography, MenuItem, Menu, Divider, List, ListItemButton, ListItemText, ListSubheader, Stack, ListItem, Tab, Paper } from '@mui/material'
import { cerrarSesion } from '../Redux/usuarios'
import { obtenerTareas, inactivarTarea } from '../Redux/Cuenta'
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import { useProSidebar } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import logo from "../img/Logonavbar.png"
import logolight from "../img/Logonavbar-light.png"
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import 'moment/locale/es';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import ListItemAvatar from '@mui/material/ListItemAvatar';
moment.locale('es');

const Appbar = ({ setearColor, usuario, foto, loggedUser }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const accountid = useSelector(store => store.usuarios.accountid)
    const token = useSelector(store => store.token.token)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorNoti, setAnchorNoti] = React.useState(null);
    const [notificaciones, setNotificaciones] = React.useState([])
    const [llamadaNotificaciones, setLlamadaNotificaciones] = React.useState(false)
    const tareasSelector = useSelector(store => store.cuenta.tareas)
    const inactivarTareaSelector = useSelector(store => store.cuenta.inactivarTarea)
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const { toggleSidebar, broken, rtl } = useProSidebar();
    const [user, setUser] = React.useState({})
    const [loadingIr, setLoadingIr] = React.useState(false)
    const [loadingMarcar, setLoadingMarcar] = React.useState(false)
    const [checked, setChecked] = React.useState([0])
    const [value, setValue] = React.useState('0')

    const CerrarSesion = (e) => {
        e.preventDefault()
        handleClose()
        dispatch(cerrarSesion())
        navigate('/')
    }

    const handleClickIR = () => {
        setLoadingIr(!loadingIr)
    }

    const handleClickMarcar = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        dispatch(inactivarTarea(value, token.token))
    }

    React.useEffect(() => {
        if (inactivarTareaSelector === "EXITO") {
            setChecked([0])
            dispatch(obtenerTareas(accountid, token.token))
        } else if (inactivarTareaSelector === "ERROR") {
            setChecked([0])
        }
    }, [inactivarTareaSelector])

    React.useEffect(() => {
        if (tareasSelector != undefined && tareasSelector.length > 0) {
            var array = []
            tareasSelector.forEach(item => {
                var object = {
                    id: item.activityid,
                    title: item.subject,
                    description: item.description,
                    statuscode: item["statuscode@odata.community.display.v1.formattedvalue"],
                    statecode: item["statecode"],
                    type: item["new_tipodenotificacion@odata.community.display.v1.formattedvalue"],
                    type_value: item["new_tipodenotificacion"],
                    createdon: item.createdon,
                    createdAt: moment(item.createdon).startOf('minute').fromNow()
                }
                array.push(object)
            })
            array.sort(function (a, b) {
                return new Date(b.createdon) - new Date(a.createdon);
            });
            setNotificaciones(array)
            if (inactivarTareaSelector === "EXITO") {
                setValue('1')
            }
        } else if (token != undefined && token != '' && llamadaNotificaciones === false) {
            dispatch(obtenerTareas(accountid, token.token))
            setLlamadaNotificaciones(true)
        }
    }, [tareasSelector, token])

    React.useEffect(() => {
        setUser(usuario)
    }, [usuario])

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    const handleClickNoti = (event) => {
        setAnchorNoti(event.currentTarget);
    };

    const handleCloseNoti = () => {
        setAnchorNoti(null);
    };

    const open = Boolean(anchorNoti);
    const id = open ? 'simple-popover' : undefined;

    const destinoNotificacion = (tipo) => {
        if (tipo == 100000006) {//"Mi Cuenta"
            navigate('/micuenta')
            setTimeout(() => {
                handleCloseNoti()
            }, 300)
        } else if (tipo == 100000000) { //"Documentación"
            navigate('/midocumentaciondigital')
            setTimeout(() => {
                handleCloseNoti()
            }, 300)
        }
        else if (tipo == 100000001) { //"Lineas"
            navigate('/mislineas')
            setTimeout(() => {
                handleCloseNoti()
            }, 300)
        }
        else if (tipo == 100000002) { //"Garantias"
            navigate('/misgarantias')
            setTimeout(() => {
                handleCloseNoti()
            }, 300)
        }
        else if (tipo == 100000004) { //"Relaciones"
            navigate('/micuenta')
            setTimeout(() => {
                handleCloseNoti()
            }, 300)
        }
        else if (tipo == 100000003) { //"Relaciones"
            navigate('/misoperaciones')
            setTimeout(() => {
                handleCloseNoti()
            }, 300)
        }
    }

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <AppBar
            sx={{
                position: "static",
                background: "none",
                boxShadow: "none",
                bgColor: "#fff"
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <Box sx={{
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'row',
                    display: { xs: 'none', sm: 'block' }
                }}>
                    {
                        theme.palette.mode == 'dark' ?
                            <img src={logo} alt="Logo Responsive" style={{ height: "1.8rem" }} /> :
                            <img src={logolight} alt="Logo Responsive" style={{ height: "1.8rem" }} />
                    }
                </Box>
                <FlexBetween>
                    <Box display="flex">
                        {broken && !rtl && (
                            <IconButton
                                sx={{ margin: "0 6 0 2" }}
                                onClick={() => toggleSidebar()}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        )}
                    </Box>
                    <FlexBetween
                        backgroundColor={theme.palette.background.alt}
                        borderRadius="9px"
                        gap="3rem"
                        p="0.1rem 1.5rem"
                    >
                    </FlexBetween>
                </FlexBetween>
                <FlexBetween gap="0.2rem">
                    <Tooltip title={<Typography sx={{ color: 'inherit' }}>Notificaciones</Typography>}>
                        <IconButton aria-describedby={id} variant="contained" onClick={handleClickNoti}>
                            {theme.palette.mode === 'dark' ?
                                (
                                    <Badge badgeContent={
                                        notificaciones.filter(item => item.statecode === 0).length > 0 ?
                                            notificaciones.filter(item => item.statecode === 0).length : 0} color="primary">
                                        <NotificationsNoneOutlinedIcon
                                            sx={{ fontSize: "25px", color: 'inherit' }} />
                                    </Badge>

                                )
                                :
                                (
                                    <Badge badgeContent={
                                        notificaciones.filter(item => item.statecode === 0).length > 0 ?
                                            notificaciones.filter(item => item.statecode === 0).length : 0} color="primary" >
                                        <NotificationsNoneOutlinedIcon sx={{ fontSize: "25px", color: '#000' }} />
                                    </Badge>
                                )
                            }
                        </IconButton>
                    </Tooltip>
                    <Popover
                        open={Boolean(anchorNoti)}
                        anchorEl={anchorNoti}
                        onClose={handleCloseNoti}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        PaperProps={{
                            sx: {
                                mt: 1.5,
                                ml: 0.75,
                                width: 400,
                                maxHeight: 500,
                                backgroundColor: theme.palette.background.list,
                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                borderRadius: '8px',
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1" sx={{ color: theme.typography.primary.text }}>Notificaciones</Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {notificaciones.filter(item => item.statecode === 0).length == 0 ?
                                        "No tienes notificaciones pendientes" : null}
                                    {notificaciones.filter(item => item.statecode === 0).length > 1 ?
                                        "Tienes " + notificaciones.filter(item => item.statecode === 0).length + " notificaciones pendientes" : null}
                                    {notificaciones.filter(item => item.statecode === 0).length === 1 ?
                                        "Tienes 1 notificación pendiente" : null}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                        <TabContext value={value}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                px: 0, mx: 0
                            }}>
                                <TabList
                                    aria-label="scrollable force tabs example"
                                    onChange={handleChangeTabs} variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                >
                                    <Tab label="Pendientes" value="0" sx={{ fontWeight: 'bold', fontSize: '12px', color: theme.typography.primary.text }}></Tab>
                                    <Tab label="Completadas" value="1" sx={{ fontWeight: 'bold', fontSize: '12px', color: theme.typography.primary.text }}></Tab>
                                </TabList>
                            </Box>
                            <TabPanel value="0" sx={{ p: 0 }}>
                                <Paper elevation={2} sx={{ m: 0, p: 0 }}>
                                    <List
                                        sx={{
                                            width: '100%',
                                            maxWidth: 400,
                                            backgroundColor: theme.palette.background.list,
                                            m: 0, p: 0
                                        }}>
                                        {notificaciones.filter(item => item.statecode === 0).length === 0 ?
                                            <ListItem
                                                alignItems="center"
                                                className={theme.palette.mode === 'dark' ? "itemnotificaciones" : "itemnotificaciones-light"}
                                                sx={{
                                                    backgroundColor: theme.palette.background.list,
                                                }}
                                            >
                                                <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                                    <Typography align="center">
                                                        No tienes notificaciones pendientes
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                            : null}
                                        {
                                            notificaciones.filter(item => item.statecode === 0).map((item) => {
                                                return (
                                                    <ListItem
                                                        alignItems="flex-start"
                                                        className={theme.palette.mode === 'dark' ? "itemnotificaciones" : "itemnotificaciones-light"}
                                                        sx={{
                                                            minHeight: 100,
                                                            backgroundColor: theme.palette.background.list,
                                                        }}
                                                        key={item.id}
                                                    >
                                                        <ListItemAvatar>
                                                            <CircleNotificationsIcon sx={{ fontSize: "35px", color: "#43a047" }} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                >
                                                                    <Typography variant="body2">
                                                                        {item.title}
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                        {item.createdAt}
                                                                    </Typography>
                                                                </Stack>
                                                            }
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography variant="body2" sx={{ my: 1 }}>
                                                                        {item.description}
                                                                    </Typography>
                                                                    {
                                                                        item.statecode === 0 ?
                                                                            <Stack
                                                                                direction="row"
                                                                                justifyContent="flex-start"
                                                                                alignItems="center"
                                                                                spacing={1}
                                                                            >
                                                                                <Button
                                                                                    sx={{ borderRadius: 5 }}
                                                                                    size="small"
                                                                                    onClick={() => destinoNotificacion(item.type_value)}
                                                                                    loadingPosition="start"
                                                                                    startIcon={<SendIcon />}
                                                                                    variant="outlined"
                                                                                >
                                                                                    <span>Ir</span>
                                                                                </Button>
                                                                                <LoadingButton
                                                                                    sx={{ borderRadius: 5 }}
                                                                                    size="small"
                                                                                    onClick={() => handleClickMarcar(item.id)}
                                                                                    loading={checked?.indexOf(item.id) !== -1 ? true : false}
                                                                                    loadingPosition="start"
                                                                                    startIcon={<CheckIcon />}
                                                                                    variant="contained"
                                                                                >
                                                                                    <span>Marcar como leido</span>
                                                                                </LoadingButton>
                                                                            </Stack> : null
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        <Divider sx={{ my: 1, borderBottomWidth: 2 }} />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Paper>
                            </TabPanel>
                            <TabPanel value="1" sx={{ p: 0 }}>
                                <List
                                    sx={{
                                        width: '100%',
                                        maxWidth: 400,
                                        maxHeight: 350,
                                        overflow: 'auto',
                                        backgroundColor: theme.palette.background.list,
                                        m: 0, p: 0
                                    }}>
                                    {notificaciones.filter(item => item.statecode === 1).length === 0 ?
                                        <ListItem
                                            alignItems="flex-start"
                                            className={theme.palette.mode === 'dark' ? "itemnotificaciones" : "itemnotificaciones-light"}
                                            sx={{
                                                backgroundColor: theme.palette.background.list,
                                            }}
                                        >
                                            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                                <Typography align="center">
                                                    No tienes notificaciones completadas
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                        : null}
                                    {
                                        notificaciones.filter(item => item.statecode === 1).map((item) => {
                                            return (
                                                <ListItem
                                                    alignItems="flex-start"
                                                    className={theme.palette.mode === 'dark' ? "itemnotificaciones" : "itemnotificaciones-light"}
                                                    sx={{
                                                        minHeight: 100,
                                                        backgroundColor: theme.palette.background.list,
                                                    }}
                                                    key={item.id}
                                                >
                                                    <ListItemAvatar>
                                                        <CircleNotificationsIcon sx={{ fontSize: "35px", color: "#43a047" }} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <Typography variant="body2">
                                                                    {item.title}
                                                                </Typography>
                                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                    {item.createdAt}
                                                                </Typography>
                                                            </Stack>
                                                        }
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography variant="body2" sx={{ my: 1 }}>
                                                                    {item.description}
                                                                </Typography>
                                                                {
                                                                    item.statecode === 0 ?
                                                                        <Stack
                                                                            direction="row"
                                                                            justifyContent="flex-start"
                                                                            alignItems="center"
                                                                            spacing={1}
                                                                        >
                                                                            <Button
                                                                                sx={{ borderRadius: 5 }}
                                                                                size="small"
                                                                                onClick={() => destinoNotificacion(item.type_value)}
                                                                                loadingPosition="start"
                                                                                startIcon={<SendIcon />}
                                                                                variant="outlined"
                                                                            >
                                                                                <span>Ir</span>
                                                                            </Button>
                                                                            <LoadingButton
                                                                                sx={{ borderRadius: 5 }}
                                                                                size="small"
                                                                                onClick={() => handleClickMarcar(item.id)}
                                                                                loading={checked?.indexOf(item.id) !== -1 ? true : false}
                                                                                loadingPosition="start"
                                                                                startIcon={<CheckIcon />}
                                                                                variant="contained"
                                                                            >
                                                                                <span>Marcar como leido</span>
                                                                            </LoadingButton>
                                                                        </Stack> : null
                                                                }
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </TabPanel>
                        </TabContext>
                    </Popover>
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Tema</Typography>}>
                        <IconButton onClick={setearColor}>
                            {theme.palette.mode === 'dark' ?
                                (
                                    <DarkModeOutlined sx={{ fontSize: "25px", color: '#fff' }} />
                                )
                                :
                                (
                                    <LightModeOutlined sx={{ fontSize: "25px", color: '#000' }} />
                                )
                            }
                        </IconButton>
                    </Tooltip>
                    <FlexBetween>
                        <Button
                            onClick={handleClick}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                textTransform: "none",
                                gap: "1rem",
                            }}
                        >
                            <IconButton color="inherit">
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    variant="dot"
                                >
                                    {foto !== undefined ? (
                                        <Avatar alt="Remy Sharp" src={foto} />
                                    ) : (
                                        <Avatar alt="Remy Sharp" src={profileImage} />
                                    )}
                                </StyledBadge>
                            </IconButton>
                            <Box
                                textAlign="left"
                            >
                                <Typography
                                    fontWeight="bold"
                                    fontSize="0.80rem"
                                    sx={{
                                        color:
                                            theme.palette.mode === "dark"
                                                ? "#f5f5f5"
                                                : "#3a3541de",
                                        fontWeight: 600,
                                    }}
                                >
                                    {user.name}
                                </Typography>
                                <Typography
                                    fontSize="0.75rem"
                                    sx={{
                                        color:
                                            theme.palette.mode === "dark"
                                                ? "#f5f5f5"
                                                : "#3a3541de",
                                        fontWeight: 500,
                                    }}
                                >
                                    {user.emailaddress1}
                                </Typography>
                            </Box>
                            <ArrowDropDownOutlined
                                sx={{ color: "#fafafa", fontSize: "25px" }}
                            />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={isOpen}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        >
                            <MenuItem onClick={e => CerrarSesion(e)}>Cerrar sesión</MenuItem>
                        </Menu>
                    </FlexBetween>
                </FlexBetween>
            </Toolbar >
        </AppBar >
    )
}

export default Appbar