import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from 'recharts';
import { darken, useTheme, createTheme, ThemeProvider } from '@mui/material';
// const COLORS = ['#4caf50', '#f44336', '#ff6d00', '#1b5e20', '#ffab00', '#d50000', '#4caf50'];
import { Box, Typography, Divider, Button, Paper } from '@mui/material';
let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});


// const GraficoPie = ({data, x, y}) => {
const Grafico = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, theme } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text
                x={cx} y={cy}
                dy={8}
                textAnchor="middle"
                fill={theme.palette.mode === 'dark' ? '#fff' : '#000'}
            >
                {/* fill={theme.palette.primary.main}> */}
                {payload.name}
                {/* <Typography sx={{ fontWeight: 'bold' }}>
                   
                </Typography> */}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                sx={{ zIndex: 2 }}
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey} textAnchor={textAnchor}
                fill={theme.palette.mode === 'dark' ? '#fff' : '#000'}
            // fill={fill}
            >
                {`${dollarUS.format(value)}`}
            </text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey} dy={18} textAnchor={textAnchor}
                fill={theme.palette.mode === 'dark' ? '#fff' : '#000'}
            // fill={fill}
            >
                {`(% ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const GragicoPie = ({ data, COLORS }) => {
    const [stat, setStat] = React.useState(0)
    const globalTheme = useTheme();
    const onPieEnter = (_, index) => {
        debugger
        setStat(index)
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart className='pruebas'>
                <Pie
                    sx={{ height: '180px' }}
                    activeIndex={stat}
                    activeShape={Grafico}
                    data={data}
                    cx="50%"
                    cy="50%"
                    // height= '180px'
                    innerRadius={60}    
                    outerRadius={80}
                    fill="#43a047"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                >
                    {data.map((entry, index) => (
                        <Cell key={data[index]} fill={COLORS[index]} theme={globalTheme} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}

export default GragicoPie 