import React from 'react'
import { styled } from '@mui/material/styles';
import { Box, Typography, Divider, Button, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Fade from 'react-reveal/Fade';
import Tabla from './Tabla';
import { obtenerTodosLimitesPorLineas, obtenerTodosLimitesPorLineasGeneral } from '../Redux/LimitePorLinea'
import { COLUMNS, COLUMNS2 } from '../columnas/ColumnasLimites';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { display } from '@mui/system';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Container from '@mui/material/Container';
import SpinnerF from './SpinnerF';
import { useTheme } from '@mui/material';

const Lineas = () => {
    const dispatch = useDispatch()
    const theme = useTheme();
    const [limiteGral, setLimiteGral] = React.useState([])
    const [llamadaLimites, setLlamadaLimites] = React.useState(false)
    const [limites, setLimites] = React.useState([])
    const [columnasLineas, setColumnasLineas] = React.useState([])
    const [columnasLineasPorOperacion, setColumnasLineasPorOperacion] = React.useState([])
    const [value, setValue] = React.useState("0")
    const loadingLinea = useSelector(store => store.limites.loadingLinea)
    const [loadingData, setLoadingData] = React.useState(false)

    const accountid = useSelector(store => store.usuarios.accountid)
    const limiteSelector = useSelector(store => store.limites.limites)
    const token = useSelector(store => store.token.token)

    React.useEffect(() => {
        try {
            debugger
            if (accountid !== '') {
                if (llamadaLimites === true && limiteSelector != undefined && limiteSelector.length > 0) {
                    setColumnasLineasPorOperacion(COLUMNS)
                    setColumnasLineas(COLUMNS2)
                    let arrayLimites = []
                    limiteSelector.forEach(item => {
                        var limite = {
                            new_lineatipodeoperacion: item["new_lineatipodeoperacion@OData.Community.Display.V1.FormattedValue"],
                            new_lineatipodeoperacion_value: item["new_lineatipodeoperacion"],
                            new_montodisponibleporoperacion: item.new_montodisponibleporoperacion > 0 ? item.new_montodisponibleporoperacion : 0,
                            new_montoutilizadogeneral: item.new_montoutilizadogeneral > 0 ? item.new_montoutilizadogeneral : 0,
                            new_montodisponiblegeneral: item.new_montodisponiblegeneral > 0 ? item.new_montodisponiblegeneral : 0,
                            new_montoutilizadoporoperacion: item.new_montoutilizadoporoperacion > 0 ? item.new_montoutilizadoporoperacion : 0,
                            new_mostrarenportalsocio: item.new_mostrarenportalsocio,
                            new_productosid: item.new_productosid,
                            new_tipochpd: item["new_tipochpd@OData.Community.Display.V1.FormattedValue"],
                            new_tipochpd_value: item["new_tipochpd"],
                            new_topeporlineacomercial: item.new_topeporlineacomercial > 0 ? item.new_topeporlineacomercial : 0,
                            new_topeporlineacomercialusd: item.new_topeporlineacomercialusd > 0 ? item.new_topeporlineacomercialusd : 0,
                            statuscode: item["statuscode@OData.Community.Display.V1.FormattedValue"],
                            _transactioncurrencyid_value: item["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"]
                        }
                        arrayLimites.push(limite)
                    })
                    setLimites(arrayLimites.filter(lin => lin.new_lineatipodeoperacion_value != 100000000))
                    setLimiteGral(arrayLimites.filter(lin => lin.new_lineatipodeoperacion_value == 100000000))
                } else if (token != undefined && token != '' && llamadaLimites === false) {
                    dispatch(obtenerTodosLimitesPorLineas(accountid, token.token))
                    setLlamadaLimites(true)
                }
            }
        } catch (error) {
            // console.log(error)
        }
    }, [limiteSelector, accountid])

    React.useEffect(() => {
        setLoadingData(loadingLinea)
    }, [loadingLinea])

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, pt: 4, px: 1 }}>
            <Fade>
                {/* <Container component="main" sx={{ mb: 1 }}> */}
                <Grid sx={{ px: 2 }}>
                    <TabContext value={value}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            // bgcolor: 'background.paper'
                        }}>
                            <TabList
                                aria-label="scrollable force tabs example"
                                onChange={handleChangeTabs} variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <Tab label="Limites por línea general" value="0" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="Limites por operación activos" value="1" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                            </TabList>
                        </Box>
                        <TabPanel value="0" sx={{ p: 0 }}>
                            <Grid container spacing={2} sx={{ pt: 2 }}>
                                <Grid item xs={12}>
                                    <Paper elevation={4} >
                                        {
                                            loadingData ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        py: 2
                                                    }}>
                                                    <SpinnerF />
                                                </Box>
                                            )

                                                :
                                                <Tabla lineas={limiteGral} columnas={COLUMNS2} />
                                            // !loadingData && limiteGral.length > 0 ?
                                            //     (<Tabla lineas={limiteGral} columnas={COLUMNS2} titulo={"Limites por línea general"} />)
                                            //     : !loadingData && limiteGral.length == 0 && llamadaLimites == true ?
                                            //         (
                                            //             <Tabla lineas={[]} columnas={COLUMNS} titulo={"Limites por línea general"} />
                                            //         ) : null
                                        }

                                    </Paper>
                                </Grid>
                            </Grid>
                        </ TabPanel>
                        <TabPanel value="1" sx={{ p: 0 }}>
                            <Grid container spacing={2} sx={{ pt: 2 }}>
                                <Grid item xs={12}>
                                    <Paper elevation={4}  >
                                        {
                                            loadingData ? (

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        py: 2
                                                    }}>
                                                    <SpinnerF />
                                                </Box>
                                            )
                                                :
                                                <Tabla lineas={limites} columnas={COLUMNS} />
                                            // !loadingData && limites.length > 0 ? (
                                            //     <Tabla lineas={limites} columnas={columnasLineasPorOperacion} titulo={"Limites por operación activos"} />)
                                            //     : !loadingData && limites.length == 0 && llamadaLimites == true ?
                                            //         (
                                            //             <Tabla lineas={[]} columnas={columnasLineasPorOperacion} titulo={"Limites por operación activos"} />
                                            //         ) : null
                                        }
                                    </Paper>

                                </Grid>
                            </Grid>
                        </ TabPanel>
                    </TabContext>
                </Grid>
            </Fade>
        </Box>
    )
}

export default Lineas