import Chip from '@mui/material/Chip';
import Utilidades from '../Components/Utilidades';

export const COLUMNASDOCUMENTOSOPERACION = tema => [
    {
        accessorKey: 'id',
    },
    {
        header: 'Documento',
        minSize: 140, //min size enforced during resizing
        maxSize: 200, //max size enforced during resizing
        // accessorKey: '_new_documento_value',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Estado',
        minSize: 100, //min size enforced during resizing
        maxSize: 140, //max size enforced during resizing
        accessorKey: 'statuscode',
        Cell: ({ cell }) => {
            switch (cell.getValue()) {
                case 100000000:
                    return <Chip label="Recibida" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                    }} />
                case 1:
                    return <Chip label="Pendiente" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                    }} />
                case 100000001:
                    return <Chip label="Aprobado" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(0, 155, 255, 0.2)' : 'rgb(0, 155, 255, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(0, 155, 255)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                    }} />
            }
        }
    },
    {
        header: 'Fecha de Vencimiento',
        accessorKey: 'new_fechadevencimiento',
        minSize: 100, //min size enforced during resizing
        maxSize: 140, //max size enforced during resizing
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: '',
        accessorKey: 'utilidades',
        minSize: 100, //min size enforced during resizing
        maxSize: 140, //max size enforced during resizing
        Cell: ({ cell }) => { return cell != null ? <Utilidades utilidad={cell.getValue()} /> : '-' }
    }
]