import { onboardingHabilitado } from '../Keys'
import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import fondo from '../img/fondo2.jpg'
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { useTheme } from '@mui/material';
///////////////////////////////////////////
import { useDispatch, useSelector } from 'react-redux'
import {
    loginUsuario,
} from '../Redux/usuarios.js'
import { Link, useNavigate } from 'react-router-dom'

//////////////////////////////////////////
import * as yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import CustomTextField from '../Components/CustomTextField';
//################# NOTA IMPORTANTE ##################

import { toast } from 'react-toastify';

const Login = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme();
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const errorSelector = useSelector(store => store.usuarios.error)
    const activo = useSelector(store => store.usuarios.activo)
    const tipo = useSelector(store => store.usuarios.tipo)
    const loading = useSelector(store => store.usuarios.loadingSesion)
    //ademas de validadores, el default values puede ser usado como hook

    const defaultValues = {
        email: '',
        password: ''
    };

    const validationSchema = yup.object().shape({
        password: yup.string().required("Required"),
        email: yup
            .string()
            .email() //el.email es para que valide que realmente este escribiendo un email
            .required("Required")
    });

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    const {
        handleSubmit,
    } = methods;

    React.useEffect(() => {
        if (isFormSubmitted) {
            if (activo) {
                navigate('/')
            }
        } else if (activo) {
            navigate('/')
        }
    }, [isFormSubmitted, activo])

    // }, [isFormSubmitted, activo, errorSelector, tipo, props.history, loading])


    //loader en boton
    const buttonSx = {
        color: '#24292e',
        p: 1.2,
        // fontWeight: 'bold',
        ...(loading && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const ProcesarLogin = (datos) => {
        setIsFormSubmitted(true);
        //es necesario hacer destructuring de lo que venga de datos, ya que yup almacena todo como un hook y eso es lo que debemos pasar como parametro
        const { email, password } = datos

        if (!loading) {
            dispatch(loginUsuario(email, password))
        }
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${fondo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon sx={{ color: '#24292e' }} />
                    </Avatar>
                    <Typography variant="h4">
                        Bienvenido
                    </Typography>
                    <FormProvider {...methods}>
                        <form>
                            <Box sx={{ mt: 5 }}>
                                <CustomTextField
                                    Component={TextField}
                                    type="text"
                                    name="email"
                                    label="Correo Electrónico"
                                    helperText="Por favor escribe tu Correo Electrónico"
                                    rules={{ required: "Required!" }}
                                // onChange={e => setMail(e.target.value)}
                                />
                                <CustomTextField
                                    sx={{ mt: 3, '&-webkit-box-shadow': '#fff' }}
                                    Component={TextField}
                                    type="password"
                                    name="password"
                                    label="Contraseña"
                                    helperText="Por favor escribe tu Contraseña"
                                    rules={{ required: "Required!" }}
                                // onChange={e => setPass(e.target.value)}
                                />
                                <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={buttonSx}
                                        disabled={loading}
                                        onClick={handleSubmit(ProcesarLogin)}
                                    >
                                        Ingresar
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                                <Grid container>
                                    <Grid item xs>
                                        <Link to="/recupero" style={{ textDecoration: 'none', color: theme.palette.mode == 'dark' ? '#fefefe' : '#000' }}>
                                            Olvidaste la contraseña?
                                        </Link>
                                    </Grid>
                                    <Grid item xs>
                                        {
                                            onboardingHabilitado ?
                                                <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                                    <Link to='/solicitud-alta' style={{ textDecoration: 'none', color: theme.palette.mode == 'dark' ? '#fefefe' : '#000' }}>
                                                        <Typography align="right">
                                                            Todavía no eres socio? Registrese Aquí
                                                        </Typography>
                                                    </Link>
                                                </Box>
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </FormProvider>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Login