import React from 'react'
import { Box, Typography, Divider, Button, Paper } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Container from '@mui/material/Container';
import CustomSearchSelect from '../CustomSearchSelect';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CustomSelect from '../CustomSelect';
import { useSelector, useDispatch } from 'react-redux';
import { cargarGarantia, obteneAcreedores } from '../../Redux/Operaciones';
import CustomTextField from '../CustomTextField';
import CustomCurrencyField from '../CustomCurrencyField';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'
import { obtenerGarantiasFetch } from '../../Redux/Garantias';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';
moment.locale('es');

const validacionSchema = yup.object().shape({
    tipoDeOperacion: yup.string().required('El apellido es requerido'),
    formatoDelCheque: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion == '4' || tipoDeOperacion == '13' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    tasa: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion == '11' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    sistemaAmortizacion: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion == '11' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    periocidadDePago: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion == '11' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    plazoDias: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion == '11' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    plazoGracia: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion == '11' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    fechaVencimiento: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion !== '11' ? yup.date().min(new Date(), 'La fecha de vencimiento no puede ser manor a hoy').required('Required field')
            .nullable()
            .default(undefined)
            .typeError("La fecha es requerida") : yup.string().notRequired()
    }),
    tipoCHPD: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion !== '11' ? yup.string().required('El tipo CHPD es requerido') : yup.string().notRequired()
    }),
    razonSocialLibrador: yup.string().when('tipoCHPD', (tipoCHPD) => {
        return tipoCHPD == '100000001' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    cuitLibrador: yup.string().when('tipoCHPD', (tipoCHPD) => {
        return tipoCHPD == '100000001' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    montoBruto: yup.number().min(1, "El monto de la garantia debe ser mayor a 0").required('El monto es requerido'),
    acreedor: yup.object({
        value: yup.string().required()
    }),
});

const ModalNuevaGarantia = ({ open, handleClose, acreedores, destinoDeFondos, montoDisponible }) => {
    const dispatch = useDispatch()
    const [habilitarNGarantia, setHabilitarNGarantia] = React.useState(false)
    const [acreedoresFiltrado, setAcreedoresFiltrados] = React.useState([])
    const [acreedoresCargados, setAcreedoresCargados] = React.useState(false)
    const [readOnly, setReadOnly] = React.useState(false)
    const cargaGarantiaSelector = useSelector(store => store.operaciones.cargaGarantia)
    const token = useSelector(store => store.token.token)
    const accountid = useSelector(store => store.usuarios.accountid)

    React.useEffect(() => {
        if (cargaGarantiaSelector == "EXITO" || cargaGarantiaSelector == "ERROR") {
            if (cargaGarantiaSelector != "ERROR") {
                dispatch(obtenerGarantiasFetch(accountid, token.token))
            }
            if (habilitarNGarantia == true) {
                setHabilitarNGarantia(!habilitarNGarantia)
                setTimeout(() => {
                    reset()
                    handleClose()
                }, 1500)
            }
        }
    }, [cargaGarantiaSelector])

    const cerrarModal = () => {
        reset()
        handleClose()
    }

    const tipoCHPDOpciones = [
        { value: '100000000', label: 'Propio' },
        { value: '100000001', label: 'Tercero' },
    ]

    const tipoDeOperacionOpciones = [
        { value: '4', label: 'Cheque de Pago Diferido' },
        { value: '17', label: 'Cheque de Pago Diferido Acreedor Bancario' },
        { value: '13', label: 'Valores de Corto Plazo' },
        { value: '10', label: 'Pagare Bursatil' },
        { value: '11', label: 'Préstamo' }
    ]

    const tipoTasa = [ //new_tasa
        { value: '100000000', label: 'FIJA' },
        { value: '100000001', label: 'LIBOR' },
        { value: '100000002', label: 'BADLAR' },
        { value: '100000003', label: 'BADLARPU' },
        { value: '100000004', label: 'BADLARPR' },
        { value: '100000005', label: 'TEC' },
        { value: '100000006', label: 'TEBP' },
        { value: '100000007', label: 'TECBP' },
        // { value: '100000008', label: 'COMBINADA' }
    ]

    const tipoSistemaAmortizacion = [ //new_sistemadeamortizacion
        { value: '100000000', label: 'FRANCES' },
        { value: '100000001', label: 'AMERICANO' },
        { value: '100000002', label: 'ALEMAN' },
        { value: '100000003', label: 'OTRO' },
        { value: '100000004', label: 'PAGO UNICO' }
    ]

    const tipoPeriocidadDePago = [ //new_periodicidadpagos
        { value: '100000001', label: 'MENSUAL' },
        { value: '100000002', label: 'BIMESTRAL' },
        { value: '100000003', label: 'TRIMESTRAL' },
        { value: '100000004', label: 'CUATRIMESTRAL' },
        { value: '100000005', label: 'SEMESTRAL' },
        { value: '100000006', label: 'ANUAL' },
        { value: '100000007', label: 'OTRO' },
        { value: '100000008', label: 'PAGO UNICO' }
    ]

    const formatoDelChequeOpciones = [
        { value: '100000000', label: 'Físico' },
        { value: '100000001', label: 'Echeck' },
        { value: '100000002', label: 'E-Pagaré' },
    ]

    const defaultValues = {
        acreedor: null,
        // destionDeFondos: null,
        tipoCHPD: '',
        razonSocialLibrador: '',
        cuitLibrador: '',
        montoBruto: 0,
        tipoDeOperacion: '',
        formatoDelCheque: '',
        fechaVencimiento: '',
        numeroCheque: '',
        tasa: '',
        sistemaAmortizacion: '',
        periocidadDePago: '',
        plazoDias: '',
        plazoGracia: '',
        observaciones: ''
    }

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validacionSchema),
        mode: "onChange"
    })

    const { handleSubmit, trigger, reset, setValue } = methods;

    const tipoDeOperacion = methods.watch('tipoDeOperacion')

    React.useEffect(() => {
        if (acreedores?.length > 0) {
            if (tipoDeOperacion === '11') {
                let acreedoresAux = acreedores.filter(item => item.tipo === 100000000 || item.tipo === 100000001)
                setAcreedoresFiltrados(acreedoresAux?.filter(item => item.montoCalificado > 0))
                setAcreedoresCargados(true)
            } else {
                setAcreedoresFiltrados(acreedores)
                setAcreedoresCargados(true)
            }
        }
    }, [acreedores, tipoDeOperacion])

    React.useEffect(() => {
        setValue('acreedor', null)
        setValue('tipoCHPD', '')
        setValue('razonSocialLibrador', '')
        setValue('cuitLibrador', '')
        setValue('montoBruto', 0)
        setValue('formatoDelCheque', '')
        setValue('fechaVencimiento', null)
        setValue('numeroCheque', '')
        setValue('tasa', '')
        setValue('sistemaAmortizacion', '')
        setValue('periocidadDePago', '')
        setValue('plazoDias', '')
        setValue('plazoGracia', '')
        setValue('observaciones', '')
        setReadOnly(false)
    }, [tipoDeOperacion])

    const submitDatos = (datos) => {
        if (tipoDeOperacion !== '11') {
            if (tipoCHPD == "100000000") {
                setValue('razonSocialLibrador', '')
                setValue('cuitLibrador', '')
            } else {
                var cuitValido = true
                cuitValido = ValidateCUITCUIL(cuitLibrador)
                if (!cuitValido) {
                    toast.error('El cuit del librador debe ser válido!', {
                        theme: "dark",
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return
                }
            }
        }
        if (tipoDeOperacion == '11') {
            if (datos.montoBruto > montoDisponible) {
                toast.info('El monto solicitado excede al disponible calificado. El otorgamiento del aval quedará sujeto a Análisis de riesgo!', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        if (tipoDeOperacion == '13' || tipoDeOperacion == '17') {
            setValue('formatoDelCheque', '');
        }
        dispatch(cargarGarantia(datos, accountid, token.token))
        setHabilitarNGarantia(!habilitarNGarantia)
    }

    const formData = methods.watch();

    const tipoCHPD = methods.watch('tipoCHPD')
    const cuitLibrador = methods.watch('cuitLibrador')
    const plazoDias = methods.watch('plazoDias')

    React.useEffect(() => {
        debugger
        if (tipoDeOperacion === '11' && plazoDias > 0) {
            var fechaSuma = moment().add(plazoDias, 'months').calendar(); 
            let fechaY = fechaSuma.split("/").reverse().join("-");
            let fechaV = new Date(fechaY)
            setValue('fechaVencimiento', fechaV)
            setReadOnly(true)
        } else if (tipoDeOperacion === '11' && plazoDias === '') {
            setValue('fechaVencimiento', null)
            setReadOnly(false)
        }
    }, [plazoDias])

    function addMonths(date, months) {
        date.setMonth(date.getMonth() + months);

        return date;
    }

    //style del modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '100%',
        width: 'auto',
        bgcolor: 'background.default',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
        m: 1
    };

    const ValidateCUITCUIL = (cuit) => {
        let acumulado = 0;
        let respuesta = false;
        let digitos = cuit.split('');
        let digito = parseInt(digitos.pop());

        for (let i = 0; i < digitos.length; i++) {
            acumulado += digitos[9 - i] * (2 + (i % 6));
        }

        let verif = 11 - (acumulado % 11);
        if (verif === 11) {
            verif = 0;
        } else if (verif === 10) {
            verif = 9;
        }
        respuesta = (digito === verif);
        return respuesta;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown
            BackdropClick={false}
            fullScreen
        >
            <DialogContent sx={{ m: 0, p: 0 }}>
                <Paper elevation={3} sx={{ m: 0, p: 0, minHeight: '100vh' }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                        <Typography id="alert-dialog-title" variant='h5' sx={{ ml: 3, pt: 1 }}>
                            Nueva Garantía
                        </Typography>
                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={cerrarModal}
                                aria-label="close"
                                sx={{ mr: 2 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Divider />
                    <Container component="main">
                        <FormProvider {...methods}>
                            <form id="Garantia">
                                <Grid container>
                                    <Grid xs={12} sx={{ p: 1 }}>
                                        <Typography variant="h6">
                                            Datos Generales
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                        <CustomSelect
                                            name="tipoDeOperacion"
                                            label="Tipo de Operación"
                                            helperText="Por favor seleccione un valor"
                                            items={tipoDeOperacionOpciones}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                        <CustomSearchSelect
                                            name="acreedor"
                                            lab="Acreedor"
                                            helperText="Por favor seleccione un valor"
                                            options={acreedoresFiltrado ? acreedoresFiltrado : null}
                                            noOptions={acreedoresCargados && acreedoresFiltrado.length === 0 ? true : false}
                                            req="true"
                                        />
                                    </Grid>
                                    {
                                        tipoDeOperacion !== '' && tipoDeOperacion !== '11' ?
                                            <Grid xs={12} sx={{ p: 1 }}>
                                                <Typography variant="h6">
                                                    Datos del Cheque
                                                </Typography>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        tipoDeOperacion === '11' ?
                                            <Grid xs={12} sx={{ p: 1 }}>
                                                <Typography variant="h6">
                                                    Datos del Aval
                                                </Typography>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        tipoDeOperacion === '11' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomSelect
                                                    name="tasa"
                                                    label="Tasa"
                                                    helperText="Por favor seleccione un valor"
                                                    items={tipoTasa}
                                                />
                                            </Grid> : null
                                    }
                                    {
                                        tipoDeOperacion === '11' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="Plazo en Meses"
                                                    name="plazoDias"
                                                    helperText="El campo es requerido"
                                                    type="number"
                                                    required
                                                />
                                            </Grid> : null
                                    }
                                    {
                                        tipoDeOperacion === '11' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="Plazo de Gracia en Meses"
                                                    name="plazoGracia"
                                                    helperText="El campo es requerido"
                                                    type="number"
                                                    required
                                                />
                                            </Grid> : null
                                    }
                                    {
                                        tipoDeOperacion === '11' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomSelect
                                                    name="sistemaAmortizacion"
                                                    label="Sistema de Amortización"
                                                    helperText="Por favor seleccione un valor"
                                                    items={tipoSistemaAmortizacion}
                                                />
                                            </Grid> : null
                                    }
                                    {
                                        tipoDeOperacion === '11' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomSelect
                                                    name="periocidadDePago"
                                                    label="Periocidad de Pago"
                                                    helperText="Por favor seleccione un valor"
                                                    items={tipoPeriocidadDePago}
                                                />
                                            </Grid> : null
                                    }
                                    {
                                        tipoDeOperacion == '4' || tipoDeOperacion == '13' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomSelect
                                                    name="formatoDelCheque"
                                                    label="Formato del Cheque"
                                                    helperText="Por favor seleccione un valor"
                                                    items={formatoDelChequeOpciones}
                                                />
                                            </Grid> : null
                                    }
                                    {
                                        tipoDeOperacion !== '' && tipoDeOperacion !== '11' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="Nro. Cheque"
                                                    name="numeroCheque"
                                                />
                                            </Grid> : null
                                    }
                                    {
                                        tipoDeOperacion !== '' && tipoDeOperacion !== '11' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomSelect
                                                    name="tipoCHPD"
                                                    label="Tipo CHPD"
                                                    helperText="Por favor seleccione un valor"
                                                    items={tipoCHPDOpciones}
                                                    rules={{ required: "Required!" }}
                                                />
                                            </Grid> : null
                                    }
                                    <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                        <CustomCurrencyField
                                            name="montoBruto"
                                            label="Monto Bruto"
                                            rules={{ required: "Required!" }}
                                            req="true"
                                        />
                                    </Grid>
                                    {/* {
                                        tipoDeOperacion !== '11' ? */}
                                    <Grid xs={12} sm={6} sx={{ p: 1, mt: 1 }}>
                                        <Controller
                                            fullwidth="true"
                                            name="fechaVencimiento"
                                            render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                    <DesktopDatePicker
                                                        {...field}
                                                        views={['day', 'month', 'year']}
                                                        inputRef={ref}
                                                        label="Fecha de Vencimiento:"
                                                        readOnly={readOnly}
                                                        renderInput={(inputProps) => (
                                                            <TextField
                                                                sx={{ mt: 1 }}
                                                                {...inputProps}
                                                                fullWidth
                                                                onBlur={onBlur}
                                                                name={name}
                                                                error={!!fieldState.error}
                                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                                rules={{ required: "Required!" }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />
                                    </Grid>
                                    {/*: null
                                    } */}
                                    {
                                        tipoDeOperacion !== '' && tipoCHPD == '100000001' && tipoDeOperacion !== '11' ?
                                            <Grid xs={12} sx={{ p: 1 }}>
                                                <Typography variant="h6">
                                                    Datos del Librador
                                                </Typography>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        tipoDeOperacion !== '' && tipoCHPD == '100000001' && tipoDeOperacion !== '11' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="Razon Social Librador"
                                                    name="razonSocialLibrador"
                                                    helperText="La razon social del librador es requerida"
                                                    rules={{ required: "Required!" }}
                                                    req="true"
                                                />
                                            </Grid> : null
                                    }
                                    {
                                        tipoDeOperacion !== '' && tipoCHPD == '100000001' && tipoDeOperacion !== '11' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="Cuit Librador"
                                                    name="cuitLibrador"
                                                    helperText="Por favor escribe tu CUIT/CUIL sin espacios ni guiones"
                                                    rules={{ required: "Required!" }}
                                                    req="true"
                                                />
                                            </Grid> : null
                                    }
                                    <Grid xs={12} sx={{ p: 1 }}>
                                        <Typography variant="h6">
                                            Observaciones
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} sx={{ width: '100%' }}>
                                        <CustomTextField
                                            Component={TextField}
                                            multiline
                                            label="Observaciones"
                                            name="observaciones"
                                            helperText="Escribe alguna observación"
                                        />
                                    </Grid>
                                    <Grid xs={12} sx={{ pr: 1 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit(submitDatos)}
                                                disabled={habilitarNGarantia}
                                                sx={{ my: 3, ml: 1 }}
                                            >Guardar</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </Container>
                </Paper>
            </DialogContent>
        </Dialog >
    )
}

export default ModalNuevaGarantia