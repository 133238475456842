import axios from 'axios'
import { Entidad, UrlApiDynamics, UrlApi } from '../Keys'
import { toast } from 'react-toastify';

const dataInicial = {
    documentos: [],
    cargaDocumento: '',
    documentoid: '',
    loadingDocumentos: false,
    retrieveDocsExito: false
}

const CARGA_DOCUMENTOXCUENTA = 'CARGA_DOCUMENTOXCUENTA'
const TODOS_DOCUMENTOXCUENTA_EXITO = 'TODOS_DOCUMENTOXCUENTA_EXITO'
const LOADING_DOCUMENTOS = "LOADING_DOCUMENTOS"
const LOADING_TODOS_DOCUMENTOS = "LOADING_TODOS_DOCUMENTOS"
const ERROR = 'ERROR'
const ERROR_DOCUMENTOS = 'ERROR_DOCUMENTOS'


export default function carpetaDigitalReducers(state = dataInicial, action) {
    switch (action.type) {
        case ERROR:
            return { ...dataInicial, cargaDocumento: action.cargaDocumento }
        case LOADING_DOCUMENTOS:
            return { ...state, cargaDocumento: action.cargaDocumento }
        case LOADING_TODOS_DOCUMENTOS:
            return { ...state, loadingDocumentos: action.loadingDocumentos, retrieveDocsExito: action.retrieveDocsExito }
        case ERROR_DOCUMENTOS:
            return { ...dataInicial, loadingDocumentos: action.loadingDocumentos, retrieveDocsExito: action.retrieveDocsExito }
        case TODOS_DOCUMENTOXCUENTA_EXITO:
            return { ...state, documentos: action.payload, loadingDocumentos: action.loadingDocumentos, retrieveDocsExito: action.retrieveDocsExito }
        case CARGA_DOCUMENTOXCUENTA:
            return { ...state, cargaDocumento: action.cargaDocumento, documentoid: action.documentoid }
        default:
            return { ...state }
    }
}

export const obtenerDocumentosPorCuenta = (accountid, token) => async (dispatch) => {
    dispatch({
        type: LOADING_TODOS_DOCUMENTOS,
        loadingDocumentos: true,
        retrieveDocsExito: false
    })

    try {
        if (accountid != undefined) {

            var entidad = 'new_documentacionporcuentas';
            var fetch = "<fetch mapping='logical' distinct='false'>" +
                "<entity name='new_documentacionporcuenta'>" +
                "<attribute name='statuscode' />" +
                "<attribute name='createdon' />" +
                "<attribute name='new_cuentaid' />" +
                "<attribute name='new_fechadevencimiento' />" +
                "<attribute name='new_name' />" +
                "<attribute name='new_vinculocompartido' />" +
                "<attribute name='new_documentacionporcuentaid' />" +
                "<attribute name='new_visibleenportal' />" +
                "<attribute name='new_documentoid' />" +
                "<order attribute='new_fechadevencimiento' descending='true' />" +
                "<order attribute='new_cuentaid' descending='false' />" +
                "<filter type='and'>" +
                "<condition attribute='statecode' operator='eq' value='0' />" +
                "<condition attribute='new_cuentaid' operator='eq'  uitype='account' value='" + accountid + "' />" +
                "</filter>" +
                "<link-entity name='new_documentacion' from='new_documentacionid' to='new_documentoid' link-type='outer' alias='documentos'>" +
                "<attribute name='new_urlplantilla'/> " +
                "<attribute name='new_name'/> " +
                "<attribute name='new_descripcion'/> " +
                "</link-entity>" +
                "</entity>" +
                "</fetch>";

            await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: TODOS_DOCUMENTOXCUENTA_EXITO,
                        payload: response.data,
                        loadingDocumentos: false,
                        retrieveDocsExito: true
                    })
                })
                .catch(err => {
                    dispatch({
                        type: ERROR_DOCUMENTOS,
                        loadingDocumentos: false,
                        retrieveDocsExito: true
                    })
                })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR_DOCUMENTOS,
            loadingDocumentos: false,
            retrieveDocsExito: true
        })
    }
}

export const cargarDocumentacionPorCuenta = (file, token, documentoId) => async (dispatch) => {
    dispatch({
        type: LOADING_DOCUMENTOS,
        cargaDocumento: 'LOADING'
    })

    try {
        const configDocumentos = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            },
        };

        const subirDocumento = () => new Promise(async (resolve, reject) => {
            await axios.post(`${UrlApi}api/socioparticipe/documentacionporcuenta?documentacionporcuenta_id=${documentoId}`,
                file, configDocumentos)
                .then(data => {
                    dispatch({
                        type: CARGA_DOCUMENTOXCUENTA,
                        cargaDocumento: 'EXITO',
                        documentoid: data.data
                    })
                    resolve(data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })

        const response = await toast.promise(
            subirDocumento,
            {
                pending: 'Procesando...',
                success: 'Proceso exitoso',
                error: {
                    render({ data }) {
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )


    } catch (error) {
        dispatch({
            type: ERROR,
            cargaDocumento: 'ERROR'
        })
    }
}