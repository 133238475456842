let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const COLUMNASFIADORES = [
    {
        header: 'Contacto vinculado',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Plazo',
        // accessorKey: 'new_cbu',
        // Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Monto',
        // accessorKey: 'new_numerodecuenta',
        // Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Divisa',
        // accessorKey: 'new_numerodecuenta',
        // Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]