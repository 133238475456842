import React from 'react'
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';
import {
    Controller,
    useFormState
} from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const CustomSearchSelect = ({ name, lab, value, helperText, rules, options, ultimaOpcion, req, readOnly = false, noOptions = false, ...restProps }) => {
    const formState = useFormState()
    const [open, setOpen] = React.useState(false);
    const loading = open && !noOptions && options?.length === 0;

    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        return () => {
            active = false;
        };
    }, [loading]);

    return (
        <Controller
            name={name}
            render={({ field: { onChange, onBlur, value, ref, ...field } }) => (
                <FormControl variant="outlined" fullWidth margin="normal" color="success" 
                             error={Boolean(formState.errors && formState.errors[name])}>
                    <Autocomplete
                        open={open}
                        onOpen={() => { setOpen(true) }}
                        onClose={() => { setOpen(false) }}
                        value={value}
                        loading={loading}
                        loadingText="Cargando..."
                        noOptionsText={'No hay opciones'}
                        options={options}
                        getOptionSelected={(option) => option.value === value.value}
                        getOptionLabel={(option) => option.label}
                        onChange={(_, data) => onChange(data)}
                        onBlur={onBlur}
                        color="success"
                        fullWidth
                        readOnly={readOnly}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={lab}
                                color="success"
                                variant="outlined"
                                error={Boolean(formState.errors && formState.errors[name])}
                                required={req}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <FormHelperText>{formState.errors[name] ? helperText : null}</FormHelperText>
                </FormControl>
            )}
            rules={rules}
            {...restProps}
        />
    )
}

export default CustomSearchSelect