import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

//este componente es importante para que haya espacios dinamicos en el appbar y el sidebar
const FlexBetween = styled(Box)({
    display:"flex",
    justifyContent: "space-between",
    alignItems: "center"
})

export default FlexBetween