import axios from 'axios'
import { Entidad, UrlApiDynamics } from '../Keys'
//Const
const dataInicial = {
    loading: false,
    contactos: [],
    actualizacionDatos: ''
}

//Types
const CONTACTOS_EXITO = 'CONTACTOS_EXITO'
const LOADING = "LOADING"
const ACTUALIZAR_CONTACTO_NOSIS_EXITO = 'ACTUALIZAR_CONTACTO_NOSIS_EXITO'
const ERROR = 'ERROR'
const LOADING_ACTUALIZACION = 'LOADING_ACTUALIZACION'
const ERROR_GENERAL = 'ERROR_GENERAL'

//Reducers
export default function contactosReducers(state = dataInicial, action) {
    switch (action.type) {
        case CONTACTOS_EXITO:
            return { ...state, contactos: action.payload }
        case ACTUALIZAR_CONTACTO_NOSIS_EXITO:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        case LOADING_ACTUALIZACION:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        case ERROR_GENERAL:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        default:
            return { ...dataInicial }
    }
}

//Actions
export const obtenerContactos = () => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        const response = await axios.get(`${UrlApiDynamics}Contacto?filter=&cuit=${Entidad}`)
        dispatch({
            type: CONTACTOS_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const actualizarDatosContactoNosis = (contactid, fechaUltimaConuslta, resultadoUltimaConsulta, nombreValidacion, dniValidacion) => async (dispatch) => {
    dispatch({
        type: LOADING_ACTUALIZACION,
        actualizacionDatos: 'LOADING'
    })

    try {
        const response = await axios.post(`${UrlApiDynamics}Contacto?fechaUltimaConsulta=${fechaUltimaConuslta}&resultadoUltimaConsulta=${resultadoUltimaConsulta}
        &contactid=${contactid}&nombreValidacion=${nombreValidacion}&dniValidacion=${dniValidacion}&cuit=${Entidad}`)
        dispatch({
            type: ACTUALIZAR_CONTACTO_NOSIS_EXITO,
            payload: response.data,
            actualizacionDatos: 'EXITO'
        })
    } catch (error) {
        dispatch({
            type: ERROR_GENERAL,
            actualizacionDatos: 'ERROR'
        })
    }
}