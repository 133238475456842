import React from 'react'
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Tooltip from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import { obtenerNotaADescargar } from '../Redux/Garantias';
import { useSelector, useDispatch } from 'react-redux';

const DescargarArchivo = (nota) => {
    const dispatch = useDispatch()
    const token = useSelector(store => store.token.token)
    const [loading, setLoading] = React.useState(false)

    function downloadBase64File(event) {
        event.preventDefault()
        if (nota?.nota?.annotationid != null && nota?.nota?.annotationid != undefined) {
            if (event?.currentTarget?.id === nota?.nota?.annotationid) {
                setLoading(true)
                dispatch(obtenerNotaADescargar(nota?.nota?.annotationid, token.token))
                    .then(data => {
                        if (data?.length > 0) {
                            let documentBody = data[0]['documentbody']
                            let filename = data[0]['filename']
                            let mimetype = data[0]['mimetype']
                            if (documentBody != null && filename != null && mimetype != null) {
                                const linkSource = `data:${mimetype};base64,${documentBody}`;
                                const downloadLink = document.createElement("a");
                                downloadLink.href = linkSource;
                                downloadLink.download = filename;
                                downloadLink.click();
                                setLoading(false)
                            }
                        }
                    })
                    .catch(error => {
                        setLoading(false)
                    })
            }
        }
    }

    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
                nota?.nota?.annotationid != null ?
                    <LoadingButton
                        id={nota?.nota?.annotationid}
                        sx={{ borderRadius: 5 }}
                        size="small"
                        onClick={(e) => downloadBase64File(e)}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<CloudDownloadIcon />}
                        variant="contained"
                    >
                        <span>Descargar</span>
                    </LoadingButton> : '-'
            }
            {
                nota.nota.urlafip != null ?
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Ver Comprobante en AFIP</Typography>} sx={{ mt: 3 }}>
                        <IconButton
                            edge="end"
                            aria-label=""
                            sx={{ mx: 1 }}
                            onClick={() => window.open(nota.nota.urlafip, "_blank")}>
                            <ExitToAppIcon />
                        </IconButton>
                    </Tooltip> : '-'
            }
        </Box>
    )
}

export default DescargarArchivo
