import React, { useMemo } from 'react'
import MaterialReactTable from 'material-react-table';
import {
    Typography,
    Box,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
//Import Material React Table Translations
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//Icons Imports
import { useTheme, createTheme, ThemeProvider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
// import { ExportToCsv } from 'export-to-csv';
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Tabla = ({ columnas, lineas, titulo, handleOpen, handleOpenNew, acciones = false,
    accionAgregar = false, edicionRegistro = false, exportData = false, dataExp = [], headerDataExp = [], nombreArchivo }) => {
    const [data, setData] = React.useState(lineas)
    const columns = useMemo(() => columnas, [data])
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 5
    });
    const globalTheme = useTheme();

    React.useEffect(() => {
        if (lineas.length > 0) {
            setData(lineas)
        }
    }, [lineas])

    const openModal = (row) => {
        var id = row.getValue('id')
        handleOpen(id)
    }

    const openModalNew = () => {
        try {
            handleOpenNew()
        } catch (error) {
            console.log(error)
        }
    }

    // const csvOptions = {
    //     fieldSeparator: ',',
    //     quoteStrings: '"',
    //     decimalSeparator: '.',
    //     showLabels: true,
    //     useBom: true,
    //     useKeysAsHeaders: false,
    //     filename: nombreArchivo ? nombreArchivo : "Datos",
    //     headers: headerDataExp,
    // };

    // const csvConfig = mkConfig({
    //     fieldSeparator: ',',
    //     decimalSeparator: '.',
    //     useBom: true,
    //     useKeysAsHeaders: false,
    //     filename: nombreArchivo ? nombreArchivo : "Datos",
    //     columnHeaders: headerDataExp,
    // });

    const csvConfig = mkConfig({ useKeysAsHeaders: true, 
        filename: nombreArchivo ? nombreArchivo : "Datos" });
    // const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        let datos = []
        if (dataExp?.length > 0) {
            rows.forEach(item => {
                let datosFiltrados = dataExp.filter(dat => dat.id === item.original.id)
                const nuevoArray = datosFiltrados.map(objeto => {
                    const { id, ...restoPropiedades } = objeto;
                    return restoPropiedades;
                });
                nuevoArray?.forEach(itemDF => {
                    datos.push(itemDF)
                })
            })
        }
        if (datos.length > 0) {
            const csv = generateCsv(csvConfig)(datos)
            download(csvConfig)(csv)
            // csvExporter.generateCsv(datos);
        }
    };

    const handleExportData = () => {
        debugger
        if (dataExp?.length > 0) {
            const nuevoArray = dataExp.map(objeto => {
                const { id, ...restoPropiedades } = objeto;
                return restoPropiedades;
            });
            if (nuevoArray?.length > 0) {
                const csv = generateCsv(csvConfig)(nuevoArray)
                download(csvConfig)(csv)
                // csvExporter.generateCsv(nuevoArray);
            }
        }
    };

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.primary, //swap in the secondary color as the primary for the table
                    info: {
                        main: '#223354', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.background.main
                    },
                },
                typography: {
                    color: "#223354",
                    // fontSize: '.8rem', 
                    fontWeight: 'bold',
                    color: globalTheme.typography.primary.main,
                    button: {
                        textTransform: 'none', //customize typography styles for all buttons in table by default
                        fontSize: '1rem',
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: '1rem', //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme],

    );

    return (
        <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
                muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10],
                    showFirstLastPageButtons: false
                }}
                onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                state={{ pagination }}
                columns={columns}
                // columns={columnas}
                data={data}
                // muiTablePaperProps={{
                //     elevation: 0, //change the mui box shadow
                //     //customize paper styles
                //     sx: (theme) => ({
                //         borderRadius: '0',
                //         border: darken(theme.palette.background.default, 0.1),
                //         bgcolor: theme.palette.mode == 'dark' ? '#24292e' : '#bdbdbd'
                //     }),
                // }}
                muiTableBodyProps={{
                    sx: (theme) => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: theme.palette.mode == 'dark' ? 'rgb(17, 22, 51)' : 'rgba(255, 255, 255, 0.5)',
                        },
                    }),
                }}
                enableFullScreenToggle={false}
                enableColumnFilterModes={true}
                enableColumnOrdering={true} //mueve columnas
                enableColumnActions={true} //aciones de columnas como filtro individual
                enableColumnFilters={true}
                enableSorting={true}
                enableColumnResizing
                columnResizeMode="onChange"
                // enableGrouping
                enablePinning
                enableRowSelection={exportData}
                enableRowActions={acciones}
                enableStickyHeader
                // enableRowSelection
                initialState={{ showColumnFilters: false, columnVisibility: { id: false }, density: 'compact' }}
                positionToolbarAlertBanner="bottom"
                //idiomas
                localization={MRT_Localization_ES}
                positionActionsColumn="last"
                defaultColumn={{
                    maxSize: 400,
                    minSize: 40,
                }}
                enableEditing={false}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title={<Typography sx={{ fontSize: '0.8rem' }}>{!edicionRegistro ? "Ver" : "Subir"}</Typography>}>
                            <IconButton >
                                {!edicionRegistro ? <VisibilityIcon onClick={() => openModal(row)} /> : <UploadFileIcon onClick={() => openModal(row)} />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        {
                            accionAgregar ?
                                <Button
                                    color="secondary"
                                    onClick={() => openModalNew()}
                                    variant="contained"
                                >
                                    <PlaylistAddIcon />
                                </Button> :
                                null
                        }
                        {
                            exportData ?
                                <Button
                                    color="primary"
                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                    onClick={handleExportData}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Exportar todo
                                </Button> : null
                        }
                        {
                            exportData ?
                                <Button
                                    disabled={
                                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                    }
                                    //only export selected rows
                                    onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Exportar seleccionados
                                </Button> : null
                        }
                        <Typography variant="subtitle" p="2px">{titulo}</Typography>
                    </Box>
                )}
            />
        </ThemeProvider>
    )
}

export default Tabla