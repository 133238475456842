let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const COLUMNS = [
    {
        header: 'Línea (Tipo de Operación)',
        accessorKey: 'new_lineatipodeoperacion',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Tipo CHPD',
        accessorKey: 'new_tipochpd_value',
        Cell: ({ cell }) => {
            switch (cell.getValue()) {
                case 100000000:
                    return <strong>Propio</strong>
                case 100000001:
                    return <strong>Tercero</strong>
                default:
                    return '---'
                    break;
            }
        }
    },
    {
        header: 'Tope por Línea Comercial',
        accessorKey: 'new_topeporlineacomercial',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Tope por Línea Comercial USD',
        accessorKey: 'new_topeporlineacomercialusd',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Monto Utilizado por Operación',
        accessorKey: 'new_montoutilizadoporoperacion',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Monto Disponible por Operación',
        accessorKey: 'new_montodisponibleporoperacion',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
]

export const COLUMNS2 = [
    {
        header: 'Divisa',
        accessorKey: '_transactioncurrencyid_value',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Tope por Línea Comercial',
        accessorKey: 'new_topeporlineacomercial',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Tope por Línea Comercial USD',
        accessorKey: 'new_topeporlineacomercialusd',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Monto Utilizado General',
        accessorKey: 'new_montoutilizadogeneral',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Monto Disponible General',
        accessorKey: 'new_montodisponiblegeneral',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    }, 
]