import React from 'react'
import { Box, Typography, Divider, Button, Paper } from '@mui/material';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Container from '@mui/material/Container';
import CustomSearchSelect from '../CustomSearchSelect';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CustomSelect from '../CustomSelect';
import { useSelector, useDispatch } from 'react-redux';
import { cargarOperacion } from '../../Redux/Operaciones';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const validacionSchema = yup.object().shape({
    acreedor: yup.object({
        value: yup.string().required()
    }),
    tipoDeOperacion: yup.string().required('El apellido es requerido'),
    formatoDelCheque: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion == '4' || tipoDeOperacion == '13' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    destionDeFondos: yup.object({
        value: yup.string().required()
    })
});

const ModalNuevaOperacion = ({ open, handleClose, acreedores, destinoDeFondos }) => {
    const dispatch = useDispatch()
    const token = useSelector(store => store.token.token)
    const accountid = useSelector(store => store.usuarios.accountid)
    const [desabilitado, setDesabilitado] = React.useState(false)
    const cargaOPSelector = useSelector(store => store.operaciones.cargaOP)

    React.useEffect(() => {
        if (cargaOPSelector == "EXITO" || cargaOPSelector == "ERROR") {
            if (desabilitado == true) {
                setDesabilitado(!desabilitado)
                setTimeout(() => {
                    reset()
                    handleClose()
                }, 1500)
            }
        }
    }, [cargaOPSelector])

    const cerrarModal = () => {
        reset()
        handleClose()
    }

    const tipoDeOperacionOpciones = [
        { value: '4', label: 'Cheque de Pago Diferido' },
        { value: '17', label: 'Cheque de Pago Diferido Acreedor Bancario' },
        { value: '13', label: 'Valores de Corto Plazo' },
        { value: '10', label: 'Pagare Bursatil' },
    ]

    const formatoDelChequeOpciones = [
        { value: '100000000', label: 'Físico' },
        { value: '100000001', label: 'Echeck' },
        { value: '100000002', label: 'E-Pagaré' },
    ]

    const defaultValues = {
        acreedor: null,
        tipoDeOperacion: '',
        formatoDelCheque: '',
        destionDeFondos: null,
    }

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validacionSchema),
        mode: "onChange"
    })

    const submitDatos = (datos) => {
        if (tipoDeOperacion == '13' || tipoDeOperacion == '17') {
            setValue('formatoDelCheque', '');
        }
        dispatch(cargarOperacion(datos, accountid, token.token))
        setDesabilitado(!desabilitado)
    }

    const { handleSubmit, trigger, reset, setValue } = methods;

    const formData = methods.watch();

    const tipoDeOperacion = methods.watch('tipoDeOperacion')

    //style del modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.default',
        border: '2px solid #000',
        boxShadow: 24,
        p: 0,
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown
        >
            <Box sx={style}>
                <Paper elevation={4}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                        <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem", pt: 1 }}>
                            Nueva Operación
                        </Typography>
                        <IconButton
                            edge="end"
                            color="warning"
                            onClick={cerrarModal}
                            aria-label="close"
                            sx={{ mr: 2 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider />
                    <Container component="main">
                        <FormProvider {...methods}>
                            <form >
                                <Grid container>
                                    <Grid xs={12} sx={{ p: 1 }}>
                                        <CustomSelect
                                            name="tipoDeOperacion"
                                            label="Tipo de Operación"
                                            helperText="Por favor seleccione un valor"
                                            items={tipoDeOperacionOpciones}
                                        />
                                    </Grid>
                                    {
                                        tipoDeOperacion == '4' || tipoDeOperacion == '13' ?
                                            <Grid xs={12} sx={{ p: 1 }}>
                                                <CustomSelect
                                                    name="formatoDelCheque"
                                                    label="Formato del Cheque"
                                                    helperText="Por favor seleccione un valor"
                                                    items={formatoDelChequeOpciones}
                                                />
                                            </Grid> : null
                                    }
                                    <Grid xs={12} sx={{ p: 1 }}>
                                        <CustomSearchSelect
                                            name="destionDeFondos"
                                            lab="Destino de Fondos"
                                            helperText="Por favor seleccione un valor"
                                            options={destinoDeFondos}
                                        />
                                    </Grid>
                                    <Grid xs={12} sx={{ p: 1 }}>
                                        <CustomSearchSelect
                                            name="acreedor"
                                            lab="Acreedor"
                                            helperText="Por favor seleccione un valor"
                                            options={acreedores}
                                        />
                                    </Grid>
                                    <Grid xs={12} sx={{ pr: 1 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit(submitDatos)}
                                                disabled={desabilitado}
                                                sx={{ my: 3, ml: 1 }}
                                            >Guardar</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </Container>
                </Paper>
            </Box>
        </Modal >
    )
}

export default ModalNuevaOperacion