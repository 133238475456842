import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import CustomTextField from '../Components/CustomTextField';
import CustomSelect from '../Components/CustomSelect';
import { obtenerActividadesAFIP, obtenerCondicionImpositiva, obtenerReferidor } from '../Redux/DatosSolicitudAlta';
import Paper from '@mui/material/Paper';
import CustomCurrencyField from '../Components/CustomCurrencyField';
import CustomSearchSelect from '../Components/CustomSearchSelect';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const SolicitudAltaCuenta = ({ personeria, token }) => {
    const dispatch = useDispatch()
    const [actividadAfip, setActividadAfip] = React.useState([])
    const [referidores, setReferidores] = React.useState([])
    const [llamadaAfip, setLlamadaAfip] = React.useState(false)
    const [llamadaCondicion, setLlamadaCondicion] = React.useState(false)
    const [llamadaReferidores, setLlamadaReferidores] = React.useState(false)
    const [condiciones, setCondiciones] = React.useState([])
    const actividadAfipSelector = useSelector(store => store.datos.actividades)
    const condicionAfipSelector = useSelector(store => store.datos.condiciones)
    const referidoresSelector = useSelector(store => store.datos.referidores)
    const retrieveActividadAFIPSelector = useSelector(store => store.datos.retrieveActividadAFIP)
    const retrieveCondicionAFIPSelector = useSelector(store => store.datos.retrieveCondicionAFIP)
    const retrieveReferidorSelector = useSelector(store => store.datos.retrieveReferidor)

    React.useEffect(() => {
        if (actividadAfipSelector.length > 0 && llamadaAfip === true) {
            setActividadAfip(actividadAfipSelector)
            const opcionesAfip = [];
            actividadAfipSelector.forEach(element => {
                if (element.new_codigo !== null) {
                    var actividad = { value: element.new_actividadafipid, label: element.new_codigo + ' - ' + element.new_name }
                } else {
                    var actividad = { value: element.new_actividadafipid, label: element.new_name }
                }
                opcionesAfip.push(actividad);
            });
            setActividadAfip(opcionesAfip)
        } else if (token != '' && llamadaAfip === false) {
            obtenerActividades()
            setLlamadaAfip(true)
        }

        if (personeria !== '') {
            if (personeria === '100000000') {
                var societario = document.getElementById('societario')
                societario.style.display = 'block';
            } 
            // else if (personeria === '100000001') {
            //     var condicion = document.getElementById('condicion')
            //     condicion.style.display = 'block';
            // }
        }
    }, [actividadAfipSelector, token])

    React.useEffect(() => {
        if (condicionAfipSelector.length > 0 && llamadaCondicion === true) {
            const condicionesAfip = [];
            condicionAfipSelector.forEach(element => {
                var condicion = { value: element.new_condiciondeinscipcionanteafipid, label: element.new_name }
                condicionesAfip.push(condicion);
            });
            setCondiciones(condicionesAfip)
        } else if (token != '' && llamadaCondicion === false) {
            obtenerCondiciones()
            setLlamadaCondicion(true)
        }
    }, [condicionAfipSelector, token])

    React.useEffect(() => {
        if (referidoresSelector.length > 0 && llamadaReferidores === true) {
            const referidoresAux = [];
            referidoresSelector.forEach(element => {
                var referidor = { value: element.accountid, label: element.name + ' - ' + element.new_nmerodedocumento }
                referidoresAux.push(referidor);
            });
            setReferidores(referidoresAux)
        } else if (token != '' && llamadaReferidores === false) {
            dispatch(obtenerReferidor(token.token))
            setLlamadaReferidores(true)
        }
    }, [referidoresSelector, token])


    const obtenerActividades = () => {
        dispatch(obtenerActividadesAFIP(token.token))
    }

    const obtenerCondiciones = () => {
        dispatch(obtenerCondicionImpositiva(token.token))
    }

    const condicionImpositivaOpciones = [
        { value: '100000000', label: 'Autónomo' },
        { value: '100000001', label: 'Relación de Dependencia' },
        { value: '100000002', label: 'Otro' }
    ]

    const tiposSocietariosOpciones = [
        { value: '100000000', label: 'S.A.' },
        { value: '100000001', label: 'S.R.L.' },
        { value: '100000002', label: 'Cooperativa' },
        { value: '100000003', label: 'Mutual' },
        { value: '100000004', label: 'Gobierno' },
        { value: '100000005', label: 'S.A.S.' },
        { value: '100000006', label: 'Asociación Civil sin Fines de Lucro' },
        { value: '100000007', label: 'ONG' },
        { value: '100000008', label: 'Fundación' },
        { value: '100000009', label: 'LLC' }
    ]

    const theme = useTheme();

    const opcionCargando = [{ value: null, label: 'Cargando...' }]

    return (
        <>
            <Paper elevation={4}>
                {/* <Grid xs={12} sx={{ bgcolor: theme.palette.primary.main, p: 1, borderRadius: '5px', m: 0 }}>
                    <Typography variant="subtitle1" sx={{ m: 0 }} gutterBottom>
                        Documentación requerida
                    </Typography>
                </Grid> */}
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomSearchSelect
                                name="actividad"
                                lab="Actividad AFIP"
                                helperText="Por favor seleccione un valor"
                                options={actividadAfip}
                                noOptions={retrieveActividadAFIPSelector && actividadAfip.length === 0 ? true : false}
                                rules={{ required: "Required!" }}
                                req="true"
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item id="societario" sx={{ pt: 0, pb: 0 }} style={{ display: 'none' }}>
                            <CustomSelect
                                name="tipoSocietario"
                                label="Tipo de Societario"
                                helperText="Por favor seleccione un valor"
                                items={tiposSocietariosOpciones}
                                rules={{ required: "Required!" }}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} sx={{ pt: 0, pb: 0 }} item id="condicion">
                            <CustomSearchSelect
                                name="condicionImp"
                                lab="Condición de Inscripción ante AFIP"
                                helperText="Por favor seleccione un valor"
                                options={condiciones}
                                noOptions={retrieveCondicionAFIPSelector && condiciones.length === 0 ? true : false}
                                rules={{ required: "Required!" }}
                                req="true"
                            />
                        </Grid>
                        <Grid xs={12} sm={6} sx={{ pt: 0, pb: 0 }} item>
                            <CustomCurrencyField
                                // Component={TextField}
                                name="facturacionIngreso"
                                label="Facturación/Ingresos Último Año"
                                rules={{ required: "Required!" }}
                                helperText="Escribe tu facturacion/ingresos del último año"
                                req="true"
                            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomSearchSelect
                                name="cuitReferidor"
                                lab="Referidor"
                                noOptions={retrieveReferidorSelector && referidores.length === 0 ? true : false}
                                // helperText="Por favor seleccione un valor"
                                options={referidores}
                                // rules={{ required: "Required!" }}
                                // req="true"
                            />
                            {/* <CustomTextField
                                Component={TextField}
                                name="cuitReferidor"
                                label="CUIT del Referidor"
                                // rules={{ required: "Required!" }}
                                helperText="Escribe el CUIT del Referidor"
                            /> */}
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>
        </>
    )
}

export default SolicitudAltaCuenta