import React, { useMemo, useCallback, useState } from 'react'
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { readOnlyDatos } from '../Keys'

const TablaRelaciones = ({ lineas, columnas, handleOpen, titulo, funcionEliminar }) => {
    const [tableData, setTableData] = useState(lineas);
    const columns = useMemo(() => columnas, [tableData])

    React.useEffect(() => {
        if (lineas.length > 0) {
            setTableData(lineas)
        }
    }, [lineas])

    const abrilModalEdicion = (row) => {
        let id = row.getValue('id')
        let object = row.original
        let editar = true
        handleOpen(id, object, editar)
    }

    const abrirModalAgregar = () => {
        let id = ''
        let obj = {}
        let editar = false
        handleOpen(id, obj, editar)
    }

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(`Estas seguro que deseas eliminar este registro?`)
            ) {
                return;
            }
            funcionEliminar(tableData[row.index])
            // console.log(tableData[row.index])
        },
        [tableData],
    );




    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableFullScreenToggle={false}
                columns={columns}
                data={tableData}
                localization={MRT_Localization_ES}
                editingMode="modal" //default
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    density: 'compact',
                    expanded: true,
                    grouping: ['new_tipoderelacion'],
                    pagination: { pageIndex: 0, pageSize: 10 },
                }}
                enableGrouping
                enableColumnOrdering
                enableEditing={true}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        {/* <Tooltip arrow placement="left" title="Editar / Ver"> */}
                        <Tooltip arrow placement="left" title="Ver">
                            <IconButton onClick={() => abrilModalEdicion(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        {
                            !readOnlyDatos ?
                                <Tooltip arrow placement="right" title="Borrar">
                                    <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip> : null
                        }
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <>
                        {
                            !readOnlyDatos ?
                                <Button
                                    color="secondary"
                                    onClick={() => abrirModalAgregar()}
                                    variant="contained"
                                >
                                    <PlaylistAddIcon />
                                </Button> : null
                        }
                        <Typography variant="subtitle" p="2px">{titulo}</Typography>
                    </>
                )}
            />
        </>
    )
}

export default TablaRelaciones