import React from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import CustomTextField from '../Components/CustomTextField';
import CustomSelect from '../Components/CustomSelect';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

const SolicitudAltaPersonal = ({ personeria }) => {

    const [relacionConLaCuentaOpciones, setRelacionConLaCuentaOpciones] = React.useState([])

    React.useEffect(() => {
        if (personeria === "100000000") { //Juridica
            setRelacionConLaCuentaOpciones([
                { value: '100000001', label: 'Accionista' },
                { value: '100000002', label: 'Beneficiario' },
                { value: '100000003', label: 'Miembro del Directorio' },
                { value: '100000004', label: 'Representante Legal/Apoderado' },
                { value: '100000007', label: 'Asesor/Estructurador del proyecto' },
                { value: '100000005', label: 'Otro' }
            ])
        } else if (personeria === "100000001") { //Humana
            setRelacionConLaCuentaOpciones([
                { value: '100000000', label: 'Titular' },
                { value: '100000007', label: 'Asesor/Estructurador del proyecto' },
                { value: '100000005', label: 'Otro' }
            ])
        }
    }, [])

    const theme = useTheme();

    return (
        <>
            <Paper elevation={4}>
                {/* <Grid xs={12} sx={{ bgcolor: theme.palette.primary.main, p: 1, borderRadius: '5px', m: 0 }}>
                    <Typography variant="subtitle1" sx={{ m: 0 }} gutterBottom>
                        Documentación requerida
                    </Typography>
                </Grid> */}
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                name="nombre"
                                label="Nombre"
                                rules={{ required: "Required!" }}
                                helperText="Por favor escribe tu nombre"
                                req="true"
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                name="apellido"
                                label="Apellido"
                                rules={{ required: "Required!" }}
                                helperText="Por favor escribe tu apellido"
                                req="true"
                            />

                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                name="cuitCuil"
                                label="CUIT/CUIL"
                                rules={{ required: "Required!" }}
                                helperText="Por favor escribe tu CUIT/CUIL"
                                req="true"
                            />
                            <FormHelperText sx={{ mx: 1, color: '#f57c00' }} id="outlined-weight-helper-text">
                                ¡ Sin espacios ni guiones !
                            </FormHelperText>
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                name="telefono"
                                label="Teléfono"
                                rules={{ required: "Required!" }}
                                helperText="Por favor escribe tu Teléfono"
                                req="true"
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                name="usuarioPortal"
                                label="Usuario Portal"
                                rules={{ required: "Required!" }}
                                helperText="Por favor escribe tu email que usaras en el portal"
                                req="true"
                            />
                            <FormHelperText sx={{ mx: 1, color: '#f57c00' }} id="outlined-weight-helper-text">
                                ¡ Este usuario debe ingresarse con formate de correo electrónico y debe ser único.
                                Se usara luego para iniciar sesión cuando se habilite el acceso en el portal del socio !
                            </FormHelperText>
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                name="emailNotificaciones"
                                label="Email para Notificaciones"
                                rules={{ required: "Required!" }}
                                helperText="Por favor escribe tu email para notificaciones"
                                req="true"
                            />
                            <FormHelperText sx={{ mx: 1, color: '#f57c00' }} id="outlined-weight-helper-text">
                                ¡ Correo electrónico del contacto donde el sistema enviará las notificaciones y novedades !
                            </FormHelperText>
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomSelect
                                name="relacionCuenta"
                                label="Relación con la cuenta"
                                helperText="Por favor seleccione un valor"
                                items={relacionConLaCuentaOpciones}
                                rules={{ required: "Required!" }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>
        </>
    )
}

export default SolicitudAltaPersonal