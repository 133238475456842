import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

var firebaseConfig = {
  apiKey: "AIzaSyAslZOpkDtQXLiA0_1qOZLcPqG2TPfTnbE",
  authDomain: "portal-innovasgr.firebaseapp.com",
  projectId: "portal-innovasgr",
  storageBucket: "portal-innovasgr.appspot.com",
  messagingSenderId: "180307053521",
  appId: "1:180307053521:web:d3aa722e77f38cf7edaef0"
};

app.initializeApp(firebaseConfig);

const firebase = app.firestore()
const auth = app.auth()
const storage = app.storage()

export {firebase, auth, storage}