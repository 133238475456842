import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { obtenerCertificadoPyme } from '../Redux/Cuenta'
import { COLUMNSCERTIFICADOS } from '../columnas/ColumnasCertificados';
import Tabla from './Tabla';
import SpinnerF from './SpinnerF';
import { Typography, Grid, Paper } from '@mui/material';
import { Box } from '@mui/material';
import moment from 'moment'

const CertificadosPymes = () => {
    const dispatch = useDispatch()
    const [certificados, setCertificados] = React.useState([])
    const [llamadaCertificados, setLlamadaCertificados] = React.useState(false)
    const certificadosSelector = useSelector(store => store.cuenta.certificadosPymes)
    const [columnasCertificados, setColumnasCertificados] = React.useState([])
    const accountid = useSelector(store => store.usuarios.accountid)
    const loadingCertificadoSelector = useSelector(store => store.cuenta.loadingCertificado)
    const [loadingData, setLoadingData] = React.useState(false)
    const token = useSelector(store => store.token.token)

    React.useEffect(() => {
        if (certificadosSelector.length > 0) {
            let CertificadosFormat = []
            certificadosSelector.forEach(element => {
                    let certificadoFormat = {
                        id: element["new_certificadopymeid"],
                        new_vigenciahasta: moment(new Date(element["new_vigenciahasta"])).format('DD/MM/yyyy'),
                        new_vigenciadesde: moment(new Date(element["new_vigenciadesde"])).format('DD/MM/yyyy'),
                        statuscode: element["statuscode@OData.Community.Display.V1.FormattedValue"],
                        new_numeroderegistro: element["new_numeroderegistro"],
                    }
                    CertificadosFormat.push(certificadoFormat)
                })
            setCertificados(CertificadosFormat)
            setColumnasCertificados(COLUMNSCERTIFICADOS)
            if (llamadaCertificados == false) {
                setLlamadaCertificados(true)
            }
        } else if (llamadaCertificados === false) {
            obtenerTodosCertificadosPymes()
            setLlamadaCertificados(true)
        }
    }, [certificadosSelector])

    const obtenerTodosCertificadosPymes = () => {
        dispatch(obtenerCertificadoPyme(accountid, token.token))
    }

    React.useEffect(() => {
        setLoadingData(loadingCertificadoSelector)
    }, [loadingCertificadoSelector])

    return (
        <Grid container spacing={4} sx={{ pt: 2 }}>
            <Grid item xs={12}>
                <Paper elevation={4} >
                    {
                        loadingData ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    py: 2
                                }}>
                                <SpinnerF />
                            </Box> 
                        )
                            : 
                            <Tabla lineas={certificados} columnas={COLUMNSCERTIFICADOS} titulo={"Certificados pymes"} />
                            // !loadingData && certificados.length > 0 ? (
                            //     <Tabla lineas={certificados} columnas={columnasCertificados} titulo={"Certificados pymes"} />
                            // ) : !loadingData && certificadosSelector.length == 0 && llamadaCertificados == true ?
                            //     <Tabla lineas={[]} columnas={columnasCertificados} titulo={"Certificados pymes"} /> : null
                    }

                </Paper>
            </Grid>
        </Grid>
    )
}

export default CertificadosPymes