import React from 'react'
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Select, MenuItem, Box, List, ListItem } from '@mui/material';
import DashboardCard from '../DashboardCard';
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, Avatar } from '@mui/material';
let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});
const colors = ["rgb(84, 230, 0)", "rgb(255, 45, 0)", "rgb(255, 163, 25)", "rgb(87, 202, 34)", "rgb(0, 155, 255)",
    "rgb(255, 45, 0)", "rgb(243, 156, 18)", "rgb(87, 124, 96)", "rgb(77, 103, 117)", "rgb(239, 226, 152)"]

const GraficoDonaApex = ({ datos, opciones, titulo, opcionPrincipal }) => {
    const [datosGrafico, setDatosGraficos] = React.useState([])
    const [opcion, setOpcion] = React.useState([])
    const theme = useTheme();
    const primary = theme.palette.primary.main;
    const secondary = theme.palette.secondary.main;

    React.useEffect(() => {
        // let opcion
        // if (opcionPrincipal != undefined) {
        //     opcion = opciones?.find(item => item === opcionPrincipal)
        // } else {
        //     opcion = opciones[0]
        // }
        // setOpcion(opcion)
        // setDatosGraficos(datos.filter(item => item.filtro === opcion))
        setDatosGraficos(datos)
    }, [datos])

    const handleChange = (event) => {
        setOpcion(event.target.value);
        setDatosGraficos(datos.filter(item => item.filtro === event.target.value))
    };

    function Item(props) {
        const { sx, ...other } = props;
        return (
            <Box
                sx={{
                    p: 1,
                    m: 1,
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#223354' : 'grey.100'),
                    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    ...sx,
                }}
                {...other}
            />
        );
    }

    Item.propTypes = {
        /**
         * The system prop that allows defining system overrides as well as additional CSS styles.
         */
        sx: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
            ),
            PropTypes.func,
            PropTypes.object,
        ]),
    };

    // chart
    const optionscolumnchart = {
        chart: {
            locales: [{
                "name": "en",
                "options": {
                    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    "toolbar": {
                        "exportToSVG": "Descargar SVG",
                        "exportToPNG": "Descargar PNG",
                        "exportToCSV": "Descargar CSV",
                        "menu": "Menu",
                        "selection": "Selection",
                        "selectionZoom": "Selection Zoom",
                        "zoomIn": "Zoom In",
                        "zoomOut": "Zoom Out",
                        "pan": "Panning",
                        "reset": "Reset Zoom"
                    }
                }
            }],
            defaultLocale: "en",
            type: 'donut',
            fontFamily: "'Plus Jakarta Sans', sans-serif;",
            foreColor: '#adb0bb',
            toolbar: {
                show: true,
                export: {
                    csv: {
                        filename: titulo,
                        columnDelimiter: ',',
                        headerCategory: 'Serie',
                        headerValue: 'Cantidad',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    png: {
                        filename: titulo,
                        columnDelimiter: ',',
                        headerCategory: 'Serie',
                        headerValue: 'Cantidad',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    svg: {
                        filename: titulo,
                        columnDelimiter: ',',
                        headerCategory: 'Serie',
                        headerValue: 'Cantidad',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                }
            }
        },
        labels: datosGrafico.map(item => item.opcion),
        colors: colors,
        plotOptions: {
            pie: {
                expandOnClick: true,
                startAngle: 0,
                endAngle: 360,
                donut: {
                    size: '70%',
                    background: 'transparent',
                    labels: {
                        show: false,
                        style: {
                            fontSize: '10px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 'bold',
                            colors: '#000'
                        },
                        value: {
                            show: true,
                            fontSize: '10px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            color: '#fff',
                            offsetY: 2,
                            formatter: function (val) {
                                return val
                            }
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            color: '#373d3f',
                            formatter: function (w) {
                                return w
                            }
                        }
                    },
                },
            },
        },
        tooltip: {
            theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
            fillSeriesColor: false,
            enabled: true,
            y: {
                formatter: function (val) {
                    return val
                }
            },
        },
        stroke: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            fformatter: function (val) {
                return val + "%"
            },
        },
        legend: {
            show: false,
        },
        responsive: [
            {
                breakpoint: 991,
                options: {
                    chart: {
                        width: 120,
                    },
                },
            },
        ],
    };

    return (
        <DashboardCard title={titulo}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', my: 2, mx: 2 }}>
                {
                    datosGrafico.map(item => {
                        let index = datosGrafico.indexOf(item);
                        return (
                            <Item>
                                <Stack direction="row" sx={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                    <Avatar
                                        sx={{ mr: 1, width: 9, height: 9, bgcolor: colors[index], svg: { display: 'none' } }}
                                    ></Avatar>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {item.opcion}
                                    </Typography>
                                </Stack>
                            </Item>
                        )
                    })
                }
            </Box>
            <Box sx={{
                "& .apexcharts-menu": {
                    background: '#223354!important',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'normal',
                    color: "rgba(255, 255, 255, 0.8)"
                },
                "& .apexcharts-menu:hover": {
                    color: "#223354!important"
                },
            }}>
                <Chart
                    options={optionscolumnchart}
                    series={datosGrafico.map(item => item.cantidad)}
                    type="donut"
                    height="280px"
                    width="100%"
                />
            </Box>
        </DashboardCard>
    )
}

export default GraficoDonaApex