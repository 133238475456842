import Chip from '@mui/material/Chip';

export const COLUMNAOPERACIONES = tema => [
    {
        accessorKey: 'id',
    },
    {
        header: 'Nro. de Orden',
        accessorKey: 'new_nrooperacion',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Tipo de Operación',
        accessorKey: 'new_tipooperacin',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
        // Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Estado',
        accessorKey: 'statuscode',
        Cell: ({ cell }) => {
            switch (cell.getValue()) {
                case "Enviada":
                    return <Chip label="Enviada" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(0, 155, 255, 0.2)' : 'rgb(0, 155, 255, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(0, 155, 255)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                    }} />
                case "Monetizada":
                    return <Chip label="Monetizada" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                    }} />
                case "Pendiente":
                    return <Chip label="Pendiente" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                    }} />
                case "Instrumentada":
                    return <Chip label="Instrumentada" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(0, 155, 255, 0.2)' : 'rgb(0, 155, 255, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(0, 155, 255)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                    }} />
            }
        }
    },
    {
        header: 'Destino de Fondo',
        accessorKey: 'new_destinodefondo',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Fecha de Creación',
        accessorKey: 'fechaCreacion_str',
        Cell: ({ cell }) => { cell.getValue() }
    },

    // {
    //     accessorKey: 'new_operacionid',
    //     Cell: ({ value }) => {
    //         return (
    //             <SeleccionarFila value={value} />
    //         )
    //     }
    // }
]