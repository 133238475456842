import axios from 'axios'
import { Entidad, UrlApiDynamics, UrlApi } from '../Keys'
import { toast } from 'react-toastify';

const dataInicial = {
    loadingGarantias: false,
    loadingDocumentBody: false,
    garantias: [],
    garantiasFetch: [],
    adjuntosGarantia: [],
    notaDescarga: [],
    cargaDocumento: '',
    documentoid: '',
}

const ERROR = 'ERROR'
const LOADING_GARANTIAS = 'LOADING_GARANTIAS'
const LOADING_ADJUNTOS = 'LOADING_ADJUNTOS'
const ERROR_GARANTIAS = 'ERROR_GARANTIAS'
const TODAS_GARANTIAS_EXITO = 'TODAS_GARANTIAS_EXITO'
const TODAS_GARANTIASFETCH_EXITO = 'TODAS_GARANTIASFETCH_EXITO'
const TODOS_ADJUNTOSGARANTIA_EXITO = 'TODOS_ADJUNTOSGARANTIA_EXITO'
const LOADING_CARGAADJUNTOGARANTIA = 'LOADING_CARGAADJUNTOGARANTIA'
const CARGA_ADJUNTOGARANTIA = 'CARGA_ADJUNTOGARANTIA'
const LOADING_NOTADESCARGA = 'LOADING_NOTADESCARGA'
const NOTADESCARGA_EXITO = 'NOTADESCARGA_EXITO'
const NOTADESCARGA_ERROR = 'NOTADESCARGA_ERROR'
const LIMPIAR_DOCUMENTBODY = 'LIMPIAR_DOCUMENTBODY'

const ToastError = (msj) => {
    toast.error(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export default function garantiasReducers(state = dataInicial, action) {
    switch (action.type) {
        case TODAS_GARANTIAS_EXITO:
            return { ...state, garantias: action.payload, loadingGarantias: action.loadingGarantias }
        case TODAS_GARANTIASFETCH_EXITO:
            return { ...state, garantiasFetch: action.payload, loadingGarantias: action.loadingGarantias }
        case LOADING_GARANTIAS:
            return { ...state, loadingGarantias: action.loadingGarantias }
        case ERROR_GARANTIAS:
            return { ...state, loadingGarantias: action.loadingGarantias }
        case TODOS_ADJUNTOSGARANTIA_EXITO:
            return { ...state, adjuntosGarantia: action.payload }
        case LOADING_CARGAADJUNTOGARANTIA:
            return { ...state, cargaDocumento: action.cargaDocumento }
        case CARGA_ADJUNTOGARANTIA:
            return { ...state, cargaDocumento: action.cargaDocumento, documentoid: action.documentoid }
        case LOADING_NOTADESCARGA:
            return { ...state, loadingDocumentBody: true }
        case NOTADESCARGA_EXITO:
            return { ...state, notaDescarga: action.payload, loadingDocumentBody: false }
        case NOTADESCARGA_ERROR:
            return { ...state, notaDescarga: [], loadingDocumentBody: false }
        case LIMPIAR_DOCUMENTBODY:
            return { ...state, notaDescarga: [], loadingDocumentBody: false }
        default:
            return { ...state }
    }
}

export const obtenerTodasGarantias = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING_GARANTIAS,
        loadingGarantias: true
    })

    try {
        if (accountid != undefined) {
            const response = await axios(`${UrlApiDynamics}Garantia?filter=_new_socioparticipe_value eq ${accountid}&cuit=${Entidad}`)
            dispatch({
                type: TODAS_GARANTIAS_EXITO,
                payload: response.data,
                loadingGarantias: false
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR_GARANTIAS,
            loadingGarantias: false
        })
    }
}

export const obtenerGarantiasFetch = (cuentaid, token) => async (dispatch) => {
    dispatch({
        type: LOADING_GARANTIAS,
        loadingGarantias: true
    })

    var entidad = "new_garantias"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_garantia'>" +
        "<attribute name='new_monto'/> " +
        "<attribute name='new_name'/> " +
        "<attribute name='new_fechadenegociacion'/>" +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_garantiaid'/> " +
        "<attribute name='createdon'/> " +
        "<attribute name='new_ndeordendelagarantiaotorgada'/> " +
        "<attribute name='new_fechadevencimiento'/> " +
        "<attribute name='new_tipodeoperacion'/> " +
        "<attribute name='new_acreedor'/> " +
        "<attribute name='new_tipodegarantias'/>" +
        "<attribute name='transactioncurrencyid'/> " +
        "<attribute name='new_referido'/> " +
        "<attribute name='new_sociosprotector'/> " +
        "<attribute name='new_fechadecancelada'/> " +
        "<attribute name='new_fechadeanulada'/> " +
        "<attribute name='new_formatodelcheque'/> " +
        "<attribute name='new_numerodecheque'/> " +
        "<attribute name='new_tipochpd'/> " +
        "<attribute name='new_libradorcheque'/> " +
        "<attribute name='new_tasa'/> " +
        "<attribute name='new_plazodias'/> " +
        "<attribute name='new_periodogracia'/> " +
        "<attribute name='new_sistemadeamortizacion'/> " +
        "<attribute name='new_periodicidadpagos'/> " +
        "<attribute name='new_observaciones'/> " +
        "<order attribute ='createdon' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='new_socioparticipe' operator='eq' value='" + cuentaid + "' />" +
        // "<condition attribute='statecode' operator='eq' value='' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: TODAS_GARANTIASFETCH_EXITO,
                    payload: response.data,
                    loadingGarantias: false
                })
            })
            .catch(err => {
                // console.log(err)
            })
    }
    catch (error) {
        dispatch({
            type: ERROR,
            loadingGarantias: false
        })
    }
}

export const obtenerAdjuntosGarantias = (cuentaid, token) => async (dispatch) => {
    // dispatch({
    //     type: LOADING_GARANTIAS,
    //     loadingGarantias: true
    // })

    var entidad = "new_adjuntoses"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_adjuntos'>" +
        "<attribute name='new_adjuntosid'/> " +
        "<attribute name='new_name'/> " +
        "<attribute name='new_tipo'/>" +
        "<attribute name='createdon'/> " +
        "<attribute name='new_garantia'/> " +
        "<attribute name='statuscode'/> " +
        "<order attribute ='createdon' descending='false' />" +
        "<link-entity name='new_garantia' from='new_garantiaid' to='new_garantia' link-type='inner' alias='garantia'>" +
        "<filter type='and'>" +
        "<condition attribute='new_socioparticipe' operator='eq' value='" + cuentaid + "' />" +
        "</filter>" +
        "</link-entity>" +
        "<link-entity name='annotation' from='objectid' to='new_adjuntosid' link-type='outer' alias='nota'>" +
        "<attribute name='annotationid'/> " +
        "<attribute name='filename'/> " +
        "<attribute name='mimetype'/> " +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: TODOS_ADJUNTOSGARANTIA_EXITO,
                    payload: response.data,
                })
            })
            .catch(err => {
                // console.log(err)
            })
    }
    catch (error) {
        dispatch({
            type: ERROR,
            loadingGarantias: false
        })
    }
}

// export const obtenerNotaADescargar = (notaid, token) => async (dispatch) => {
//     dispatch({
//         type: LOADING_NOTADESCARGA,
//     })

//     var entidad = "annotations"
//     var fetch = "<fetch mapping='logical'>" +
//         "<entity name='annotation'>" +
//         "<attribute name='annotationid'/> " +
//         "<attribute name='documentbody'/> " +
//         "<filter type='and'>" +
//         "<condition attribute='annotationid' operator='eq' value='" + notaid + "' />" +
//         "</filter>" +
//         "</entity>" +
//         "</fetch>";
//     try {
//         await axios.post(`${UrlApi}api/consultafetch`,
//             {
//                 "entidad": entidad,
//                 "fetch": fetch
//             },
//             {
//                 headers: {
//                     "Authorization": `Bearer ${token}`
//                 }
//             })
//             .then((response) => {
//                 dispatch({
//                     type: NOTADESCARGA_EXITO,
//                     payload: response.data,
//                 })
//             })
//             .catch(err => {
//                 dispatch({
//                     type: NOTADESCARGA_ERROR
//                 })
//             })
//     }
//     catch (error) {
//         dispatch({
//             type: NOTADESCARGA_ERROR
//         })
//     }
// }

export const obtenerNotaADescargar = (notaid, token) => async (dispatch) => {
    dispatch({
        type: LOADING_NOTADESCARGA,
    })

    var entidad = "annotations"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='annotation'>" +
        "<attribute name='annotationid'/> " +
        "<attribute name='filename'/> " +
        "<attribute name='mimetype'/> " +
        "<attribute name='documentbody'/> " +
        "<filter type='and'>" +
        "<condition attribute='annotationid' operator='eq' value='" + notaid + "' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";
    try {
        return new Promise((resolve, reject) => {
            axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {
                    ToastError("Error al descargar archivo")
                    reject(err)
                })
        })
    }
    catch (error) {
        ToastError("Error al descargar archivo")
        dispatch({
            type: NOTADESCARGA_ERROR
        })
    }
}

export const limpiarDocumentBody = () => async (dispatch) => {
    dispatch({
        type: LIMPIAR_DOCUMENTBODY,
    })
}

export const cargarAdjuntoGarantia = (file, garantiaid, token) => async (dispatch) => {
    dispatch({
        type: LOADING_CARGAADJUNTOGARANTIA,
        cargaDocumento: 'LOADING',
        documentoid: ''
    })

    try {
        const configDocumentos = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            },
        };

        const subirDocumento = () => new Promise(async (resolve, reject) => {
            await axios.post(`${UrlApi}api/socioparticipe/adjuntosgarantia?garantia_id=${garantiaid}`,
                file, configDocumentos)
                .then(data => {
                    dispatch({
                        type: CARGA_ADJUNTOGARANTIA,
                        cargaDocumento: 'EXITO',
                        documentoid: data.data
                    })
                    // console.log(data)
                    resolve(data.data)
                })
                .catch(err => {
                    // console.log(err)
                    reject(err)
                })
        })

        const response = await toast.promise(
            subirDocumento,
            {
                pending: 'Procesando...',
                success: 'Proceso exitoso',
                error: {
                    render({ data }) {
                        return `Error al cargar documento, ponganse en contacto con mesa de ayuda`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (err) {
        // console.log(err)
        crearExcepcion(`Excepcion al subir documento` + err, token)
    }
}

const crearExcepcion = (error, token) => {
    axios.post(`https://hw365api.azurewebsites.net/api/excepcion`,
        {
            "descripcion": error
        },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
}
