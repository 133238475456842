import React from 'react'
import Moment from 'moment'
import Chip from '@mui/material/Chip';

import {
    MenuItem,
} from '@mui/material';

let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

//Todas
export const COLUMNSGT = (tema) => [
    {
        accessorKey: 'id',
    },
    {
        minSize: 100, //min size enforced during resizing
        maxSize: 120, //max size enforced during resizing
        // size: 100,
        header: 'N° Orden',
        accessorKey: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Tipo de Operacion',
        accessorKey: 'new_tipodeoperacion',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        minSize: 100, //min size enforced during resizing
        maxSize: 140, //max size enforced during resizing
        header: 'Acreedor',
        accessorKey: 'new_acreedor',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        minSize: 100, //min size enforced during resizing
        maxSize: 120, //max si
        header: 'Estado',
        accessorKey: 'statuscode_value',
        Cell: ({ cell }) => obtenerChip(cell.getValue(), tema)
    },
    {
        minSize: 100, //min size enforced during resizing
        maxSize: 140, //max size enforced during resizing
        header: 'Monto',
        accessorKey: 'new_monto',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Fecha de Creación',
        accessorKey: 'createdon',
        Cell: ({ cell }) => { cell.getValue() }
    },

    // {
    //     accessorKey: 'state',
    //     header: 'State',
    //     muiTableBodyCellEditTextFieldProps: {
    //         select: true, //change to select for a dropdown
    //         children: states.map((state) => (
    //             <MenuItem key={state} value={state}>
    //                 {state}
    //             </MenuItem>
    //         )),
    //     },
    // }
]

export const COLUMNSGOPERACIONES = [
    {
        accessorKey: 'id',
    },
    {
        header: 'Tipo de Operación',
        accessorKey: 'new_tipodeoperacion',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Acreedor',
        accessorKey: 'new_acreedor',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Fecha de Carga',
        accessorKey: 'createdon',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Estado',
        accessorKey: 'statuscode_value',
        Cell: ({ cell }) => obtenerChip(cell.getValue())
    },
    {
        header: 'Monto Bruto',
        accessorKey: 'new_monto',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
]

//en cartera
export const COLUMNSGEC = [
    {
        accessorKey: 'id',
    },
    {
        header: 'N° Orden',
        accessorKey: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Tipo de Operacion',
        accessorKey: 'new_tipodeoperacion',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Acreedor',
        accessorKey: 'new_acreedor',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Monto',
        accessorKey: 'new_monto',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Fecha de vencimiento',
        accessorKey: 'new_fechadevencimiento',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

function obtenerChip(data, tema) {

    switch (data) {
        case "En Cartera":
            return <Chip label="En Cartera" sx={{
                bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
                color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
        case "En Gestión":
            return <Chip label="En Gestión" sx={{
                bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
                color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
        case "Vigente":
            return <Chip label="Vigente" sx={{
                bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
                color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
        case "Vencida":
            return <Chip label="Vencida" sx={{
                bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 45, 0 , 0.2)' : 'rgb(255, 45, 0 , 0.8)',
                color: tema.palette.mode === "dark" ? 'rgb(255, 45, 0)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
        case "Afrontada":
            return <Chip label="Afrontada" sx={{
                bgcolor: tema.palette.mode === "dark" ? 'rgba(84, 230, 0 , 0.2)' : 'rgb(84, 230, 0, 0.8)',
                color: tema.palette.mode === "dark" ? 'rgb(84, 230, 0)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
        case "Honrada":
            return <Chip label="Honrada" sx={{
                bgcolor: tema.palette.mode === "dark" ? 'rgba(0, 155, 255, 0.2)' : 'rgb(0, 155, 255, 0.8)',
                color: tema.palette.mode === "dark" ? 'rgb(0, 155, 255)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
        case "Anulada":
            return <Chip label="Anulada" sx={{
                bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 45, 0 , 0.2)' : 'rgb(255, 45, 0 , 0.8)',
                color: tema.palette.mode === "dark" ? 'rgb(255, 45, 0)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
        case "Inactivo":
            return <Chip label="Inactivo" sx={{
                bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 87, 51 , 0.2)' : 'rgb(255, 87, 51 , 0.8)',
                color: tema.palette.mode === "dark" ? 'rgb(255, 87, 51)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
        default:
            break;
    }
}
//en gestion
export const COLUMNSGEG = [
    {
        header: 'N° Orden',
        accessorKey: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Nombre',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    // {
    //     header: 'Serie',
    //     footer: 'Serie',
    //     accessorKey: '_new_nmerodeserie',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },

    // {
    //     header: 'Socio Participe',
    //     footer: 'Socio Participe',
    //     accessorKey: '_new_socioparticipe_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     header: 'Acreedor',
    //     footer: 'Acreedor',
    //     accessorKey: 'new_acreedor',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     header: 'Dictamen del Aval',
    //     accessorKey: 'new_dictamendelaval_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },
    {
        header: 'Monto',
        accessorKey: 'new_monto',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Fecha de vencimiento',
        accessorKey: 'new_fechadevencimiento',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]
//vigentes
export const COLUMNSGV = [
    {
        header: 'N° Orden',
        accessorKey: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Nombre',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    // {
    //     header: 'Serie',
    //     footer: 'Serie',
    //     accessorKey: '_new_nmerodeserie',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },

    // {
    //     header: 'Socio Participe',
    //     footer: 'Socio Participe',
    //     accessorKey: '_new_socioparticipe_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     header: 'Acreedor',
    //     footer: 'Acreedor',
    //     accessorKey: 'new_acreedor',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     header: 'Dictamen del Aval',
    //     accessorKey: 'new_dictamendelaval_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },
    {
        header: 'Monto',
        accessorKey: 'new_monto',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Fecha de vencimiento',
        accessorKey: 'new_fechadevencimiento',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

//vencidas
export const COLUMNSGVV = [
    {
        header: 'N° Orden',
        accessorKey: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Nombre',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    // {
    //     header: 'Serie',
    //     footer: 'Serie',
    //     accessorKey: '_new_nmerodeserie',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },

    // {
    //     header: 'Socio Participe',
    //     footer: 'Socio Participe',
    //     accessorKey: '_new_socioparticipe_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     header: 'Acreedor',
    //     footer: 'Acreedor',
    //     accessorKey: 'new_acreedor',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     header: 'Dictamen del Aval',
    //     accessorKey: 'new_dictamendelaval_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },
    {
        header: 'Monto',
        accessorKey: 'new_monto',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Fecha de vencimiento',
        accessorKey: 'new_fechadevencimiento',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]
//afrontadas
export const COLUMNSGA = [
    {
        header: 'N° Orden',
        accessorKey: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Nombre',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    // {
    //     header: 'Serie',
    //     footer: 'Serie',
    //     accessorKey: '_new_nmerodeserie',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },

    // {
    //     header: 'Socio Participe',
    //     footer: 'Socio Participe',
    //     accessorKey: '_new_socioparticipe_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     header: 'Acreedor',
    //     footer: 'Acreedor',
    //     accessorKey: 'new_acreedor',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     header: 'Dictamen del Aval',
    //     accessorKey: 'new_dictamendelaval_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },
    {
        header: 'Monto',
        accessorKey: 'new_monto',
        Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Fecha de vencimiento',
        accessorKey: 'new_fechadevencimiento',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]