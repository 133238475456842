
import React from 'react'
import { Navigate } from 'react-router-dom'

function PrivateRouter({ loggedUser, children }) {
    if (!loggedUser) {
        return <Navigate to="/login" replace />
    }

    return children
    
}

export default PrivateRouter

