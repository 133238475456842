import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import BancosReducers from '../Redux/Bancos.js';
import carpetaDigitalReducers from '../Redux/CarpetaDigital.js';
import contactosReducers from '../Redux/Contacto.js';
import cuentaReducer from '../Redux/Cuenta.js';
import datosReducers from '../Redux/DatosSolicitudAlta.js';
import garantiasReducers from '../Redux/Garantias.js';
import limitesReducers from '../Redux/LimitePorLinea.js';
import validacionesReducers from '../Redux/Nosis.js';
import operacionesReducers from '../Redux/Operaciones.js';
import relacionesReducers from '../Redux/RelacionDeVinculacion.js';
import tipoDocumentosReducers from '../Redux/TipoDeDocumento.js';
import unidadesNegocioReducers from '../Redux/UnidadDeNegocio.js';
import usuariosReducer from '../Redux/usuarios.js';
import tokenReducer from '../Redux/Token.js';

const appReducer = combineReducers({
  usuarios: usuariosReducer,
  cuenta: cuentaReducer,
  datos: datosReducers,
  operaciones: operacionesReducers,
  garantias: garantiasReducers,
  limites: limitesReducers,
  carpetaDigital: carpetaDigitalReducers,
  relaciones: relacionesReducers,
  bancos: BancosReducers,
  unidadesNegocio: unidadesNegocioReducers,
  validacion: validacionesReducers,
  contactos: contactosReducers,
  tiposDocumentos: tipoDocumentosReducers,
  token: tokenReducer
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'CERRAR_SESION') {
    state = undefined;
  }

  return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
  return store
}