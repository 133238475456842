import React from 'react'
import { styled } from '@mui/material/styles';
import { Box, Typography, Divider, Button, useTheme, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Fade from 'react-reveal/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';
import { cargarDocumentacionPorCuenta, obtenerDocumentosPorCuenta } from '../Redux/CarpetaDigital';
import { toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import SpinnerF from './SpinnerF';
import Tooltip from '@mui/material/Tooltip';
import { MuiFileInput } from 'mui-file-input';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { COLUMNASCARPETADIGITAL } from '../columnas/columnasCarpetaDigital'
import Tabla from './Tabla'

const CarpetaDigital = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [carpetas, setCarpetas] = React.useState([])
    const [llamadaDocumentosCuentas, setLlamadaDocumentosCuentas] = React.useState(false)
    const [habilitado, setHabilitado] = React.useState(false)
    const [open, setOpen] = React.useState(false);

    const loadingSelector = useSelector(store => store.carpetaDigital.loadingDocumentos)
    const documentosCuentasSelector = useSelector(store => store.carpetaDigital.documentos)
    const cargaDocumentoSelector = useSelector(store => store.carpetaDigital.cargaDocumento)
    const accountid = useSelector(store => store.usuarios.accountid)
    const token = useSelector(store => store.token.token)
    const retrieveDocsExitoSelector = useSelector(store => store.carpetaDigital.retrieveDocsExito)
    const [retrieveDocs, setRetrieveDocs] = React.useState(false)

    const handleOpen = (id) => {
        setDocumentoId(id)
        setTimeout(() => {
            setOpen(true)
        }, 100);
    };

    const handleClose = () => setOpen(false);

    const [documento, setDocumento] = React.useState('')
    const [documentoId, setDocumentoId] = React.useState('')
    const [selectedFile, setSelectedFile] = React.useState();
    const [selectedFiles, setSelectedFiles] = React.useState([])
    const [isFilePicked, setIsFilePicked] = React.useState(false);

    React.useEffect(() => {
        if (accountid !== '') {
            if (documentosCuentasSelector?.length > 0) {
                var arrayDocumentosXcuentas = []
                documentosCuentasSelector.forEach(item => {
                    var documento = {
                        id: item.new_documentacionporcuentaid,
                        createdon: item.createdon,
                        new_fechadevencimiento: item.new_fechadevencimiento,
                        new_documentacionporcuentaid: item.new_documentacionporcuentaid,
                        new_fechadevencimiento: item.new_fechadevencimiento,
                        new_name: item.new_name,
                        new_name_documento: item["documentos.new_name"],
                        new_vinculocompartido: item.new_vinculocompartido,
                        new_visibleenportal: item.new_visibleenportal,
                        statuscode: item.statuscode,
                        statuscodeNOMBRE: item["statuscode@OData.Community.Display.V1.FormattedValue"],
                        _new_cuentaid_value: item["_new_cuentaid_value@OData.Community.Display.V1.FormattedValue"],
                        _new_documentoid_value: item._new_documentoid_value,
                        utilidades: {
                            new_vinculocompartido: item.new_vinculocompartido,
                            new_urlplantilla: item["documentos.new_urlplantilla"],
                            new_descripcion: item["documentos.new_descripcion"]
                        }
                    }
                    arrayDocumentosXcuentas.push(documento)
                })
                // setCarpetas(arrayDocumentosXcuentas)
                setCarpetas(arrayDocumentosXcuentas.sort(function (a, b) {
                    if (a.statuscodeNOMBRE < b.statuscodeNOMBRE) {
                        return -1;
                    }
                    if (a.statuscodeNOMBRE > b.statuscodeNOMBRE) {
                        return 1;
                    }
                    return 0;
                }))
                setRetrieveDocs(true)
            } else if (token != undefined && token != '' && llamadaDocumentosCuentas === false) {
                obtenerDocumentosCuenta()
                setLlamadaDocumentosCuentas(true)
            } else if (documentosCuentasSelector.length == 0 && retrieveDocsExitoSelector == true) {
                setRetrieveDocs(true)
            }
        }
    }, [documentosCuentasSelector, accountid, token, retrieveDocsExitoSelector])

    React.useEffect(() => {
        carpetas.filter(elemento => elemento.new_documentacionporcuentaid === documentoId).map(item => {
            setDocumento(item.new_name)
        })
    }, [documentoId])


    React.useEffect(() => {
        if (cargaDocumentoSelector == "EXITO") {
            obtenerDocumentosCuenta()
            setHabilitado(!habilitado)
            setTimeout(() => {
                setSelectedFiles([])
                setOpen(false)
            }, 1500)
        } else if (cargaDocumentoSelector == "ERROR") {
            setHabilitado(!habilitado)
            setSelectedFiles([])
        }
    }, [cargaDocumentoSelector])

    const obtenerDocumentosCuenta = () => {
        dispatch(obtenerDocumentosPorCuenta(accountid, token.token))
    }

    //modal archivos
    const changeHandler = (event) => {
        setSelectedFiles(event)
    };

    const handleSubmission = () => {
        if (documentoId === '') {
            toast.error('El documento es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        if (selectedFiles.length === 0) {
            toast.error('El archivo adjunto es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        const formData = new FormData();
        for (let index = 0; index < selectedFiles.length; index++) {
            if (selectedFiles[index].size >= 15000000) {
                toast.error('El archivo no puede superar los 15 megas', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSelectedFiles([])
                return
            }
            let element = selectedFiles[index];
            formData.append(`body${index}`, element);
        }

        dispatch(cargarDocumentacionPorCuenta(formData, token.token, documentoId))
        setHabilitado(!habilitado)
    };

    //drawer header es necesario para que tome bien centrado los componentes
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    //style del modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 2,
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {/* <DrawerHeader /> */}
            <Fade>
                <Grid>
                    <Typography variant="h5" sx={{ pb: 3 }}>
                        Mi Documentación Digital
                    </Typography>
                </Grid>
                <Grid>
                    <Paper elevation={2}>
                        {
                            loadingSelector === true && carpetas.length === 0 || retrieveDocs === false && carpetas.length === 0 ?
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    py: 2
                                }}>
                                    <SpinnerF />
                                </Box> :
                                <Tabla
                                    lineas={carpetas}
                                    columnas={COLUMNASCARPETADIGITAL(theme)}
                                    handleOpen={handleOpen}
                                    acciones={true}
                                    edicionRegistro={true}
                                />
                        }
                    </Paper>
                </Grid>
            </Fade >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem", pt: 1 }}>
                                Cargar documentación
                            </Typography>
                            <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
                                <IconButton
                                    edge="end"
                                    color="warning"
                                    onClick={handleClose}
                                    aria-label="close"
                                    sx={{ mr: 1 }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Chip label={documento} sx={{ mt: 2, mb: 2 }} />
                        <Box sx={{ py: 2 }}>
                            <MuiFileInput
                                value={selectedFiles}
                                multiple
                                label="Adjuntar documento"
                                onChange={changeHandler}
                            />
                        </Box>
                        <Button
                            sx={{ mt: 1, mb: 1 }}
                            fullWidth
                            variant="contained"
                            onClick={handleSubmission}
                            disabled={habilitado}
                        >
                            Subir archivo(s)
                        </Button>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                            <Box sx={{ height: '100%' }} >
                                <WarningAmberIcon color="warning" sx={{ mr: 1 }} />
                            </Box>
                            <Box sx={{ height: '100%' }} >
                                <Typography>
                                    El tamaño máximo permitido por archivo es de 15 MB
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box >
    )
}

export default CarpetaDigital