import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../img/Logonavbar.png';
import Container from '@mui/material/Container';
import { recuperarContraseña, limpiarRecupero } from '../Redux/usuarios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { RecaptchaKey } from '../Keys'
import { toast } from 'react-toastify';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import Stack from '@mui/material/Stack';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Tooltip from '@mui/material/Tooltip';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import QuizIcon from '@mui/icons-material/Quiz';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

const schema = yup.object().shape({
    correo: yup.string().email('El correo electrónico no es válido').required('El correo electrónico es requerido')
});

const Recupero = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [robot, setRobot] = React.useState(false)
    const [envioCorreo, setEnvioCorreo] = React.useState(false)
    const [correo, setCorreo] = React.useState('')
    const [colapsar, setColapsar] = React.useState(false)
    const resultado = useSelector(store => store.usuarios.resultadoRecupero)
    const loading = useSelector(store => store.usuarios.loadingRecupero)

    React.useEffect(() => {
        debugger
        if (resultado !== undefined) {
            if (resultado !== '' && resultado == "EXITO") {
                debugger
                setEnvioCorreo(true)
                reset();
                dispatch(limpiarRecupero())
                setTimeout(() => {
                    setColapsar(true)
                }, 2000)
            }
        }
    }, [resultado])

    const volver = () => {
        setEnvioCorreo(false)
        setColapsar(false)
        navigate('/')
    }

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = (data) => {
        debugger
        if (!robot) {
            toast.error('Debes confirmar que no eres un robot!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        setCorreo(data.correo)
        dispatch(recuperarContraseña(data.correo))
    };

    const robotOnChange = (value) => {
        var valor = ""
        if (value) {
            valor = "True"
            setRobot(true)
        } else {
            valor = "False"
            setRobot(false)
        }
    }

    //loader en boton
    const buttonSx = {
        color: '#24292e',
        p: 1.2,
        // fontWeight: 'bold',
        ...(loading && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
        }}>
            {
                !envioCorreo ?
                    <Container component="main" maxWidth="xs">
                        <Box sx={{ display: 'flex', justifyContent: 'center', }} gap="0.5rem">
                            <img src={logo} alt="Logo Responsive" style={{ height: "3rem" }} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                            <Typography component="h1" variant="h5" sx={{ mb: 2, mt: 4 }}>
                                Recuperar Contraseña
                                <Tooltip title={<Typography sx={{ color: '#fff' }}>Le enviaremos un correo con las instrucciones para el cambio de contraseña.</Typography>}>
                                    <IconButton
                                        edge="end"
                                        aria-label="">
                                        <QuizIcon style={{ color: '#03a9f4' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Box>
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="correo"
                                        label="Correo"
                                        {...register("correo")}
                                        helperText={errors.correo?.message}
                                        error={errors.correo}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReCAPTCHA
                                        size={12}
                                        className=""
                                        render="explicit"
                                        sitekey={RecaptchaKey}
                                        onChange={e => robotOnChange(e)} />
                                </Grid>
                            </Grid>
                            <Box sx={{ mt: 2, mb: 2, position: 'relative' }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={buttonSx}
                                    disabled={loading}
                                // onClick={handleSubmit(ProcesarLogin)}
                                >
                                    Recuperar
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                            <Box component="form" noValidate sx={{ mt: 3 }}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/" variant="body2">
                                            Ya tienes una cuenta? Inicia sesion
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Container> :
                    <Container component="main" maxWidth="xs">
                        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                            <Collapse in={colapsar} timeout={{ enter: 800 }}>
                                <IconButton fontSize="inherit" sx={{ fontSize: '100px', color: "#388e3c" }}>
                                    <ForwardToInboxIcon sx={{ fontSize: '100px' }} />
                                </IconButton>
                            </Collapse>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                            <Collapse in={colapsar} timeout={{ appear: 400, enter: 800 }}>
                                <Typography variant="h6" align="center">
                                    Enviamos al correo <Typography component="span" style={{ color: "#00b0ff", fontWeight: 800 }}>{correo}</Typography> las instrucciones para el recupero de tu contraseña.
                                </Typography>
                                <Typography align="center" style={{ color: "#00b0ff", fontWeight: 800, fontSize: 14 }}>
                                    !Revisa el correo no deseado!
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    p: 1,
                                    m: 1,
                                    mt: 5,
                                    borderRadius: 1,
                                }}>
                                    <Stack direction="row" spacing={2}>
                                        <Button variant="outlined" color="success" onClick={() => volver()}>
                                            Volver
                                        </Button>
                                    </Stack>
                                </Box>
                            </Collapse>
                        </Box>
                    </Container>
            }
        </Box>
    );
};

export default Recupero;