const Entidad = "OneClickSgr";
const GoogleMapKey = "AIzaSyDwKzicjMvfVsf9SbEAZn9N7i4CxX6YO1I";
const RecaptchaKey = "6Ld4450dAAAAAIH5dZAR2sWKzgQTJ9Eo6XB3himC";
const UrlApiDynamics = "https://sgroc365api.azurewebsites.net/api/";
const UnidadDeNegocio = "F7ACBC6B-9B47-E911-A9A4-000D3AC1B95D"
const UrlApi = "https://hw365api.azurewebsites.net/"

//Sandbox
const authMail = "admin@innovasgr.com"
const authPass = "Innova.2022$"
//Parametros
const onboardingHabilitado = true
const readOnlyDatos = false
//const UrlApi = "https://localhost:7035/"ñ
//6Lc8jg0cAAAAALskhenfFN_VW6bKcuUhKP44AzAL
//const UrlApiDynamics = "https://365hwapi.azurewebsites.net/api/";

//Localhost
//const UrlApiDynamics = "https://localhost:44348/api/";
//const RecaptchaKey = "6LeALiAcAAAAABv5WAeRHUmaf5GfqNSBYn8wTKON";

//6LeALiAcAAAAAFh9s4uYvwR48lxL1WRyiG63Ke9G

export { Entidad, GoogleMapKey, RecaptchaKey, UrlApiDynamics, UnidadDeNegocio, UrlApi, authMail, authPass, onboardingHabilitado, readOnlyDatos }