import Chip from '@mui/material/Chip';

let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

function obtenerChip(data) {

    switch (data) {
        case 100000001:
            return <Chip label="Accionista" color="success" />
        case 100000002:
            return <Chip label="Beneficiario" color="secondary" />
        case 100000003:
            return <Chip label="Miembro del Directorio" color="primary" />
        case 100000004:
            return <Chip label="Representante Legal/Apoderado" color="info" />
        case 100000006:
            return <Chip label="Fiadores" color="error" />
        default:
            break;
    }
}

export const COLUMNASGLOBAL = [
    {
        accessorKey: 'id'
    },
    {
        header: 'Tipo de relación',
        accessorKey: 'new_tipoderelacion',
        Cell: ({ cell }) => <strong>{obtenerChip(cell.getValue())}</strong>
    },
    {
        header: 'Vinculado con',
        accessorKey: '_new_cuentacontactovinculado_value',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

export const COLUMNASREPRESENTANTES = [
    {
        header: 'Contacto vinculado',
        accessorKey: '_new_cuentacontactovinculado_value',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

export const COLUMNASMIEMBROS = [
    {
        header: 'Contacto vinculado',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Cargo',
        accessorKey: 'new_cargo',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

export const COLUMNASBENEFICIARIOS = [
    {
        header: 'Contacto vinculado',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Porcentaje',
        accessorKey: 'new_porcentajebeneficiario',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

export const COLUMNASACCIONISTASOCIOS = [
    {
        header: 'Cuenta/Contacto vinculado',
        accessorKey: '_new_cuentacontactovinculado_value',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: '% de Participación',
        accessorKey: 'new_porcentajedeparticipacion',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]