import React, { useMemo, useCallback, useState } from 'react'
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

const TableCRUD = ({ lineas, columnas, handleOpen, titulo, funcionEliminar, readOnly = true }) => {
    const [tableData, setTableData] = useState(lineas);
    const columns = useMemo(() => columnas, [tableData])
    // const [tableData, setTableData] = useState(() => lineas);

    React.useEffect(() => {
        if (lineas.length > 0) {
            setTableData(lineas)
        }
    }, [lineas])

    const abrilModalEdicion = (row) => {
        let id = row.getValue('id')
        let object = row.original
        let editar = true
        handleOpen(id, object, editar)
    }

    const abrirModalAgregar = () => {
        let id = ''
        let obj = {}
        let editar = false
        handleOpen(id, obj, editar)
    }


    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(`Estas seguro que deseas eliminar este registro?`)
            ) {
                return;
            }
            funcionEliminar(tableData[row.index])
            // .then(() => {
            //     //cuando se cumpla la funcion de eliminar, se va a eliminar de la tabla
            //     tableData.splice(row.index, 1);
            //     setTableData([...tableData]);
            // })
        },
        [tableData],
    );


    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableFullScreenToggle={false}
                columns={columns}
                data={tableData}
                localization={MRT_Localization_ES}
                editingMode="modal" //default
                initialState={{
                    columnVisibility: { id: false },
                    density: 'compact',
                    pagination: { pageIndex: 0, pageSize: 10 },
                }}
                enableGrouping
                enableColumnOrdering
                enableEditing={!readOnly}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title={<Typography sx={{ fontSize: '0.8rem' }}>Editar</Typography>}>
                            <IconButton onClick={() => abrilModalEdicion(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title={<Typography sx={{ fontSize: '0.8rem' }}>Borrar</Typography>}>
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <>
                        {!readOnly ?
                            <Button
                                color="secondary"
                                onClick={() => abrirModalAgregar()}
                                variant="contained"
                            >
                                <PlaylistAddIcon />
                            </Button> : null
                        }
                        <Typography variant="subtitle" p="2px">{titulo}</Typography>
                    </>
                )}
            />

        </>
    )
}

export default TableCRUD