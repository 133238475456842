import React from 'react'
import { Box, Button, Typography, Container } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import CardContent from '@mui/material/CardContent';
import { obtenerUN } from '../Redux/UnidadDeNegocio'

const SolicitudAltaFinal = ({ ResetOnboarding }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [mostrarIconoFinal, setMostrarIconoFinal] = React.useState(false)
    const [mostrarTextoFinal, setMostrarTextoFinal] = React.useState(false)
    const [progress, setProgress] = React.useState(0);
    const [UN, setUN] = React.useState({})

    const estadoOnboardingSelector = useSelector(store => store.datos.estadoOnboarding)
    const loadingOnboarding = useSelector(store => store.datos.loadingOnboarding)
    const porcentaje = useSelector(store => store.datos.porcentajeOnboarding)
    const token = useSelector(store => store.token.token)
    const unidadDeNegocio = useSelector(store => store.unidadesNegocio.unidadDeNegocio)

    React.useEffect(() => {
        setProgress(porcentaje);
    }, [porcentaje]);

    React.useEffect(() => {
        if (token != undefined && token != '') {
            dispatch(obtenerUN(token.token))
        }
    }, [token])

    React.useEffect(() => {
        setProgress(porcentaje);
    }, []);

    React.useEffect(() => {
        if (unidadDeNegocio?.length > 0) {
            setUN(unidadDeNegocio[0])
        }
    }, [unidadDeNegocio]);


    const reinciarOnboarding = () => {
        localStorage.clear();
        ResetOnboarding()
        navigate('/')
    }

    React.useEffect(() => {
        if (estadoOnboardingSelector === 'EXITO') {
            setTimeout(() => {
                setMostrarIconoFinal(true)
            }, 500);
            setTimeout(() => {
                setMostrarTextoFinal(true)
            }, 700);
        }
    }, [estadoOnboardingSelector])

    return (
        <CardContent>
            {
                loadingOnboarding ?
                    <Container component="main" maxWidth="md">
                        <Box
                            sx={{
                                width: '100%',
                            }}
                        >
                            <LinearProgress sx={{ height: '15px', borderRadius: "50px" }} variant="determinate" value={progress} />
                            <Box
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <Typography align="center" variant="h5" sx={{ mt: 5 }}>
                                    Aguarde por favor, no cierre la ventana ya que se está procesando la información. Esta tarea puede demorar unos minutos....
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                    : null
            }
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 1,
                m: 1,
                borderRadius: 1,
            }}>
                <Collapse in={mostrarIconoFinal} timeout={{ enter: 800 }}>
                    <IconButton fontSize="inherit" color="primary" sx={{ fontSize: '120px' }}>
                        <BeenhereOutlinedIcon sx={{ fontSize: '80px' }} />
                    </IconButton>
                </Collapse>
            </Box>
            <Collapse in={mostrarTextoFinal} timeout={{ appear: 400, enter: 800 }}>
                {
                    UN?.new_textoconfirmacionsolicitudaltasgr != undefined ?
                        <Typography variant="h5" align="center" sx={{ fontFamily: 'Roboto' }}>
                            {UN?.new_textoconfirmacionsolicitudaltasgr}
                        </Typography> :
                        <Typography variant="h5" align="center" sx={{ fontFamily: 'Roboto' }}>
                            Felicitaciones! has completado el primer paso. En breve nos comunicaremos contigo vía correo electrónico para informarte del estado de tu solicitud. Cualquier duda podés enviar un correo informando nombre completo o razón social y Cuit/Cuil al siguiente correo calificaciones@innovasgr.com
                        </Typography>
                }
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    m: 1,
                    mt: 5,
                    borderRadius: 1,
                }}>
                    <Stack direction="row" spacing={2}>
                        <Button size="large" color="primary" variant="outlined" onClick={() => reinciarOnboarding()}>
                            Finalizar
                        </Button>
                    </Stack>
                </Box>
            </Collapse>
        </CardContent>
    )
}

export default SolicitudAltaFinal