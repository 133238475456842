import React from 'react'
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Tooltip, Typography, IconButton, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DownloadIcon from '@mui/icons-material/Download';

const Utilidades = (utilidad) => {
    return (
        <Box textAlign="center">
            {
                utilidad.utilidad.new_vinculocompartido != null ?
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Abrir carpeta</Typography>} sx={{ mt: 3 }}>
                        <IconButton edge="end" aria-label="" onClick={() => window.open(utilidad.utilidad.new_vinculocompartido, "_blank")} sx={{ mx: 1 }}>
                            <FileOpenIcon />
                        </IconButton>
                    </Tooltip>
                    : null
            }
            {
                utilidad.utilidad.new_urlplantilla != null ?
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Descargar Plantilla</Typography>} sx={{ mt: 3 }} >
                        <IconButton edge="end" aria-label="" onClick={() => window.open(utilidad.utilidad.new_urlplantilla, "_blank")} sx={{ mx: 1 }}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip> : null
            }
            {
                utilidad.utilidad.new_descripcion != null ?
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>{utilidad.utilidad.new_descripcion}</Typography>} sx={{ mx: 1 }}>
                        <IconButton edge="end" aria-label="">
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip> : null
            }
        </Box>
    )
}

export default Utilidades
