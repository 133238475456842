import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles';
import { Box, Button, InputLabel, Typography } from '@mui/material';
import Fade from 'react-reveal/Fade';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox } from '@mui/material';
import { ListItemText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import {
    actualizarDatosAlyc,
    actualizarDatosCuenta,
    inactivarSociedadBolsa,
    obtenerCertificadoPyme,
    obtenerContactoDeNotificaciones,
    obtenerCuenta,
    obtenerPaises,
    obtenerProvincias,
    obtenerSociedadesXsocio
} from '../Redux/Cuenta';
import Tabla from './Tabla';
import { COLUMNSCERTIFICADOS } from '../columnas/ColumnasCertificados';
import { toast } from 'react-toastify';
import { actualizarFotoUid } from '../Redux/usuarios';
import { inactivarCuenta, obtenerBancosXsocio } from '../Redux/Bancos';
import { obtenerRelaciones } from '../Redux/RelacionDeVinculacion';
import { COLUMNASSOCXBOLSA } from '../columnas/ColumnasSociedadXbolsa';
import { COLUMNASACCIONISTASOCIOS, COLUMNASBENEFICIARIOS, COLUMNASMIEMBROS, COLUMNASREPRESENTANTES } from '../columnas/ColumnasRelaciones';
import { COLUMNASFIADORES } from '../columnas/ColumnasFiadores';
import CustomSearchSelectHook from './CustomSearchSelectHook'
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';
import ComprobanteDeVenta from './ComprobanteDeVenta';
import Perfil from './Perfil';
import Relaciones from './Relaciones';
import SociedadesDeBolsa from './SociedadesDeBolsa'
import CertificadosPymes from './CertificadosPymes'
import { useTheme } from '@mui/material';

const Cuenta = () => {
    const dispatch = useDispatch()
    const theme = useTheme();
    //values de los tabs - siempre el numero como string
    const [value, setValue] = React.useState("1")
    //perfil
    const accountid = useSelector(store => store.usuarios.accountid)
    //############## DATOS ALYC ########################
    const [estadoSocio, setEstadoSocio] = React.useState('')
    const [actividadEsperada, setActividadEsperada] = React.useState([])
    const [opera, setOpera] = React.useState('');
    const [montoInvertir, setMontoInvertir] = React.useState('')
    const [proposito, setProposito] = React.useState([])
    const [tipoSujeto, setTipoSujeto] = React.useState('')
    const [origenes, setOrigenes] = React.useState([])
    const [metodos, setMetodos] = React.useState([])
    const [fechaContrato, setFechaContrato] = React.useState('')
    const [fechaInscripcion, setFechaInscripcion] = React.useState('')
    const [nroRegistral, setNroRegistral] = React.useState('')
    const [otro, setOtro] = React.useState('')

    // React.useEffect(() => {
    //     if (token == undefined || token == "") {
    //         dispatch(loginToken())
    //     }
    // }, [token])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const obtenerValorActividad = (valor) => {
        switch (valor) {
            case "100000000":
                return "Compra-Venta de Títulos Públicos"
            case "100000001":
                return "Compra-Venta de Acciones Caución"
            case "100000002":
                return "Colocadora/Tomadora"
            case "100000003":
                return "Opciones"
            case "100000004":
                return "Futuros"
            case "100000005":
                return "FCI-FF"
            case "100000006":
                return "Otros"
        }
    }

    const obtenerValorPropostio = (valor) => {
        switch (valor) {
            case '100000000':
                return 'Inversión'
            case '100000001':
                return 'Ahorro'
            case '100000002':
                return 'Financiamiento'
        }
    }

    const obtenerValorOpera = (valor) => {
        switch (valor) {
            case "false":
                return "No"
            case "true":
                return "Si"
        }
    }

    const obtenerValorMetodoEmision = (metodo) => {
        switch (metodo) {
            case "100000000":
                return "Teléfono"
            case "100000001":
                return "Presencial"
            case "100000002":
                return "E-mail"
            case "100000003":
                return "Online"
        }
    }

    const obtenerOrigenDeFondos = (origen) => {
        switch (origen) {
            case "100000000":
                return "Ahorros de mi Actividad"
            case "100000001":
                return "Otros"
            case "100000002":
                return "Venta de Bienes Registrables"

        }
    }

    const obtenerValorTipoSujeto = (Inscripcion) => {
        switch (Inscripcion) {
            case "100000000":
                return "Sujeto Obligado"
            case "100000001":
                return "Sujeto No Obligado"
        }
    }

    //armar opera por cuenta propia?
    const ArmarOpera = (valor) => {
        if (valor !== null) {
            var opcionesOpera = []
            var opera = { value: valor, label: obtenerValorOpera(valor) }
            opcionesOpera.push(opera)
            setOpera(opcionesOpera)
        }
    }

    //armar proposito de la cuenta
    const ArmarProposito = (valor) => {
        if (valor !== null) {
            var opcionesProposito = []

            if (valor.includes(',')) {
                var valores = valor.split(',')
                valores.forEach(pro => {
                    var proposito = { value: pro, label: obtenerValorPropostio(pro) }
                    opcionesProposito.push(proposito);
                });
            } else {
                var proposito = { value: valor, label: obtenerValorPropostio(valor) }
                opcionesProposito.push(proposito);
            }

            setProposito(opcionesProposito)
        }
    }

    const ArmarMetodoEmision = (valor) => {
        if (valor !== null) {
            var opcionesMetodos = []

            if (valor.includes(',')) {
                var valores = valor.split(',')
                valores.forEach(met => {
                    var metodos = { value: met, label: obtenerValorMetodoEmision(met) }
                    opcionesMetodos.push(metodos);
                });
            } else {
                var metodos = { value: valor, label: obtenerValorMetodoEmision(valor) }
                opcionesMetodos.push(metodos);
            }

            setMetodos(opcionesMetodos)
        }
    }

    const ArmarOrigenDeFondos = (valor) => {
        if (valor !== null) {
            var opcionesOrigenes = []

            if (valor.includes(',')) {
                var valores = valor.split(',')
                valores.forEach(val => {
                    var origenesF = { value: val, label: obtenerOrigenDeFondos(val) }
                    opcionesOrigenes.push(origenesF);
                });
            } else {
                var origenesF = { value: valor, label: obtenerOrigenDeFondos(valor) }
                opcionesOrigenes.push(origenesF);
            }

            setOrigenes(opcionesOrigenes)
            var contieneOtro = false
            opcionesOrigenes.forEach(element => {
                if (element.value === "100000001") {
                    contieneOtro = true
                }
            });
            setOtro(contieneOtro)
        }
    }


    const ActividadesEsperadasOpciones = [
        { value: '100000000', label: 'Compra-Venta de Títulos Públicos' },
        { value: '100000001', label: 'Compra-Venta de Acciones Caución' },
        { value: '100000002', label: 'Colocadora/Tomadora' },
        { value: '100000003', label: 'Opciones' },
        { value: '100000004', label: 'Futuros' },
        { value: '100000005', label: 'FCI-FF' },
        { value: '100000006', label: 'Otros' }
    ]

    const OperaOpciones = [
        { value: 'false', label: 'No' },
        { value: 'true', label: 'Si' }
    ]

    const MetodosEmisionesOpciones = [
        { value: '100000000', label: 'Teléfono' },
        // { value: '100000001', label: 'Presencial' },
        { value: '100000002', label: 'E-mail' },
        { value: '100000003', label: 'Online' }
    ]

    const PropositoOpciones = [
        { value: '100000000', label: 'Inversión' },
        { value: '100000001', label: 'Ahorro' },
        { value: '100000002', label: 'Financiamiento' }
    ]

    const OrigenFondosOpciones = [
        { value: '100000000', label: 'Ahorros de mi Actividad' },
        { value: '100000002', label: 'Venta de Bienes Registrables' },
        { value: '100000001', label: 'Otros' }
    ]

    const sujetoOpciones = [
        { value: '100000000', label: 'Sujeto Obligado' },
        { value: '100000001', label: 'Sujeto No Obligado' }
    ]

    //### ONCHANGE DATOS ALYC ###
    const actividadEsperadaOnChange = (event) => {
        const { target: { value } } = event;
        setActividadEsperada(
            typeof value === "string" ? value.split(",") : value
        );
    }
    const operaOnchange = (event) => {
        setOpera(event.target.value);
    }
    const montoOnChange = (event) => {
        setMontoInvertir(event.target.value)
    }
    const propositoOnChange = (event) => {
        const { target: { value } } = event;
        setProposito(
            typeof value === "string" ? value.split(",") : value
        );
    }

    const tipoOnchange = (event) => {
        setTipoSujeto(event.target.value);
    }

    const origenesOnChange = (event) => {
        const { target: { value } } = event;
        value.forEach(element => {
            var otros = document.getElementById('otrosInput')
            if (element === "100000001") {
                otros.style.display = 'block';
            } else {
                otros.style.display = 'none';
            }
        });
        setOrigenes(
            typeof value === "string" ? value.split(",") : value
        );
    }
    const metodosOnChange = (event) => {
        const { target: { value } } = event;
        setMetodos(
            typeof value === "string" ? value.split(",") : value
        );
    }
    const otroOnChange = (event) => {
        setOtro(event.target.value);
    }

    const armarOpcionMultiple = (arrayOpciones = []) => {
        let opcion = null

        if (arrayOpciones.length >= 1) {
            arrayOpciones.forEach(element => {
                if (opcion === null) {
                    opcion = element.value;
                } else {
                    opcion = opcion + ',' + element.value
                }
            });
        }

        return opcion
    }

    const actualizarDatosInfoAlyc = () => {
        // e.preventDefault()
        debugger
        let actividad = null
        let propositoCuenta = null
        let origenFondos = null
        let metodosEmision = null
        if (actividadEsperada.length > 0) {
            actividad = armarOpcionMultiple(actividadEsperada)
        }
        if (proposito.length > 0) {
            propositoCuenta = armarOpcionMultiple(proposito)
        }
        if (origenes.length > 0) {
            origenFondos = armarOpcionMultiple(origenes)
        }
        if (metodos.length > 0) {
            metodosEmision = armarOpcionMultiple(metodos)
        }
        dispatch(actualizarDatosAlyc(accountid, estadoSocio, actividad, opera, montoInvertir, propositoCuenta, otro, metodosEmision,
            fechaContrato, fechaInscripcion, nroRegistral, origenFondos, tipoSujeto))
    }

    return (
        <Box component="main" sx={{ flexGrow: 0, pt: 4, px: 1 }}>
            <Fade>
                <Grid sx={{ mx: 2 }}>
                    <TabContext value={value}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            // bgcolor: 'background.paper'
                        }}>
                            <TabList
                                aria-label="scrollable force tabs example"
                                onChange={handleChange} variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <Tab label="General" value="1" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="Vinculaciones" value="2" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="Certificados Pymes" value="3" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="Sociedad de bolsa" value="4" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                                <Tab label="Comprobante de Venta" value="5" sx={{ fontWeight: 'bold', fontSize: '16px', color: theme.typography.primary.text }}></Tab>
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ p: 0 }}>
                            <Perfil />
                        </TabPanel>
                        <TabPanel value="2" sx={{ p: 0 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Relaciones />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="3" sx={{ p: 0 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <CertificadosPymes />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="4" sx={{ p: 0 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <SociedadesDeBolsa />
                                </Grid>

                            </Grid>
                        </TabPanel>
                        <TabPanel value="5" sx={{ p: 0 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ComprobanteDeVenta />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Fade>
        </Box >
    )
}

export default Cuenta